import { useNavigate, useParams } from 'react-router-dom'
import {
  formatBillingType,
  formatCity,
  formatCountry,
  formatCurrency,
  formatDate,
  generateOfferedItemsText,
  handleNavigate,
  useMediaQueries
} from '../../../utils/global-utils'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider'
import StyledButton from '../../styledComponents/styledButton.module'
import { AdDetails, SelectedAddResponse } from '../../../utils/types.d'
import AddApi from '../../../services/addApi'
import MobileHeader from '../../mobileHeader/mobileHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined'
import useStyles from './adProfilePreview.module'
import LandingFooter from '../../wwwLandingPage/landingPageBody/landingFooter'

function AdProfilePreview() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  const { addId } = useParams()
  const [fetchSelectedAdd, setfFetchSelectedAdd] = useState<SelectedAddResponse[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const selectedAddResponse: any = await AddApi.GetSelectedAdNoAuth(addId)
        setfFetchSelectedAdd([selectedAddResponse.data])
        setLoading(false)
      } catch {
        setfFetchSelectedAdd([])
        setLoading(false)
      }
    }

    fetchDetails()
  }, [addId])

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <MobileHeader />
          </Grid>
        </Grid>
        <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
          <div className={formClasses.companyNameHeadline}>
            <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Pregledaj oglas
          </div>
        </div>
        <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
          <div className={`${formClasses.bodyItemsHolder}`}>
            {loading ? (
              <Backdrop open={true}>
                <div className={formClasses.circularProgressContainer}>
                  <StyledSpinningIcon />
                </div>
              </Backdrop>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  {fetchSelectedAdd.map((selectedAdDetails, index) => (
                    <>
                      <Grid container key={index}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={`${isMobile ? classes.adHeadlineContainerMobile : classes.adHeadlineContainer}`}>
                            <span>{selectedAdDetails?.adTitle}</span>
                          </div>
                          <Divider />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.adHeadlineDetails}>
                              <DescriptionOutlinedIcon />
                              <span className={classes.adDetailsText}>OPIS OGLASA</span>
                            </div>
                            <div className={classes.adHeadlineDetailsDesc}>
                              <span>{selectedAdDetails?.adDescription}</span>
                            </div>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.adHeadlineDetails}>
                              <InsertChartOutlinedOutlinedIcon />
                              <span className={classes.adDetailsText}>DETALJI OGLASA</span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <WorkOutlineOutlinedIcon />
                              <span className={classes.adDetailsText}>{selectedAdDetails?.companyName}</span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <FactoryOutlinedIcon />
                              <span className={classes.adDetailsText}>
                                {selectedAdDetails?.industries.map((industry) => industry?.industryName).join(', ')}
                              </span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <CalendarMonthIcon />
                              <span className={classes.adDetailsText}>
                                {selectedAdDetails?.workingEndDate === null ? 'Početak rada' : 'Trajanje'}:
                                {formatDate(selectedAdDetails?.workingStartDate as string)} /{' '}
                                {selectedAdDetails?.workingEndDate === null ? '_' : formatDate(selectedAdDetails?.workingEndDate as string)}
                              </span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <PublicOutlinedIcon />
                              <span className={classes.adDetailsText}>
                                {formatCountry(selectedAdDetails?.country?.countryName as string)}
                              </span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <LocationCityOutlinedIcon />
                              <span className={classes.adDetailsText}>{formatCity(selectedAdDetails?.city?.city as string)}</span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <LoyaltyOutlinedIcon />
                              <span className={classes.adDetailsText}>
                                {generateOfferedItemsText(selectedAdDetails as unknown as AdDetails) || 'Bez benefita'}
                              </span>
                            </div>
                            {selectedAdDetails?.billingType?.typeOfBilling && (
                              <div className={classes.adHeadlineDetails}>
                                <WorkOutlineOutlinedIcon />
                                <span className={classes.adDetailsText}>
                                  {formatBillingType(selectedAdDetails?.billingType?.typeOfBilling as string)}
                                </span>
                              </div>
                            )}
                            {selectedAdDetails?.salaryValue && selectedAdDetails?.currency?.currencyName ? (
                              <>
                                <div className={classes.adHeadlineDetails}>
                                  <EuroSymbolOutlinedIcon />
                                  <span className={classes.adDetailsText}>
                                    {selectedAdDetails?.salaryValue} {formatCurrency(selectedAdDetails?.currency?.currencyName as string)}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={classes.adHeadlineDetails}>
                                  <EuroSymbolOutlinedIcon />
                                  <span className={classes.adDetailsText}>Isplata po dogovoru</span>
                                </div>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={formClasses.inputContainerTop}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={4}>
                        <StyledButton
                          style={{ textTransform: 'none' }}
                          className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                          variant='contained'
                          onClick={() => handleNavigate(navigate, `/role-picker`)}
                        >
                          Prijavi se
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdProfilePreview
