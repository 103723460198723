import { useNavigate } from 'react-router-dom'
import { useMediaQueries, navigateWithScroll } from '../../utils/global-utils'
import useFormStyles from '../styledComponents/globalFormStying.module'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid/Grid'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import logo from '../../assets/logo.png'
import useStyles from './landingHeader.module'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'

function LandingHeader() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <>
      <AppBar>
        <Toolbar>
          <Grid container justifyContent='flex-start' alignItems='center'>
            <Grid item>
              <img alt='logo-sezonal' src={`${logo}`} className={classes.logoLanding} />
            </Grid>

            <Grid item>
              <div
                onClick={() => navigateWithScroll(navigate, `/browse-ads`)}
                className={isMobile ? formClasses.dne : `${formClasses.headerMenuItems} ${formClasses.ml18} ${formClasses.mr18}`}
              >
                Oglasi
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={() => navigateWithScroll(navigate, `/about-us`)}
                className={`${isMobile ? formClasses.dne : formClasses.headerMenuItems} ${formClasses.mr18}`}
              >
                O nama
              </div>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end' alignItems='center'>
            <Grid item>
              <div
                onClick={() => navigateWithScroll(navigate, `/login`)}
                className={`${isMobile ? formClasses.dne : formClasses.headerMenuItems}`}
              >
                Prijavi se
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={() => navigateWithScroll(navigate, `/role-picker/`)}
                className={`${isMobile ? formClasses.dne : formClasses.headerMenuItems} ${formClasses.mr18} ${formClasses.ml18}`}
              >
                Registruj se
              </div>
            </Grid>
            <Grid item>
              <div onClick={() => setOpenDrawer(!openDrawer)} className={isMobile ? formClasses.headerMenuItems : formClasses.dne}>
                <MenuIcon />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        open={openDrawer}
        anchor='right'
        onClose={() => setOpenDrawer(false)}
        variant='temporary'
        PaperProps={{
          style: {
            width: '80%',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <List>
          <div className={classes.drawerItemsContainer}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigateWithScroll(navigate, `/browse-ads`)}>
                <ListItemText className={classes.drawerNavigations} primary={'Oglasi'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigateWithScroll(navigate, `/about-us`)}>
                <ListItemText className={classes.drawerNavigations} primary={'O nama'} />
              </ListItemButton>
            </ListItem>
            <div className={formClasses.inputContainerTop}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigateWithScroll(navigate, `/login`)}>
                  <ListItemText className={classes.drawerNavigations} primary={'Prijavi se'} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigateWithScroll(navigate, `/role-picker/`)}>
                  <ListItemText className={classes.drawerNavigations} primary={'Registruj se'} />
                </ListItemButton>
              </ListItem>
            </div>
          </div>
        </List>
      </Drawer>
    </>
  )
}

export default LandingHeader
