import { useNavigate, useParams } from 'react-router-dom'
import { useMediaQueries } from '../../../utils/global-utils'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useEffect, useState } from 'react'
import { SelectedBlogResponse } from '../../../utils/types.d'
import BlogApi from '../../../services/blogApi'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider'
import MobileHeader from '../../mobileHeader/mobileHeader'
import useStyles from './singleBlog.module'
import LandingFooter from '../landingPageBody/landingFooter'

function SingleBlog() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const { blogId } = useParams()
  const navigate = useNavigate()
  const [fetchSelectedBlog, setfFetchSelectedBlog] = useState<SelectedBlogResponse[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const selectedBlogResponse: any = await BlogApi.GetSelectedBlog(blogId as string)
        setfFetchSelectedBlog([selectedBlogResponse.data])
        setLoading(false)
      } catch {
        setfFetchSelectedBlog([])
        setLoading(false)
      }
    }

    fetchDetails()
  }, [blogId])

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <MobileHeader />
          </Grid>
        </Grid>
        <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
          <div className={formClasses.companyNameHeadline}>
            <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Pregledaj blog
          </div>
        </div>
        <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
          <div className={`${formClasses.bodyItemsHolder}`}>
            {loading ? (
              <Backdrop open={true}>
                <div className={formClasses.circularProgressContainer}>
                  <StyledSpinningIcon />
                </div>
              </Backdrop>
            ) : (
              <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                <div className={`${formClasses.bodyItemsHolder} ${isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}`}>
                  {fetchSelectedBlog?.map((blogDetails) => (
                    <>
                      <Grid container key={blogDetails.blogId}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={`${isMobile ? classes.adHeadlineContainerMobile : classes.adHeadlineContainer}`}>
                            <span>{blogDetails?.blogTitle}</span>
                          </div>
                          <div className={formClasses.inputContainerTop}>
                            <Divider />
                          </div>
                          <div className={classes.adHeadlineDetails}>
                            <span>{blogDetails?.blogText}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </div>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LandingFooter />
      </Grid>
    </Grid>
  )
}

export default SingleBlog
