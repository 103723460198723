import Grid from '@material-ui/core/Grid/Grid'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import MobileHeader from '../../mobileHeader/mobileHeader'
import StyledLayoutItem from '../../layoutItem/layoutItem'
import BODY_ITEMS from '../../../assets/bodyItems'
import { StyledCard } from '../../styledComponents/styledCard/styledCard'
import { useMediaQueries } from '../../../utils/global-utils'

function ForgotPasswordConfirmation() {
  const formClasses = useFormStyles()
  const { isMobile, isTablet } = useMediaQueries()

  return (
    <>
      <Grid container className={formClasses.container}>
        {isMobile || isTablet ? <MobileHeader /> : <StyledLayoutItem alt='lifeguard' imageSrc={`${BODY_ITEMS.BARTENDER}`} children />}
        <Grid item xs={12} sm={12} md={6}>
          <div className={isMobile ? formClasses.formContainerMobile : formClasses.formContainer}>
            <div className={formClasses.rolePickerContainer}>
              <div className={formClasses.inputContainer}>
                <StyledCard
                  className={formClasses.styledCardBody}
                  cardTextDescription={`Sezonal Vam je poslao link za promenu lozinke, 
                                        molimo Vas da proverite mejl i pratite uputstva u mejlu da bi ste uspešno resetovali lozinku. 
                                        Ukoliko Vam nije stigla poruka molimo Vas proverite folder "nepoželjno".`}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ForgotPasswordConfirmation
