import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  notifierContainer: {
    display: 'flex',
    paddingTop: '18px',
    paddingBottom: '18px'
  },
  adDetailsText: {
    paddingTop: '6px',
    paddingLeft: '6px'
  },
  adHeadlineDetails: {
    color: '#636366',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '12px 12px 12px 18px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center'
  },
  dflex: {
    display: 'flex'
  }
}))

export default useStyles
