import { styled } from '@mui/system'
import { Card } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import useStyles from './styledCard.module'
import { RefObject } from 'react'
import React from 'react'

const StyledCardBody = styled(Card)``

const StyledCardContent = styled(CardContent)``

interface CardProps {
  cardImage?: string
  cardTextHeadline?: string
  cardTextDescription?: string
  className?: string | undefined
  // eslint-disable-next-line no-unused-vars
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  children?: React.ReactNode
  isSelected?: boolean
  width?: number
  height?: number
  // eslint-disable-next-line no-unused-vars
  innerRef?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined
}
export const StyledCard = React.forwardRef(
  ({ cardImage, cardTextHeadline, cardTextDescription, className, onClick, children }: CardProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles()
    return (
      <StyledCardBody onClick={onClick} className={className} ref={ref}>
        <StyledCardContent className={classes.styledCardContent}>
          <div className={classes.textContainer}>
            <span className={classes.cardHeadline}>{cardTextHeadline}</span>
            {children}
          </div>
          <div className={classes.avatarContainer}>
            {cardImage ? (
              <img className={classes.image} alt='role-picker' src={`${cardImage}`} />
            ) : (
              <Avatar className={classes.avatar}></Avatar>
            )}
          </div>
          <span className={classes.cardDescription}>{cardTextDescription}</span>
        </StyledCardContent>
      </StyledCardBody>
    )
  }
)
