import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => {
  return {
    appBar: {
      backgroundColor: '#0033FF',
      height: '84px',
      paddingLeft: '120px',
      position: 'fixed',
      zIndex: '2',
      width: '100% !important'
    },
    appBarTablet: {
      backgroundColor: '#0033FF',
      height: '84px',
      paddingLeft: '60px',
      position: 'fixed',
      zIndex: '2'
    },
    toolBar: {
      position: 'relative',
      top: '24px',
      paddingLeft: '0px'
    },
    navItems: {
      paddingLeft: '0px',
      backgroundColor: 'transparent !important'
    },
    navText: {
      marginRight: '36px !important'
    },
    selectedMenuItem: {
      '& p': {
        fontWeight: 'bold'
      }
    },
    scrollToTopButton: {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      border: '1px solid #000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      bottom: '6%',
      right: '6%',
      color: '#000',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.25)',
      zIndex: 1000
    },
    scrollToTopIcon: {
      width: '24px',
      height: '24px'
    },
    //UTILS
    pl60: {
      paddingLeft: '60px'
    }
  }
})
