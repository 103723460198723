import axios, { AxiosResponse } from 'axios'
import { APP_CORE } from '../environment'

const GET_EMPLOYEE_DETAILS = '/Employee/getEmployeeDetails'
const EDIT_EMPLOYEE_PROFILE = '/Employee/editEmployeeProfile'
const GET_EMPLOYEE_AND_EMPPLOYER_PROFILE_PICTURE = '/Employee/getEmployeeProfilePicture'
const GET_JOB_TYPE_OPTIONS = '/Employee/getAllLookingForAJobTypes'

const api = axios.create({
  baseURL: APP_CORE
})

const apiGet = (operation: string, accessToken: string | null, employeeId: string | undefined): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    api
      .get(operation, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: {
          EmployeeId: `${employeeId}`
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const apiGetNoAuth = (operation: string, data: object): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    api
      .get(operation, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const ApiGetProfilePicture = (accessToken: string | null): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    api
      .get(GET_EMPLOYEE_AND_EMPPLOYER_PROFILE_PICTURE, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const apiPut = (operation: string, data: object, accessToken: string | null) => {
  return new Promise((resolve, reject) => {
    api
      .put(operation, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const GetEmployeeDetails = async (accessToken: string | null, employeeId: string | undefined) => {
  return apiGet(GET_EMPLOYEE_DETAILS, accessToken, employeeId)
}

const GetJobTypeOptions = (data: object) => {
  return apiGetNoAuth(GET_JOB_TYPE_OPTIONS, data)
}

const EditEmployeeProfile = async (data: object, accessToken: string | null) => {
  return apiPut(EDIT_EMPLOYEE_PROFILE, data, accessToken)
}

const EmployeeApi = {
  GetEmployeeDetails,
  EditEmployeeProfile,
  ApiGetProfilePicture,
  GetJobTypeOptions
}

export default EmployeeApi
