import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  formHelperContainer: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    fonrSize: '14px',
    color: '#0033FF',
    marginRight: '12px'
  },
  formHelperText: {
    display: 'flex',
    fontSize: '12px !important'
  },
  sezonalTooltip: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '600px',
    fontSize: '14px'
  },
  consentCheckbox: {
    padding: '0px !important'
  }
}))

export default useStyles
