import Grid from '@mui/material/Grid/Grid'
import useStyles from './blogPostSection.module'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useEffect, useState } from 'react'
import { GetAllBlogsResponse } from '../../../utils/types.d'
import BlogApi from '../../../services/blogApi'
import BlogPostImage from '../../styledComponents/blogPostImage/blogPostImage'
import { useNavigate } from 'react-router-dom'
import { handleNavigate, useMediaQueries } from '../../../utils/global-utils'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Card, CardContent } from '@mui/material'
import { useAuthContext } from '../../../context/UserContext'
import jwt_decode from 'jwt-decode'

function BlogPostSection() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const navigate = useNavigate()
  const { isMobile } = useMediaQueries()
  const { accessToken } = useAuthContext()
  const [userRole, setUserRole] = useState<string | null>(null)

  const [fetchBlogs, setFetchBlogs] = useState<GetAllBlogsResponse[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogResponse = await BlogApi.GetAllBlogs({})
        const blogs = blogResponse.data
        setFetchBlogs(blogs)
      } catch {
        //
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (accessToken) {
      try {
        const decodedAccessToken: any = jwt_decode(accessToken)
        setUserRole(decodedAccessToken.role)
      } catch {
        //
      }
    }
  }, [accessToken])

  const handleNavigateToBlogProfile = (blogId: string) => {
    if (userRole === 'admin') {
      handleNavigate(navigate, `/blog-profile-preview-admin`, blogId)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      handleNavigate(navigate, `/blog-profile-preview`, blogId)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <>
      <Carousel
        responsive={responsive}
        autoPlay={isMobile ? false : false}
        keyBoardControl={true}
        autoPlaySpeed={3000}
        transitionDuration={500}
      >
        {fetchBlogs?.map((blogPostDetails: GetAllBlogsResponse, index: number) => (
          <Card
            onClick={() => handleNavigateToBlogProfile(blogPostDetails.blogId)}
            key={index}
            className={`${classes.blogPostSectionBody} ${classes.p12} ${classes.m12}`}
          >
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={formClasses.blogPostImageContainer}>
                    <BlogPostImage
                      blogTitle={blogPostDetails.blogTitle}
                      imageData={blogPostDetails.blogImage as string}
                      altTag={blogPostDetails.altTag}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Carousel>
    </>
  )
}

export default BlogPostSection
