import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import useFormStyles from '../styledComponents/globalFormStying.module'
import MobileHeader from '../mobileHeader/mobileHeader'
import { useMediaQueries, handleNavigate } from '../../utils/global-utils'
import { Controller, useForm } from 'react-hook-form'
import { StyledInput } from '../styledComponents/styledInput/styledInput'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import StyledButton from '../styledComponents/styledButton.module'
import RegisterUserApi from '../../services/registerApi'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import Alert from '@mui/material/Alert'
import FormHelperText from '@mui/material/FormHelperText/FormHelperText'
import useStyles from './passwordResetVerify.module'

function PasswordResetVerify() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { token } = useParams()
  const navigate = useNavigate()
  const { isMobile } = useMediaQueries()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorModal, setErrorModal] = useState(false)

  const { handleSubmit, formState, control, trigger, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      token: token,
      password: '',
      confirmPassword: ''
    }
  })

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onSubmit = async (formData: any) => {
    try {
      const response: any = await RegisterUserApi.ResetPassword(formData)
      const { status } = response
      if (status === 200) {
        handleNavigate(navigate, `/login`)
      }
    } catch {
      setErrorModal(true)
    }
  }

  const handleButtonClick = async () => {
    try {
      await trigger()
      if (Object.keys(formState.errors).length === 0) {
        setLoading(true)
        await handleSubmit(onSubmit)()
        setLoading(false)
      }
    } catch {
      //
    }
  }

  return (
    <>
      <Grid container justify-content='center' className={formClasses.container}>
        <MobileHeader />
        <Grid item xs={12} sm={12} md={12}>
          <form onSubmit={handleButtonClick}>
            <div className={isMobile ? formClasses.formContainerMobile : formClasses.formContainer}>
              <div className={formClasses.formText}>
                <Typography className={formClasses.headline}>Resetovanje lozinke</Typography>
                <Typography className={formClasses.headlineSmall}>Unesite novu lozinku i potvrdite</Typography>
              </div>
              <div className={formClasses.inputContainer}>
                <Controller
                  name='password'
                  control={control}
                  rules={{
                    required: 'Polje je obavezno',
                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
                      message: 'Pogrešan šablon'
                    }
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <div className={classes.formHelperContainer}>
                          <FormHelperText className={classes.formHelperText}>
                            Lozinka mora sadržati barem jedno slovo, jedan broj <br />i biti dugačka najmanje 8 karaktera
                          </FormHelperText>
                        </div>
                        <StyledInput
                          {...field}
                          required
                          value={field.value}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onMouseDown={handleTogglePassword}
                                onMouseUp={handleTogglePassword}
                                onMouseLeave={() => setShowPassword(false)}
                                onTouchStart={handleTogglePassword}
                                onTouchEnd={handleTogglePassword}
                                edge='end'
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            )
                          }}
                          label='Nova lozinka'
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          inputRef={field.ref}
                        />
                      </>
                    )
                  }}
                />
              </div>
              <div className={formClasses.inputContainer}>
                <Controller
                  name='confirmPassword'
                  control={control}
                  rules={{
                    required: 'Polje je obavezno',
                    validate: (value) => value === getValues('password') || 'Lozinke se ne podudaraju',
                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
                      message: 'Pogrešan šablon'
                    }
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <StyledInput
                        {...field}
                        required
                        value={field.value}
                        type={showConfirmPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onMouseDown={handleToggleConfirmPassword}
                              onMouseUp={handleToggleConfirmPassword}
                              onMouseLeave={() => setShowConfirmPassword(false)}
                              onTouchStart={handleToggleConfirmPassword}
                              onTouchEnd={handleToggleConfirmPassword}
                              edge='end'
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          )
                        }}
                        label='Potvrdi lozinku'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        inputRef={field.ref}
                      />
                    )
                  }}
                />
              </div>
              <div className={formClasses.inputContainer}>
                <StyledButton style={{ textTransform: 'none' }} onClick={handleButtonClick} variant='contained'>
                  {loading ? <CircularProgress size={24} className='circular-progress' /> : 'Promeni lozinku'}
                </StyledButton>
              </div>
              {errorModal && (
                <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                  <Alert
                    className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                    onClose={() => setErrorModal(false)}
                    severity='error'
                  >
                    Došlo je do greške!
                  </Alert>
                </Backdrop>
              )}
            </div>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default PasswordResetVerify
