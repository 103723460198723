import axios from 'axios'
import { APP_AUTH } from '../environment'

const LOGIN_USER = '/User/Login'
const GOOGLE_USER_INFO_URL = 'https://www.googleapis.com/oauth2/v1/userinfo'

const api = axios.create({
  baseURL: APP_AUTH
})

const apiPost = (operation: string, data: object) => {
  return new Promise((resolve, reject) => {
    api
      .post(operation, data)
      .then((res) => {
        if (res.status === 200) {
          const { idToken, accessToken } = res.data
          resolve({ idToken, accessToken })
        } else {
          reject(new Error('Failed to retrieve tokens.'))
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const LoginUser = async (data: object) => {
  return apiPost(LOGIN_USER, data)
}

const fetchUserProfile = async (accessToken: any) => {
  return axios
    .get(`${GOOGLE_USER_INFO_URL}?access_token=${accessToken}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      }
    })
    .then((response) => response.data)
}

const LoginUserApi = {
  LoginUser,
  fetchUserProfile
}

export default LoginUserApi
