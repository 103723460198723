import { createContext, ReactNode, useState, useEffect, useContext } from 'react'
import jwt_decode from 'jwt-decode'
import { UserContextProps, UserData } from '../utils/types.d'
import Cookies from 'js-cookie'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useNavigate } from 'react-router-dom'

interface DecodedAccessToken {
  exp: number
}

const UserContext = createContext<UserContextProps>({
  userData: null,
  idToken: null,
  accessToken: null,
  availableJobsId: null,
  setAvailableJobsId: () => {},
  isLoggedIn: false,
  setTokens: () => {},
  logout: () => {}
})

interface UserProviderProps {
  children: ReactNode
}

export const useAuthContext = () => useContext(UserContext)

const mapIdTokenToUserData = (idToken: string): UserData => {
  const decodedToken: any = jwt_decode(idToken)

  return {
    nameid: decodedToken.nameid,
    unique_name: decodedToken.unique_name,
    given_name: decodedToken.given_name,
    family_name: decodedToken.family_name,
    email: decodedToken.email,
    birthdate: new Date(decodedToken.birthdate),
    country: decodedToken.country,
    nbf: decodedToken.nbf,
    exp: decodedToken.exp,
    iat: decodedToken.iat,
    iss: decodedToken.iss,
    aud: decodedToken.aud
  }
}

interface DecodedAccessToken {
  exp: number
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout | null>(null)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [showTokenExpirationModal, setShowTokenExpirationModal] = useState(false)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [availableJobsId, setAvailableJobsId] = useState<string | null>(null)

  const navigate = useNavigate()

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'

  const setTokens = (idToken: string, accessToken: string) => {
    setIdToken(idToken)
    setAccessToken(accessToken)
    const userData = mapIdTokenToUserData(idToken)

    Cookies.set('idToken', idToken)
    Cookies.set('accessToken', accessToken)
    Cookies.set('userData', JSON.stringify(userData))
    localStorage.setItem('isLoggedIn', 'true')
    setUserData(userData)
  }

  const logout = () => {
    setIdToken(null)
    setAccessToken(null)
    setUserData(null)

    Cookies.remove('idToken')
    Cookies.remove('accessToken')
    Cookies.remove('userData')
    localStorage.removeItem('isLoggedIn')

    if (logoutTimer) {
      clearTimeout(logoutTimer)
      setLogoutTimer(null)
    }

    setShowTokenExpirationModal(false)
    navigate('/login')
  }

  const checkTokenExpiration = () => {
    if (accessToken) {
      try {
        const decodedAccessToken: DecodedAccessToken = jwt_decode<DecodedAccessToken>(accessToken)
        const currentTime = Date.now() / 1000
        if (decodedAccessToken.exp < currentTime) {
          setShowTokenExpirationModal(true)
          logout()
        }
      } catch {
        //
      }
    }
  }

  const handleOnlineStatusChange = () => {
    setIsOnline(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  useEffect(() => {
    const checkTokenAndCookies = () => {
      checkTokenExpiration()

      const userDataFromCookies = Cookies.get('userData')
      const accessTokenFromCookies: any = Cookies.get('accessToken')
      const idTokenFromCookies: any = Cookies.get('idToken')

      if (userDataFromCookies) {
        try {
          const userDataParsed = JSON.parse(userDataFromCookies)
          setUserData(userDataParsed)
          setIdToken(idTokenFromCookies)
        } catch {
          //
        }
      }

      if (accessTokenFromCookies) {
        try {
          setAccessToken(accessTokenFromCookies)
        } catch {
          //
        }
      }
    }

    checkTokenAndCookies()

    return checkTokenAndCookies
  }, [accessToken])

  useEffect(() => {
    if (!isOnline) {
      setShowTokenExpirationModal(true)
      logout()
    }
  }, [isOnline])

  const contextValue: UserContextProps = {
    userData,
    idToken,
    accessToken,
    availableJobsId,
    setAvailableJobsId,
    isLoggedIn,
    setTokens,
    logout
  }

  return (
    <>
      <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
      {showTokenExpirationModal && (
        <Dialog open={showTokenExpirationModal} aria-describedby='logout-dialog'>
          <DialogContent>
            <DialogContentText id='logout-dialog'>Sesija je istekla, odjavljujemo vas.</DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default UserContext
