import { useEffect, useState } from 'react'
import { useAuthContext } from '../../context/UserContext'
import EmployerApi from '../../services/employerApi'
import EmployeeApi from '../../services/employeeApi'
import { getFullName, getUserId, useMediaQueries } from '../../utils/global-utils'
import { EmployeeDetailsResponse, EmployerDetailsResponse } from '../../utils/types.d'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'
import useStyles from './fetchUserDetails.module'
import jwt_decode from 'jwt-decode'

function FetchUserDetails() {
  const classes = useStyles()
  const { userData, accessToken } = useAuthContext()
  const fullName = getFullName(userData)
  const { isMobile } = useMediaQueries()
  const [userRole, setUserRole] = useState<string | null>(null)
  const [fetchEmployerDetails, setFetchEmployerDetails] = useState<EmployerDetailsResponse | null>(null)
  const [fetchEmployeeDetails, setFetchEmployeeDetails] = useState<EmployeeDetailsResponse | null>(null)
  const userId = getUserId(userData)
  useEffect(() => {
    const fetchDetails = async () => {
      if (!accessToken) return
      try {
        if (userRole === 'employer') {
          const employerDetailsResponse = await EmployerApi.GetEmployerDetails(accessToken, userId)
          if (employerDetailsResponse.status === 200) {
            const data = employerDetailsResponse.data
            setFetchEmployerDetails(data)
          }
        } else if (userRole === 'employee') {
          const employeeDetailsResponse = await EmployeeApi.GetEmployeeDetails(accessToken, userId)
          if (employeeDetailsResponse.status === 200) {
            const data = employeeDetailsResponse.data
            setFetchEmployeeDetails(data)
          }
        }
      } catch (error) {
        console.error('Error fetching details:', error)
      }
    }

    fetchDetails()
  }, [accessToken, userId, userRole])

  useEffect(() => {
    if (accessToken) {
      try {
        const decodedAccessToken: any = jwt_decode(accessToken)
        setUserRole(decodedAccessToken.role)
      } catch {
        //
      }
    }
  }, [accessToken])

  return (
    <>
      {userRole === 'employer' ? (
        <div className={`${isMobile ? '' : classes.dflex}`}>
          <div className={classes.adHeadlineDetails}>
            <WorkOutlineOutlinedIcon />
            <span className={classes.adDetailsText}>{fetchEmployerDetails?.companyName}</span>
          </div>
          <div className={classes.adHeadlineDetails}>
            <FactoryOutlinedIcon />
            <span className={classes.adDetailsText}>
              {fetchEmployerDetails?.industries.map((industry) => industry?.industryName).join(', ')}
            </span>
          </div>
          <div className={classes.adHeadlineDetails}>
            <PublicOutlinedIcon />
            <span className={classes.adDetailsText}>{fetchEmployerDetails?.country?.countryName}</span>
          </div>
          <div className={classes.adHeadlineDetails}>
            <LocationCityOutlinedIcon />
            <span className={classes.adDetailsText}>{fetchEmployerDetails?.city?.city}</span>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.adHeadlineDetails}>
            <PersonOutlinedIcon />
            <span className={classes.adDetailsText}>{fullName}</span>
          </div>
          <div className={classes.adHeadlineDetails}>
            <PublicOutlinedIcon />
            <span className={classes.adDetailsText}>{fetchEmployeeDetails?.country.countryName}</span>
          </div>
          <div className={classes.adHeadlineDetails}>
            <LocationCityOutlinedIcon />
            <span className={classes.adDetailsText}>{fetchEmployeeDetails?.city?.city}</span>
          </div>
        </>
      )}
    </>
  )
}

export default FetchUserDetails
