import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  formHelperContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  formHelperText: {
    display: 'flex',
    fontSize: '12px !important'
  }
}))

export default useStyles
