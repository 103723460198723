import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  adHeadlineContainer: {
    color: '#636366',
    fontSize: '24px',
    lineHeight: '24px',
    padding: '18px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  adHeadlineContainerMobile: {
    color: '#636366',
    fontSize: '18px',
    lineHeight: '18px',
    padding: '24px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  adHeadlineDetails: {
    color: '#636366',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '12px 12px 12px 18px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center'
  },
  adHeadlineDetailsDesc: {
    color: '#636366',
    fontSize: '16px',
    lineHeight: '21px',
    padding: '12px 12px 12px 18px',
    fontWeight: '500',
    display: 'block',
    alignItems: 'center',
    whiteSpace: 'pre-line'
  },
  adDetailsText: {
    paddingTop: '6px',
    paddingLeft: '6px'
  },
  table: {
    borderRadius: '18px !important'
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#EBEFFF !important'
    }
  },
  detailsText: {
    color: '#636366 !important',
    fontSize: '14px !important',
    fontWeight: 'bold !important'
  },
  detailsHeadlines: {
    color: '#636366 !important',
    fontSize: '14px !important'
  },
  addDescriptionTextPreview: {
    fontSize: '16px',
    color: '#636366'
  }
}))

export default useStyles
