import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid/Grid'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import logoBlue from '../../assets/logo_blue.svg'
import useStyles from './functionalHeader.module'
import useFormStyles from '../styledComponents/globalFormStying.module'
import { getFullName, handleNavigate, useMediaQueries } from '../../utils/global-utils'
import { useAuthContext } from '../../context/UserContext'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import jwt_decode from 'jwt-decode'
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp'
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp'
import PolicySharpIcon from '@mui/icons-material/PolicySharp'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import { googleLogout } from '@react-oauth/google'
import FetchUserDetails from '../fetchUserDetails/fetchUserDetails'

function FunctionalHeader() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { userData, logout, accessToken } = useAuthContext()
  const fullName = getFullName(userData)
  const [userRole, setUserRole] = useState<string | null>(null)
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    if (accessToken) {
      try {
        const decodedAccessToken: any = jwt_decode(accessToken)
        setUserRole(decodedAccessToken.role)
      } catch {
        //
      }
    }
  }, [accessToken])

  const handleNavigateToProfile = () => {
    if (userRole === 'employer') {
      handleNavigate(navigate, `/employer-profile/`)
    }
    if (userRole === 'employee') {
      handleNavigate(navigate, '/employee-profile/')
    }
  }

  const handleLogout = () => {
    googleLogout()
    logout()
  }

  return (
    <>
      <AppBar className={isMobile ? classes.functionalHeaderMobile : classes.functionalHeader}>
        <Toolbar>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <div className={classes.logoContainer}>
                <img className={classes.logo} alt='Sezonal' src={`${logoBlue}`} />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.notifierContainer}>
                {!isMobile && <FetchUserDetails />}
                <div className={classes.adHeadlineDetails}>
                  <LogoutIcon onClick={handleLogout} className={`${classes.drawerIcons} ${classes.cpointer}`} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        variant='temporary'
        PaperProps={{
          style: {
            width: isMobile ? '80%' : '20%',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <List>
          <div className={formClasses.p16}>
            <ListItem disablePadding>
              <ListItemButton onClick={handleNavigateToProfile}>
                <ListItemIcon>
                  <AccountCircleSharpIcon className={classes.drawerIcons} />
                </ListItemIcon>
                {fullName ? <ListItemText primary={`Moj profil (${fullName})`} /> : <ListItemText primary={`Moj profil`} />}
              </ListItemButton>
            </ListItem>
          </div>
          <div className={formClasses.p16}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigate(navigate, `/terms-and-conditions`)}>
                <ListItemIcon>
                  <DescriptionSharpIcon className={classes.drawerIcons} />
                </ListItemIcon>
                <ListItemText primary={'Uslovi korišćenja'} />
              </ListItemButton>
            </ListItem>
          </div>
          <div className={formClasses.p16}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigate(navigate, '/privacy-policy')}>
                <ListItemIcon>
                  <PolicySharpIcon className={classes.drawerIcons} />
                </ListItemIcon>
                <ListItemText primary={'Politika privatnosti'} />
              </ListItemButton>
            </ListItem>
          </div>
          <Divider />
          <div className={`${formClasses.inputContainerTop} ${formClasses.p16}`}>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon className={classes.drawerIcons} />
                </ListItemIcon>
                <ListItemText primary={'Odjavi se'} />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </Drawer>
    </>
  )
}

export default FunctionalHeader
