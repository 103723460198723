import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  profileImage: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    '&:focus': {
      outline: 'none !important'
    }
  }
}))

export default useStyles
