import { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined'
import { IconButton } from '@mui/material'
import { useStyles } from './landingNavigation.module'

function ScrollToTopButton() {
  const classes = useStyles()
  const [showButton, setShowButton] = useState(false)

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0
  })

  useEffect(() => {
    setShowButton(!inView)
  }, [inView])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <div ref={ref} style={{ position: 'absolute', top: 0 }}></div>
      {showButton && (
        <IconButton onClick={scrollToTop} className={classes.scrollToTopButton}>
          <KeyboardDoubleArrowUpOutlinedIcon className={classes.scrollToTopIcon} />
        </IconButton>
      )}
    </>
  )
}

export default ScrollToTopButton
