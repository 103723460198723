import { Grid } from '@material-ui/core'
import useStyles from './layoutItem.module'
import { styled } from '@mui/system'
import logo from '../../assets/logo.png'

interface LayoutItemProps {
  imageSrc: string
  alt: string
  children: React.ReactNode
}

const StyledLayout = styled(Grid)``
function StyledLayoutItem({ imageSrc, alt, children }: LayoutItemProps) {
  const classes = useStyles()
  return (
    <StyledLayout item xs={12} sm={12} md={6}>
      <div className={classes.layoutItemContainer}>
        <img className={classes.layoutItem} alt={alt} src={imageSrc} />
        <div className={classes.textContainer}>
          <span className={classes.headlineLarge}>
            Dobrodošli na <br /> najveću berzu <br /> sezonskih poslova!
          </span>
        </div>
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt='Sezonal' src={`${logo}`} />
        </div>
        {children}
      </div>
    </StyledLayout>
  )
}

export default StyledLayoutItem
