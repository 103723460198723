import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  cardsHeightMobile: {
    height: '332px !important',
    maxHeight: '332px !important'
  },
  chipsContainer: {
    display: 'flex',
    flexDirection: 'column',
    algnItems: 'flex-end'
  },
  standardChip: {
    backgroundColor: '#0033FF33 !important',
    opacity: '80%',
    color: '#001A80 !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    fontSize: '14px !important',
    marginRight: '6px !important'
  },
  sponsoredChip: {
    backgroundColor: '#BFA173 !important',
    color: '#FFEFD6 !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    fontSize: '14px !important',
    border: '1px solid #BFA173 !important',
    marginRight: '6px !important'
  },
  premiumChip: {
    backgroundColor: '#001A80 !important',
    color: '#FFFFFF !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    fontSize: '14px !important',
    border: '1px solid #0033FF !important',
    marginRight: '6px !important'
  },
  newAppliesChip: {
    backgroundColor: '#FF5C0D !important',
    opacity: '60%',
    color: '#FFFFFF !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px  !important',
    marginLeft: '12px'
  },
  numberOfCandidatesChip: {
    backgroundColor: '#EBEFFF !important',
    border: '1px solid #0033FF',
    color: '#0033FF !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px  !important',
    marginLeft: '12px'
  },
  fieldGroupChip: {
    backgroundColor: '#EBEFFF !important',
    border: '1px solid #0033FF',
    color: '#0033FF !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    margin: '2px'
  },
  dividerSponsored: {
    border: '1px solid #BFA173 !important',
    opacity: '10%'
  },
  dividerPremium: {
    border: '1px solid #0033FF !important',
    opacity: '10%'
  },
  dividerRegular: {
    border: '1px solid #0033FF !important',
    opacity: '10%'
  },
  headlineContainer: {
    marginTop: '18px',
    marginBottom: '36px',
    width: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  adTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#2C2C2E'
  },
  adTitleMobile: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#2C2C2E'
  },
  myAddContainers: {
    marginTop: '18px',
    marginBottom: '6px'
  },
  myAddDetails: {
    display: 'flex'
  },
  explanationIcons: {
    color: '#001A80',
    width: '16px !important',
    height: '16px !important',
    paddingLeft: '12px',
    paddingRight: '6px',
    '&:first-child': {
      paddingLeft: '0px'
    }
  },
  detailsTextContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  },
  detailsText: {
    color: '#636366',
    fontSize: '16px'
  },
  // utils
  cpr: {
    cursor: 'pointer'
  }
}))

export default useStyles
