import {
  formatBillingType,
  formatCity,
  formatCountry,
  formatCurrency,
  formatDate,
  generateOfferedItemsText,
  handleNavigate,
  useMediaQueries
} from '../../../utils/global-utils'
import Grid from '@mui/material/Grid/Grid'
import Chip from '@mui/material/Chip/Chip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PlaceIcon from '@mui/icons-material/Place'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import Divider from '@mui/material/Divider/Divider'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useNavigate } from 'react-router-dom'
import useStyles from './singleAd.module'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import classNames from 'classnames'
import Card from '@mui/material/Card/Card'
import { CardContent } from '@mui/material'

const SingleAd = ({ addDetails }: any) => {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()

  const navigateToMyAd = () => {
    handleNavigate(navigate, '/my-ad', addDetails.adId)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const standardAdType = addDetails.adType === 'Standardni oglas'
  const sponsoredAdType = addDetails.adType === 'Sponzorisani oglas'
  const premiumAdType = addDetails.adType === 'Premium oglas'

  const dividerClassName = classNames({
    [classes.dividerRegular]: standardAdType,
    [classes.dividerSponsored]: sponsoredAdType,
    [classes.dividerPremium]: premiumAdType
  })

  return (
    <>
      <Card onClick={navigateToMyAd} className={`${formClasses.mtb12} ${classes.landingCardsBody}`}>
        <CardContent>
          <Grid container justifyContent='space-between' alignItems='center' className={classes.cpr}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} className={formClasses.pb12}>
                    {standardAdType && <Chip label='Standardni oglas' className={classes.standardChip} />}
                    {sponsoredAdType && <Chip label='Sponzorisan oglas' className={classes.sponsoredChip} />}
                    {premiumAdType && (
                      <Chip icon={<StarRoundedIcon className={formClasses.star} />} label='Premium oglas' className={classes.premiumChip} />
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    {addDetails.industries.map((industry: any, key: string) => (
                      <>
                        {industry?.industryName ? (
                          <Chip key={`${key}`} label={`${industry?.industryName}`} className={classes.fieldGroupChip} />
                        ) : (
                          <Chip key={`${key}`} label={`${industry}`} className={classes.fieldGroupChip} />
                        )}
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              {!isMobile && (
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container>
                    <Grid display='flex' justifyContent='flex-end' item xs={12} sm={12} md={12}>
                      <div className={classes.chipsContainer}>
                        <Chip
                          label={`${addDetails.numberOfCandidates} kandidata`}
                          className={`${classes.numberOfCandidatesChip} ${formClasses.mb6}`}
                        />
                        <Chip label={`${addDetails.numberOfNewCandidates} novih`} className={classes.newAppliesChip} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${isMobile ? classes.adTitleMobile : classes.adTitle} ${classes.headlineContainer}`}>
                  {addDetails.adTitle}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={dividerClassName} />
          <div className={`${classes.myAddDetails} ${classes.myAddContainers}`}>
            <Grid container>
              <Grid display='flex' item xs={12} md={6}>
                <div className={classes.detailsTextContainer}>
                  <PlaceIcon className={classes.explanationIcons} />
                  <span className={classes.detailsText}>
                    {formatCity(addDetails.city)} , {formatCountry(addDetails.country)}
                  </span>
                </div>
              </Grid>
              <Grid display='flex' item xs={12} md={6}>
                <div className={classes.detailsTextContainer}>
                  <CalendarMonthIcon className={classes.explanationIcons} />
                  <span className={classes.detailsText}>
                    {formatDate(addDetails.workingStartDate)} / {addDetails.workingEndDate ? formatDate(addDetails.workingEndDate) : '_'}
                  </span>
                </div>
              </Grid>
              <Grid display='flex' item xs={12} md={6}>
                {addDetails?.salaryValue && addDetails?.currency && addDetails?.billingType ? (
                  <>
                    <div className={classes.detailsTextContainer}>
                      <MonetizationOnIcon className={classes.explanationIcons} />
                      <span className={classes.detailsText}>
                        {addDetails.salaryValue} {formatCurrency(addDetails.currency)}, {formatBillingType(addDetails.billingType)}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.detailsTextContainer}>
                      <MonetizationOnIcon className={classes.explanationIcons} />
                      <span className={classes.detailsText}>Isplata po dogovoru</span>
                    </div>
                  </>
                )}
              </Grid>
              <Grid display='flex' item xs={12} md={6}>
                {addDetails.offeredFood || addDetails.offeredAccomodation || addDetails.offeredTransport ? (
                  <>
                    <div className={classes.detailsTextContainer}>
                      <CheckCircleIcon className={`${classes.checkIcon} ${classes.explanationIcons}`} />
                      <span className={classes.detailsText}>{generateOfferedItemsText(addDetails)}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.detailsTextContainer}>
                      <RadioButtonUncheckedIcon className={`${classes.checkIcon} ${classes.explanationIcons}`} />
                      <span className={classes.detailsText}>Bez benefita</span>
                    </div>
                  </>
                )}
              </Grid>
              <Grid display='flex' item xs={12} md={6}>
                {addDetails.companyName && (
                  <Grid display={'flex'} item xs={12} sm={12} md={3}>
                    <div className={isMobile ? classes.companyNameMobile : classes.companyName}>{addDetails.companyName}</div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default SingleAd
