import axios, { AxiosResponse } from 'axios'
import { APP_CORE } from '../environment'

const GET_ALL_INDUSTRIES = '/Industry/getAllIndustries'
const GET_ALL_CURRENCIES = '/Billing/getAllCurrencies'
const GET_ALL_BILLING_TYPES = '/Billing/getAllBillingTypes'
const GET_ALL_PLACES = '/Place'
const GET_ALL_COUNTRIES = '/Country'
const GET_ALL_JOBS_OF_INTEREST = '/Industry/getAllJobsOfInterest'

const api = axios.create({
  baseURL: APP_CORE
})

const apiGet = (operation: string, data: object): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    api
      .get(operation, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const apiGetPlaces = (operation: string, countryId: string | string[]): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    api
      .get(operation, {
        params: {
          CountryId: countryId
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const GetAllJobsOfInterest = async (data: object) => {
  return apiGet(GET_ALL_JOBS_OF_INTEREST, data)
}

const GetIndustries = async (data: object) => {
  return apiGet(GET_ALL_INDUSTRIES, data)
}

const GetCurrencies = async (data: object) => {
  return apiGet(GET_ALL_CURRENCIES, data)
}

const getBiillingTypes = async (data: object) => {
  return apiGet(GET_ALL_BILLING_TYPES, data)
}

const getAllPlaces = async (countryId: string | string[]) => {
  return apiGetPlaces(GET_ALL_PLACES, countryId)
}

const getAllCountries = async (data: object) => {
  return apiGet(GET_ALL_COUNTRIES, data)
}

const BillingAndIndustriesApi = {
  GetAllJobsOfInterest,
  GetIndustries,
  GetCurrencies,
  getBiillingTypes,
  getAllPlaces,
  getAllCountries
}
export default BillingAndIndustriesApi
