import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
  },
  actionButtonsContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px'
  },
  actionButtonsHolder: {
    marginBottom: '12px'
  },
  addDescriptionContainer: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  addDescriptionText: {
    fontSize: '16px',
    color: '#636366'
  },
  detailsText: {
    marginBottom: '20px'
  },
  messageContainer: {
    width: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  moreInfoDetails: {
    fontSize: '16px',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  sponsoredChip: {
    backgroundColor: '#BFA173 !important',
    color: '#FFEFD6 !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    margin: '2px'
  }
}))

export default useStyles
