import { formatDate, generateOfferedItemsText, handleNavigate, useMediaQueries } from '../../../utils/global-utils'
import FunctionalHeader from '../../functionalHeader/functionalHeader'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import useStyles from '../adProfilePreview/adProfilePreview.module'
import Grid from '@mui/material/Grid/Grid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Divider from '@mui/material/Divider'
import StyledButton from '../../styledComponents/styledButton.module'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../../context/UserContext'
import { SelectedAddResponse, Candidate, AdDetails } from '../../../utils/types.d'
import AddApi from '../../../services/addApi'
import StyledConfirmationModal from '../../styledComponents/styledConfirmationModal/styledConfirmationModal'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined'
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined'
import DetailedCard from '../../wwwApp/detailedCard/detailedCard'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import Alert from '@mui/material/Alert'
import Typography from '@material-ui/core/Typography/Typography'
import LandingFooter from '../../wwwLandingPage/landingPageBody/landingFooter'

function MyAddDetailed() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  const { addId } = useParams()
  const { accessToken, userData } = useAuthContext()
  const [currentComponent, setCurrentComponent] = useState('details')
  const [fetchSelectedAdd, setfFetchSelectedAdd] = useState<SelectedAddResponse[]>([])
  const [fetchReceivedApplications, setFetchReceivedApplications] = useState<Candidate[]>([])
  const [loading, setLoading] = useState(true)
  const [deleteAdd, setDeleteAdd] = useState(false)
  const [, setIsDeleting] = useState(false)
  const [closeApplications, setCloseApplications] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [errorModalDeleteAd, setErrorModalDeleteAd] = useState(false)
  const employerId = userData?.nameid

  useEffect(() => {
    const fetchAdd = async () => {
      try {
        if (accessToken) {
          const response: any = await AddApi.GetSelectedAd(accessToken, addId)
          const { status, data } = response
          if (status === 200) {
            setfFetchSelectedAdd([data])
          }
          setLoading(false)
        }
      } catch {
        setfFetchSelectedAdd([])
        setLoading(false)
      }
    }

    if (accessToken) {
      setLoading(true)
      fetchAdd()
    }
  }, [accessToken])

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        if (accessToken) {
          const response: any = await AddApi.GetReceivedApplicationsByAdId(accessToken, addId)
          const { status, data } = response
          if (status === 200) {
            setFetchReceivedApplications(data)
          }
        }
      } catch {
        //
      }
    }

    if (accessToken) {
      fetchApplications()
    }
  }, [accessToken])

  const handleComponentChange = (_event: any, newComponent: string) => {
    if (currentComponent === newComponent) {
      return
    }
    setCurrentComponent(newComponent)
  }

  const handleCloseApplications = async () => {
    try {
      const dataObject = {
        employerId: employerId,
        adId: addId
      }
      await AddApi.CloseApplications(accessToken, dataObject)
    } catch {
      setErrorModal(true)
    } finally {
      setCloseApplications(false)
    }
  }

  const handleDeleteAdd = async () => {
    setIsDeleting(true)
    try {
      await AddApi.DeleteAdd(accessToken, addId, employerId)
      handleNavigate(navigate, '/employer-dashboard')
    } catch {
      setErrorModalDeleteAd(true)
    } finally {
      setIsDeleting(false)
      setDeleteAdd(false)
    }
  }

  const adId = fetchSelectedAdd[0]?.adId

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <FunctionalHeader />
            </Grid>
          </Grid>
          <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
            <div className={formClasses.companyNameHeadline}>
              <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Moj oglas
            </div>
          </div>
          <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
            <Grid item xs={12} sm={12} md={6}>
              <ToggleButtonGroup
                className={formClasses.buttonsGroup}
                value={currentComponent}
                exclusive
                onChange={handleComponentChange}
                aria-label='component-switcher'
              >
                <ToggleButton
                  className={formClasses.singleToggleButton}
                  value='details'
                  aria-label='details-layout'
                  disabled={currentComponent === 'details'}
                >
                  Detalji
                </ToggleButton>
                <ToggleButton
                  className={formClasses.singleToggleButton}
                  value='applications'
                  aria-label='applications-layout'
                  disabled={currentComponent === 'applications'}
                >
                  Prijave
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
            <div className={`${formClasses.bodyItemsHolder} ${formClasses.inputContainerTop}`}>
              {loading ? (
                <Backdrop open={true}>
                  <div className={formClasses.circularProgressContainer}>
                    <StyledSpinningIcon />
                  </div>
                </Backdrop>
              ) : (
                <>
                  {currentComponent === 'details' ? (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        {fetchSelectedAdd?.map((addDetails, index) => (
                          <>
                            <Grid container key={index}>
                              <Grid item xs={12} sm={12} md={12}>
                                <div className={`${isMobile ? classes.adHeadlineContainerMobile : classes.adHeadlineContainer}`}>
                                  <span>{addDetails.adTitle}</span>
                                </div>
                                {!addDetails.isOpenForApplications && <span>Prijave na ovom oglasu su zatvorene</span>}
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                <div className={classes.adHeadlineDetails}>
                                  <DescriptionOutlinedIcon />
                                  <span className={classes.adDetailsText}>OPIS OGLASA</span>
                                </div>
                                <div className={classes.adHeadlineDetailsDesc}>
                                  <span>{addDetails.adDescription}</span>
                                </div>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                  <div className={classes.adHeadlineDetails}>
                                    <InsertChartOutlinedOutlinedIcon />
                                    <span className={classes.adDetailsText}>DETALJI OGLASA</span>
                                  </div>
                                  <div className={classes.adHeadlineDetails}>
                                    <FactoryOutlinedIcon />
                                    <span className={classes.adDetailsText}>
                                      {addDetails.industries.map((industry) => industry.industryName).join(', ')}
                                    </span>
                                  </div>
                                  <div className={classes.adHeadlineDetails}>
                                    <PublicOutlinedIcon />
                                    <span className={classes.adDetailsText}>{addDetails.country.countryName}</span>
                                  </div>
                                  <div className={classes.adHeadlineDetails}>
                                    <LocationCityOutlinedIcon />
                                    <span className={classes.adDetailsText}>{addDetails.city.city}</span>
                                  </div>
                                  <div className={classes.adHeadlineDetails}>
                                    <CalendarMonthOutlinedIcon />
                                    <span className={classes.adDetailsText}>
                                      {formatDate(addDetails.workingStartDate)} /{' '}
                                      {addDetails.workingEndDate === null ? '_' : formatDate(addDetails.workingEndDate)}
                                    </span>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <div className={classes.adHeadlineDetails}>
                                    <WatchLaterOutlinedIcon />
                                    <span className={classes.adDetailsText}>{`${addDetails.workingHours}h`}</span>
                                  </div>
                                  {addDetails?.billingType?.typeOfBilling && (
                                    <div className={classes.adHeadlineDetails}>
                                      <WorkOutlineOutlinedIcon />
                                      <span className={classes.adDetailsText}>{addDetails.billingType?.typeOfBilling}</span>
                                    </div>
                                  )}
                                  {addDetails?.salaryValue && addDetails?.currency?.currencyName ? (
                                    <>
                                      <div className={classes.adHeadlineDetails}>
                                        <EuroSymbolOutlinedIcon />
                                        <span className={classes.adDetailsText}>
                                          {addDetails?.salaryValue} {addDetails?.currency?.currencyName}
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className={classes.adHeadlineDetails}>
                                        <EuroSymbolOutlinedIcon />
                                        <span className={classes.adDetailsText}>Isplata po dogovoru</span>
                                      </div>
                                    </>
                                  )}
                                  <div className={classes.adHeadlineDetails}>
                                    <WatchLaterOutlinedIcon />
                                    <span className={classes.adDetailsText}>
                                      {generateOfferedItemsText(addDetails as unknown as AdDetails) || 'Bez benefita'}
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                      <div className={formClasses.inputContainerTop}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={4}>
                            <div className={formClasses.mb12}>
                              <StyledButton
                                className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                style={{ textTransform: 'none' }}
                                onClick={() => handleNavigate(navigate, `/edit-ad/${addId}`)}
                                variant='contained'
                              >
                                Izmeni oglas
                              </StyledButton>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <div className={formClasses.mb12}>
                              <StyledButton
                                className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                style={{ textTransform: 'none' }}
                                onClick={() => setCloseApplications(true)}
                                variant='text'
                                disabled={fetchSelectedAdd && fetchSelectedAdd[0]?.isOpenForApplications !== true}
                              >
                                {fetchSelectedAdd[0]?.isOpenForApplications !== true ? 'Zatvorene prijave' : 'Zatvori prijave'}
                              </StyledButton>
                              <StyledConfirmationModal
                                open={closeApplications}
                                onClose={() => setCloseApplications(false)}
                                onConfirm={handleCloseApplications}
                                bodyText='Da li ste sigurni da želite da zatvorite prijave na oglasu?'
                              />
                              {errorModal && (
                                <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                  <Alert
                                    className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                    onClose={() => setErrorModal(false)}
                                    severity='error'
                                  >
                                    Prijave na ovom oglasu su zatvorene
                                  </Alert>
                                </Backdrop>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <StyledButton
                              className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                              style={{ textTransform: 'none' }}
                              onClick={() => setDeleteAdd(true)}
                              variant='outlined'
                            >
                              Obriši oglas
                            </StyledButton>
                            {errorModalDeleteAd && (
                              <Backdrop className={formClasses.zIndexHighest} open={errorModalDeleteAd}>
                                <Alert
                                  className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                  onClose={() => setErrorModalDeleteAd(false)}
                                  severity='error'
                                >
                                  Došlo je do greške pri brisanju oglasa
                                </Alert>
                              </Backdrop>
                            )}
                            <Dialog open={deleteAdd} onClose={() => setDeleteAdd(false)}>
                              <DialogContent className={isMobile ? classes.mobileDialogBody : ' '}>
                                <Typography>Da li ste sigurni da želite da izbrišete oglas?</Typography>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={() => setDeleteAdd(false)} autoFocus>
                                  Ne
                                </Button>
                                <Button onClick={handleDeleteAdd} autoFocus>
                                  Da
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        {fetchReceivedApplications.length === 0 ? (
                          <>
                            <div className={formClasses.inputContainerTop}>
                              <div className={`${formClasses.canditateName} ${formClasses.ml28}`}>Nema prijava na ovom oglasu</div>
                            </div>
                            <Divider />
                          </>
                        ) : (
                          <DetailedCard addDetails={fetchReceivedApplications} adId={adId} />
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </>
  )
}

export default MyAddDetailed
