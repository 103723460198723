import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  container: {
    height: '100vh'
  },
  rolePickerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '84px',
    paddingBottom: '160px'
  },
  rolePickerText: {
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  headline: {
    color: '#1C1C1E',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 'bold',
    marginBottom: '12px'
  },
  headlineSmall: {
    fontSize: '12px',
    color: '#B8B8BD',
    lineHeight: '16px'
  },
  styledCardContainerUpper: {
    marginBottom: '12px'
  },
  styledCardContainer: {
    marginBottom: '36px'
  },
  styledCardBody: {
    borderRadius: '6px',
    width: '320px',
    height: '168px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: '1px solid #B8B8BD',
    cursor: 'pointer'
  },
  styledCardBodySelected: {
    borderRadius: '6px',
    width: '320px',
    height: '168px',
    backgroundColor: '#EBEFFF !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: '1px solid #0033FF',
    cursor: 'pointer'
  }
}))

export default useStyles
