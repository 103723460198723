import { useNavigate, useParams } from 'react-router-dom'
import { handleNavigate, useMediaQueries } from '../../../utils/global-utils'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useEffect, useState } from 'react'
import { SelectedBlogResponse } from '../../../utils/types.d'
import BlogApi from '../../../services/blogApi'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider'
import MobileHeader from '../../mobileHeader/mobileHeader'
import useStyles from './singleBlogAdmin.module'
import StyledButton from '../../styledComponents/styledButton.module'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@mui/material/Button'
import { useAuthContext } from '../../../context/UserContext'

function SingleBlogAdmin() {
  const { accessToken } = useAuthContext()
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const { blogId } = useParams()
  const navigate = useNavigate()
  const [fetchSelectedBlog, setfFetchSelectedBlog] = useState<SelectedBlogResponse[]>([])
  const [loading, setLoading] = useState(true)
  const [deleteBlog, setDeleteBlog] = useState(false)
  const [, setIsDeleting] = useState(false)

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const selectedBlogResponse: any = await BlogApi.GetSelectedBlog(blogId as string)
        setfFetchSelectedBlog([selectedBlogResponse.data])
        setLoading(false)
      } catch {
        setfFetchSelectedBlog([])
        setLoading(false)
      }
    }

    fetchDetails()
  }, [blogId])

  const handleDeleteBlog = async () => {
    setIsDeleting(true)
    try {
      await BlogApi.DeleteBlog(accessToken, blogId as string)
      handleNavigate(navigate, '/admin-panel/')
    } catch {
    } finally {
      setIsDeleting(false)
      setDeleteBlog(false)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <MobileHeader />
          </Grid>
        </Grid>
        <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
          <div className={formClasses.companyNameHeadline}>
            <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Pregledaj blog
          </div>
        </div>
        <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
          <div className={`${formClasses.bodyItemsHolder}`}>
            {loading ? (
              <Backdrop open={true}>
                <div className={formClasses.circularProgressContainer}>
                  <StyledSpinningIcon />
                </div>
              </Backdrop>
            ) : (
              <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                <div className={`${formClasses.bodyItemsHolder} ${isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}`}>
                  {fetchSelectedBlog?.map((blogDetails) => (
                    <>
                      <Grid container key={blogDetails.blogId}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={`${isMobile ? classes.adHeadlineContainerMobile : classes.adHeadlineContainer}`}>
                            <span>{blogDetails?.blogTitle}</span>
                          </div>
                          <div className={formClasses.inputContainerTop}>
                            <Divider />
                          </div>
                          <div className={classes.adHeadlineDetails}>
                            <span>{blogDetails?.blogText}</span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container className={formClasses.inputContainerTop}>
                        <Grid item xs={6} sm={6} md={2} display='flex' justifyContent='flex-end'>
                          <StyledButton
                            className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                            style={{ textTransform: 'none' }}
                            onClick={() => setDeleteBlog(true)}
                            variant='outlined'
                          >
                            Obriši blog
                          </StyledButton>
                          <Dialog open={deleteBlog} onClose={() => setDeleteBlog(false)}>
                            <DialogContent className={isMobile ? classes.mobileDialogBody : ' '}>
                              <Typography>Da li ste sigurni da želite da izbrišete blog?</Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={() => setDeleteBlog(false)} autoFocus>
                                Ne
                              </Button>
                              <Button onClick={handleDeleteBlog} autoFocus>
                                Da
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </div>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SingleBlogAdmin
