import { useEffect, useState } from 'react'
import { useAuthContext } from '../../../context/UserContext'
import { ReceivedApplicationsResponse } from '../../../utils/types.d'
import AddApi from '../../../services/addApi'
import DetailedCard from '../../wwwApp/detailedCard/detailedCard'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useMediaQueries } from '../../../utils/global-utils'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded'

function ReceivedApplications() {
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const { accessToken } = useAuthContext()
  const [fetchReceivedApplications, setFetchReceivedApplications] = useState<ReceivedApplicationsResponse[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (accessToken) {
          const receivedApplicationsResponse = await AddApi.GetReceivedApplications(accessToken)
          if (receivedApplicationsResponse.status === 200) {
            const fetchedActiveAds = receivedApplicationsResponse.data.filter((ad: { adStatus: string }) => ad.adStatus === 'Aktivan')
            setFetchReceivedApplications(fetchedActiveAds)
          }
          setLoading(false)
        }
      } catch {
        setLoading(false)
      }
    }

    if (accessToken) {
      setLoading(true)
      fetchDetails()
    }
  }, [accessToken])
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={10}>
          <Grid
            container
            className={`${isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}
          >
            <div className={formClasses.bodyItemsHolder}>
              {loading ? (
                <Backdrop open={true}>
                  <div className={formClasses.circularProgressContainer}>
                    <StyledSpinningIcon />
                  </div>
                </Backdrop>
              ) : (
                <>
                  {fetchReceivedApplications ? (
                    <>
                      {fetchReceivedApplications?.map((details: any, index: number) => (
                        <div key={index}>
                          <div className={`${formClasses.pb12} ${formClasses.pt12}`}>
                            <span className={`${formClasses.canditateName} ${formClasses.fwbi}`}>{details.adTitle}</span>
                            <span className={`${formClasses.activeChip} ${formClasses.ml12}`}>{details.adStatus}</span>
                          </div>
                          {details.candidates.length === 0 ? (
                            <>
                              <div className={formClasses.inputContainerTop}>
                                <div className={`${formClasses.canditateName} ${formClasses.ml28} ${formClasses.dflex}`}>
                                  Nema prijava na oglasu
                                  <SentimentDissatisfiedRoundedIcon className={`${formClasses.fs18i} ${formClasses.pl6}`} />
                                </div>
                              </div>
                              <Divider />
                            </>
                          ) : (
                            <DetailedCard addDetails={details} adId={details.adId} />
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className={formClasses.inputContainerTop}>
                        <div className={`${formClasses.canditateName} ${formClasses.ml28}`}>Nemate pristiglih prijava</div>
                      </div>
                      <div className={formClasses.inputContainerTop}>
                        <Divider />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ReceivedApplications
