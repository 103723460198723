import Grid from '@mui/material/Grid/Grid'
import useStyles from './landingBody.module'
import BODY_ITEMS from '../../../assets/bodyItems/index'
import logo from '../../../assets/logo_landing.png'
import team from '../../../assets/media/tim.png'
import clock from '../../../assets/media/BRZ.png'
import deal from '../../../assets/media/DIL.png'
import COUNTRY_FLAG_ICONS from '../../../assets/countryFlags/'
import { useEffect, useRef, useState } from 'react'
import { navigateWithScroll, searchParamsCroatia, searchParamsMontenegro, useMediaQueries } from '../../../utils/global-utils'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import Card from '@mui/material/Card/Card'
import { useNavigate } from 'react-router-dom'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Paper from '@mui/material/Paper'
import { Button } from '@material-ui/core'
import Cookies from 'js-cookie'
import LandingHeader from '../../landingHeader/landingHeader'
import StyledButton from '../../styledComponents/styledButton.module'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import PersonIcon from '@mui/icons-material/Person'
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined'
import { CardContent } from '@mui/material'
import LandingFooter from './landingFooter'
import AdsCardLanding from '../../wwwApp/adsCardLanding/adsCardLanding'
import ScrollToTopButton from '../landingPageNavigation/scrollToTopButton'
import DetailedEmployerCard from '../../wwwApp/detailedEmployerCard/detailedEmployerCard'
import BlogPostSection from '../blogPostSection/blogPostSection'
import FAQSection from '../FAQSection/faqSection'
import ContactUsSection from '../contactUsSection/contactUsSection'

function LandingBody() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile, isTablet } = useMediaQueries()
  const navigate = useNavigate()
  const [displayCookiesNotificationModal, setDisplayCookiesNotificationModal] = useState(false)
  const contactUsRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3
  })

  const scrollToContactSection = () => {
    contactUsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const hasAcceptedCookies = Cookies.get('acceptedCookies')
    if (!hasAcceptedCookies) {
      setDisplayCookiesNotificationModal(true)
    }
  }, [])

  const handleCloseCookiesModal = () => {
    setDisplayCookiesNotificationModal(false)
    Cookies.set('acceptedCookies', 'true', { expires: 365 })
  }

  return (
    <>
      <ScrollToTopButton />
      <div>
        {displayCookiesNotificationModal && (
          <Dialog className={classes.cookiesConsentDialog} open={displayCookiesNotificationModal} aria-describedby='cookies-consent-dialog'>
            <DialogContent>
              <DialogContentText id='cookies-consent-dialog'>
                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                  <span>Ova veb stranica koristi funkcionalne kolačiće kako bi Vam pružila bolje korisničko iskustvo.</span>
                </div>
                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                  <span>
                    Molimo Vas da pročitate našu <strong>politiku privatnosti</strong> za detaljne informacije o upotrebi kolačića na našoj
                    veb stranici.
                  </span>
                </div>
                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                  <span>Kolačići će biti aktuelni tokom određenog vremena, nakon čega će biti ponovo zatražen Vaš pristanak.</span>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseCookiesModal()}>Slažem se</Button>
            </DialogActions>
          </Dialog>
        )}
        <div className={`${classes.carouselContainer}`}>
          <img
            src={`${BODY_ITEMS.BEACH_LANDING}`}
            width={`${'100%'}`}
            className={`${isMobile ? classes.carouselImagesMobile : classes.carouselImages}`}
          />
          <div className={`${isMobile ? classes.overlayMobile : classes.overlay} ${isTablet ? classes.overlayTablet : ' '}`}>
            <Grid container className={isMobile ? classes.gridContainerMobile : classes.gridContainer}>
              <Grid item xs={12} sm={12} md={12}>
                <LandingHeader />
              </Grid>
              <Grid container className={`${isMobile ? classes.mtb36 : ''}`}>
                <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
                  <img width={`${isMobile ? '100%' : '50%'}`} alt='logo-sezonal' src={`${logo}`} className={classes.logoLandingLarge} />
                </Grid>
              </Grid>
              <Grid container className={`${isMobile ? '' : classes.mtb36}`}>
                <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
                  <div>
                    <div className={`${classes.mtb12} ${classes.tac}`}>
                      <span className={`${isMobile ? classes.landingHeadingMobile : classes.landingHeading}`}>
                        NAJVEĆA BERZA SEZONSKIH POSLOVA!
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container className={`${classes.mtb12}`}>
                <Grid item xs={12} sm={12} md={6} display='flex' justifyContent={`${isMobile ? 'center' : 'flex-end'}`}>
                  <div className={`${classes.m24}`}>
                    <StyledButton
                      style={{ textTransform: 'none', height: '60px !important', fontSize: '18px !important' }}
                      onClick={() => navigateWithScroll(navigate, `/employee-manual`)}
                      variant='text'
                    >
                      TRAŽIM SEZONSKI POSAO
                    </StyledButton>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} display='flex' justifyContent={`${isMobile ? 'center' : 'flex-start'}`}>
                  <div className={`${classes.m24}`}>
                    <StyledButton style={{ textTransform: 'none' }} onClick={scrollToContactSection} variant='text'>
                      TRAŽIM RADNU SNAGU
                    </StyledButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Grid container className={`${isMobile ? classes.plr12 : classes.plr36} ${classes.ptb120} ${classes.bodyBackground}`}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
            <div className={`${classes.primaryTextContainer}`}>
              <div className={`${classes.mtb36}`}>
                <span className={`${classes.benefitsHeadline}`} style={{ color: '#636366' }}>
                  Zašto izabrati Sezonal?
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={`${classes.iconTextContainer}`}>
              <img width='84' alt='team-sezonal' src={`${team}`} />
              <span className={classes.benefitsList}>Sezonal – platforma koju su osnovali sezonski radnici, za sezonske radnike!</span>
            </div>
            <div className={`${classes.iconTextContainer}`}>
              <img width='84' alt='team-sezonal' src={`${clock}`} />
              <span className={classes.benefitsList}>Kod nas možete brzo i lako pronaći poslove ili radnike potpuno besplatno.</span>
            </div>
            <div className={`${classes.iconTextContainer} ${classes.colorPurple}`}>
              <img width='84' alt='team-sezonal' src={`${deal}`} />
              <span className={classes.benefitsList}>Možete takodje prepustiti ceo proces našem timu, a mi ćemo sve završiti za vas.</span>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className={`${classes.middleBodyContainer}`} ref={ref} style={{ backgroundImage: `url(${BODY_ITEMS.BEACH_LANDING_MIDDLE})` }}>
        <Grid container className={`${isMobile ? '' : classes.plr36} ${classes.ptb60}`}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
              <div className={`${classes.primaryTextContainer}`}>
                <div className={`${classes.mtb36}`}>
                  <span className={`${classes.benefitsHeadline} ${classes.tac}`} style={{ color: '#FFFFFF' }}>
                    Veliki broj korisnika!
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={6} md={3} className={`${classes.plr12} ${isMobile ? classes.mtb12 : ''}`}>
              <Paper>
                <div className={`${classes.iconTextContainer} ${isMobile ? '' : classes.ptb36}`}>
                  <BarChartOutlinedIcon className={`${classes.wh42} ${classes.colorRed}`} />
                  <span className={`${isMobile ? classes.benefitsListMiddleSmall : classes.benefitsListMiddle}`}>
                    {inView ? <CountUp end={20000} duration={4} suffix='+' /> : '20000+'} Poseta
                  </span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6} md={3} className={`${classes.plr12} ${isMobile ? classes.mtb12 : ''}`}>
              <Paper>
                <div className={`${classes.iconTextContainer} ${isMobile ? '' : classes.ptb36}`}>
                  <PersonIcon className={`${classes.wh42} ${classes.colorRed}`} />
                  <span className={`${isMobile ? classes.benefitsListMiddleSmall : classes.benefitsListMiddle}`}>
                    {inView ? <CountUp end={5000} duration={2} suffix='+' /> : '5000+'} Kandidata
                  </span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6} md={3} className={`${classes.plr12} ${isMobile ? classes.mtb12 : ''}`}>
              <Paper>
                <div className={`${classes.iconTextContainer} ${isMobile ? '' : classes.ptb36}`}>
                  <PostAddOutlinedIcon className={`${classes.wh42} ${classes.colorRed}`} />
                  <span className={`${isMobile ? classes.benefitsListMiddleSmall : classes.benefitsListMiddle}`}>
                    {inView ? <CountUp end={1000} duration={2} suffix='+' /> : '1000+'} Oglasa
                  </span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={6} md={3} className={`${classes.plr12} ${isMobile ? classes.mtb12 : ''}`}>
              <Paper>
                <div className={`${classes.iconTextContainer} ${isMobile ? '' : classes.ptb36}`}>
                  <WorkOutlinedIcon className={`${classes.wh42} ${classes.colorRed}`} />
                  <span className={`${isMobile ? classes.benefitsListMiddleSmall : classes.benefitsListMiddle}`}>
                    {inView ? <CountUp end={100} duration={2} suffix='+' /> : '100+'} Poslodavaca
                  </span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        className={`${isMobile ? '' : classes.plr36} ${isMobile ? classes.ptb60 : classes.ptb120} ${isMobile ? '' : classes.h808} ${classes.bodyBackground}`}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
            <div className={`${classes.primaryTextContainer}`}>
              <div className={`${classes.mtb36}`}>
                <span className={`${classes.benefitsHeadline} ${classes.tac}`} style={{ color: '#636366' }}>
                  Pronađi posao na moru
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container display='flex' justifyContent='center'>
          <Grid item xs={12} sm={12} md={3} className={`${classes.plr12} ${isMobile ? classes.ptb12 : ''}`}>
            <Card onClick={() => navigateWithScroll(navigate, `/ads/${searchParamsMontenegro}`)}>
              <CardContent>
                <div className={`${classes.landingCardsBody} ${classes.tac}`}>
                  <div className={classes.landingCadrsHolder}>
                    <span>Crna Gora</span>
                  </div>
                  <div className={classes.mtb12}>
                    <img width='84' height='84' src={`${COUNTRY_FLAG_ICONS.ME}`} />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={3} className={`${classes.plr12} ${isMobile ? classes.ptb12 : ''}`}>
            <Card onClick={() => navigateWithScroll(navigate, `/ads/${searchParamsCroatia}`)}>
              <CardContent>
                <div className={`${classes.landingCardsBody} ${classes.tac}`}>
                  <div className={classes.landingCadrsHolder}>
                    <span>Hrvatska</span>
                  </div>
                  <div className={classes.mtb12}>
                    <img width='84' height='84' src={`${COUNTRY_FLAG_ICONS.CR}`} />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <div className={`${classes.middleBodyContainer}`} style={{ backgroundImage: `url(${BODY_ITEMS.PORT_LANDING})` }}>
        <Grid container className={`${isMobile ? '' : classes.plr36} ${classes.ptb60}`}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
              <div className={`${classes.primaryTextContainer} ${classes.tac}`}>
                <div className={`${classes.mtb36}`}>
                  <span className={`${classes.benefitsHeadline} ${classes.tac}`} style={{ color: '#FFFFFF' }}>
                    Istaknuti oglasi
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={`${classes.carouselCardsContainer} ${classes.plr12}  ${isMobile ? classes.mtb12 : ''}`}
            >
              <AdsCardLanding />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        className={`${isMobile ? '' : classes.plr36} ${isMobile ? classes.ptb60 : classes.ptb120} ${isMobile ? '' : classes.h808} ${classes.bodyBackground}`}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
            <div className={`${classes.primaryTextContainer}`}>
              <div className={`${classes.mtb36}`}>
                <span className={`${classes.benefitsHeadline} ${classes.tac}`} style={{ color: '#636366' }}>
                  Istaknuti poslodavci
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} className={`${classes.plr12}  ${isMobile ? classes.mtb12 : ''}`}>
            <DetailedEmployerCard />
          </Grid>
        </Grid>
      </Grid>
      <FAQSection />
      <Grid
        container
        className={`${isMobile ? '' : classes.plr36} ${isMobile ? classes.ptb60 : classes.ptb120} ${isMobile ? '' : classes.h808} ${classes.bodyBackground}`}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
            <div className={`${classes.primaryTextContainer}`}>
              <div className={`${classes.mtb36}`}>
                <span className={`${classes.benefitsHeadline} ${classes.tac}`} style={{ color: '#636366' }}>
                  Novosti
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={`${classes.carouselCardsContainer} ${classes.plr12}  ${isMobile ? classes.mtb12 : ''}`}
          >
            <BlogPostSection />
          </Grid>
        </Grid>
      </Grid>
      <Grid container ref={contactUsRef}>
        <ContactUsSection />
      </Grid>
      <LandingFooter />
    </>
  )
}

export default LandingBody
