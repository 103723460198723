import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid/Grid'
import StyledButton from '../../styledComponents/styledButton.module'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import useStyles from './pricingDialog.module'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import UserContext from '../../../context/UserContext'
import { AdTypesResponse } from '../../../utils/types.d'
import AddApi from '../../../services/addApi'
import {
  defaultSelectedAdType,
  premiumAdNameConst,
  sponsoredAdNameConst,
  standardAdNameConst,
  useMediaQueries
} from '../../../utils/global-utils'
export interface PricingDialogProps {
  open: boolean
  onClose: () => void
  // eslint-disable-next-line no-unused-vars
  onConfirm: (type: string | undefined) => void
}

function PricingDialog({ open, onClose, onConfirm }: PricingDialogProps) {
  const { isMobile } = useMediaQueries()
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { control } = useForm()
  const { accessToken } = useContext(UserContext)
  const [selectedCard, setSelectedCard] = useState<string | undefined>('Sponzorisani oglas')
  const [adTypeSelected, setAdTypeSelected] = useState<string | undefined>(defaultSelectedAdType)
  const [openDialog] = useState(false)
  const [fetchAdTypes, setFetchAdTypes] = useState<AdTypesResponse>()

  useEffect(() => {
    const fetchAdTypes = async () => {
      try {
        if (accessToken) {
          const getAdTypes = await AddApi.GetAdTypes(accessToken)
          setFetchAdTypes(getAdTypes.data)
        }
      } catch (error) {
        console.error('Error fetching details:', error)
      }
    }

    if (accessToken) {
      fetchAdTypes()
    }
  }, [accessToken])

  const handleSelectedCard = (cardName: string) => {
    if (fetchAdTypes) {
      const adType = fetchAdTypes.adTypes.find((adType: { adTypeName: string }) => adType.adTypeName === cardName)?.id

      setAdTypeSelected(adType)
      setSelectedCard(cardName)
    }
  }

  const handleModalConfirm = () => {
    if (adTypeSelected) {
      onConfirm(adTypeSelected)
      onClose()
    }
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction='down' ref={ref} {...props} />
  })

  const standardAds = fetchAdTypes?.adTypes.filter((ad) => ad.adTypeName === standardAdNameConst)
  const sponsoredAds = fetchAdTypes?.adTypes.filter((ad) => ad.adTypeName === sponsoredAdNameConst)
  const premiumAds = fetchAdTypes?.adTypes.filter((ad) => ad.adTypeName === premiumAdNameConst)

  return (
    <Dialog
      maxWidth={'md'}
      open={open}
      onClose={onClose}
      TransitionComponent={openDialog ? Transition : undefined}
      keepMounted
      aria-describedby='pricing-modal'
    >
      <DialogTitle className={isMobile ? classes.dialogTitleMobile : classes.dialogTitle}>Odaberite tip oglasa</DialogTitle>
      <DialogContent className={classes.pricingModalContainer}>
        <DialogContentText id='pricing-modal'>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4}>
              {standardAds?.map((vals) => (
                <Controller
                  name={vals.adTypeName}
                  control={control}
                  render={({ field }) => {
                    const currentCard = selectedCard === field.name ? true : false
                    return (
                      <Card
                        {...field}
                        className={`${classes.regularCard} ${classes.cardHeight} ${currentCard ? classes.standardCard : ' '}`}
                        onClick={() => handleSelectedCard(field.name)}
                      >
                        <CardContent className={classes.cardContent}>
                          {fetchAdTypes?.hasStandard ? (
                            <div className={classes.pricingContainer}>
                              <span className={classes.price}>Imate još</span>
                              <span className={classes.duration}>{fetchAdTypes?.numOfStandard} oglasa iz paketa</span>
                            </div>
                          ) : (
                            <div className={classes.pricingContainer}>
                              <span className={classes.price}>
                                {vals.price} {fetchAdTypes?.currency}
                              </span>
                              /<span className={classes.duration}>{vals.duration} dana</span>
                            </div>
                          )}
                          <div className={classes.headlineHolder}>
                            <span className={`${classes.regularAddHeadline} ${currentCard ? classes.standardAddHeadlineSelected : ' '}`}>
                              standardni oglas
                            </span>
                          </div>
                          <div className={classes.explanationContainer}>
                            <span
                              className={`${classes.explanationHeadlineSmallRegular} ${currentCard ? classes.explanationHeadlineSmallPremium : ' '}`}
                            >
                              Vidljivost među relevantnim oglasima:
                            </span>
                            <span className={classes.explanationTextSmall}>
                              Kandidati koji pretražuju poslove, mogu pronaći Vaš oglas prilikom pregleda oglasa, u odgovarajućoj kategoriji
                              ili pretrazi.
                            </span>
                          </div>
                          <div className={classes.explanationContainer}>
                            <span
                              className={`${classes.explanationHeadlineSmallRegular} ${currentCard ? classes.explanationHeadlineSmallPremium : ' '}`}
                            >
                              Široka dostupnost:
                            </span>
                            <span className={classes.explanationTextSmall}>
                              Regularni oglasi mogu dosegnuti širok spektar kandidata koji pregledaju oglase na Sezonal platformi.
                            </span>
                          </div>
                        </CardContent>
                      </Card>
                    )
                  }}
                />
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {sponsoredAds?.map((vals) => (
                <Controller
                  name={vals.adTypeName}
                  control={control}
                  render={({ field }) => {
                    const currentCard = selectedCard === field.name ? true : false
                    return (
                      <Card
                        {...field}
                        className={`${classes.regularCard} ${currentCard ? classes.sponsoredCard : ' '} ${classes.cardHeight}`}
                        onClick={() => handleSelectedCard(field.name)}
                      >
                        <CardContent className={classes.cardContent}>
                          {fetchAdTypes?.hasSponsored ? (
                            <div className={classes.pricingContainer}>
                              <span className={classes.price}>Imate još</span>
                              <span className={classes.duration}>{fetchAdTypes?.numOfSponsored} oglasa iz paketa</span>
                            </div>
                          ) : (
                            <div className={classes.pricingContainer}>
                              <span className={classes.price}>
                                {vals.price} {fetchAdTypes?.currency}
                              </span>
                              /<span className={classes.duration}>{vals.duration} dana</span>
                            </div>
                          )}
                          <div className={classes.headlineHolder}>
                            <span className={`${classes.regularAddHeadline} ${currentCard ? classes.sponsoreddAddHeadline : ' '}`}>
                              sponzorisani oglas
                            </span>
                          </div>
                          <div className={classes.explanationContainer}>
                            <span
                              className={`${classes.explanationHeadlineSmallRegular} ${currentCard ? classes.explanationHeadlineSmallSponsored : ' '}`}
                            >
                              Povećana vidljivost:
                            </span>
                            <span className={classes.explanationTextSmall}>
                              Osigurajte maksimalnu vidljivost za svoj oglas putem naše platforme. Uz to, vaš oglas će biti promovisan i na
                              društvenim mrežama putem priče (story) sa vizualnim sadržajem i linkom koji vodi direktno do oglasa.
                            </span>
                          </div>
                          <div className={classes.explanationContainer}>
                            <span
                              className={`${classes.explanationHeadlineSmallRegular} ${currentCard ? classes.explanationHeadlineSmallSponsored : ' '}`}
                            >
                              Napredni format oglasa:
                            </span>
                            <span className={classes.explanationTextSmall}>
                              Vaš sponzorisan oglas će biti istaknut posebnom bojom, koja će ga vizuelno razlikovati od standardnih oglasa.
                            </span>
                          </div>
                        </CardContent>
                      </Card>
                    )
                  }}
                />
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {premiumAds?.map((vals) => (
                <Controller
                  name={vals.adTypeName}
                  control={control}
                  render={({ field }) => {
                    const currentCard = selectedCard === field.name ? true : false
                    return (
                      <Card
                        {...field}
                        className={`${classes.regularCard} ${currentCard ? classes.premiumCard : ' '} ${classes.cardHeight}`}
                        onClick={() => handleSelectedCard(field.name)}
                      >
                        <CardContent className={classes.cardContent}>
                          {fetchAdTypes?.hasPremium ? (
                            <div className={classes.pricingContainer}>
                              <span className={classes.price}>Imate još</span>
                              <span className={classes.duration}>{fetchAdTypes?.numOfPremium} oglasa iz paketa</span>
                            </div>
                          ) : (
                            <div className={classes.pricingContainer}>
                              <span className={classes.price}>
                                {vals.price} {fetchAdTypes?.currency}
                              </span>
                              /<span className={classes.duration}>{vals.duration} dana</span>
                            </div>
                          )}
                          <div className={classes.headlineHolder}>
                            <span className={`${classes.regularAddHeadline} ${currentCard ? classes.premiumAddHeadline : ' '}`}>
                              premium oglas
                            </span>
                          </div>
                          <div className={classes.explanationContainer}>
                            <span
                              className={`${classes.explanationHeadlineSmallRegular} ${currentCard ? classes.explanationHeadlineSmallPremium : ' '}`}
                            >
                              Objava na društvenim mrežama:
                            </span>
                            <span className={classes.explanationTextSmall}>
                              Dobijte premium vidljivost za svoj oglas na našoj platformi i društvenim mrežama Sezonal platforme. Vaš oglas
                              će biti linkovan i objavljen sa vizualno atraktivnom pričom (story) i direktnim linkom ka oglasu. Dodatno,
                              uključujemo Custom Reels oglas i top plasman u pretrazi.
                            </span>
                          </div>
                          <div className={classes.explanationContainer}>
                            <span
                              className={`${classes.explanationHeadlineSmallRegular} ${currentCard ? classes.explanationHeadlineSmallPremium : ' '}`}
                            >
                              Ekskluzivnost na početnoj stranici
                            </span>
                          </div>
                        </CardContent>
                      </Card>
                    )
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
            <div className={`${formClasses.pr6} ${formClasses.pl6}`}>
              <StyledButton style={{ textTransform: 'none' }} className={`${formClasses.w100pi}`} variant='outlined' onClick={onClose}>
                Odustani
              </StyledButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={isMobile ? `${formClasses.mt12} ${formClasses.mb12}` : `${formClasses.pr6} ${formClasses.pl6}`}>
              <StyledButton
                style={{ textTransform: 'none' }}
                className={`${formClasses.w100pi} ${formClasses.pr6} ${formClasses.pl6}`}
                variant='contained'
                onClick={handleModalConfirm}
              >
                Postavi oglas
              </StyledButton>
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default PricingDialog
