import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  actionCards: {
    backgroundColor: '#EBEFFF !important',
    borderRadius: '12px !important',
    marginLeft: '12px',
    marginRight: '12px',
    cursor: 'pointer !important'
  },
  actionCardsSelected: {
    backgroundColor: '#0033FF !important',
    borderRadius: '12px !important',
    marginLeft: '12px',
    marginRight: '12px',
    cursor: 'pointer'
  },
  actionCardsUpper: {
    fontWeight: 'bold',
    fontSize: '30px',
    color: '#0033FF'
  },
  bodyLinks: {
    fontSize: '14px',
    color: '#0033FF',
    lineSpacing: '12px',
    display: 'flex',
    alignItems: 'center'
  },
  arrowIcon: {
    fontSize: '15px !important'
  },
  myAddContainers: {
    marginTop: '18px',
    marginBottom: '6px'
  },
  myAddDetails: {
    display: 'flex'
  },
  explanationIcons: {
    color: '#001A80',
    fontSize: '16px !important',
    paddingLeft: '12px',
    paddingRight: '6px',
    '&:first-child': {
      paddingLeft: '0px'
    }
  },
  detailsText: {
    color: '#636366',
    fontSize: '14px'
  },
  verticalTabsContainer: {
    borderRight: '1px solid #636366',
    alignItems: 'flex-end',
    fontSize: '16px',
    '& .MuiTabs-scroller': {
      paddingRight: '24px'
    },
    '& .MuiTabs-flexContainer': {
      alignItems: 'baseline'
    }
  },
  horizontalTabsContainer: {
    fontSize: '12px',
    '& .MuiTabs-scroller': {
      paddingRight: '12px',
      paddingLeft: '12px'
    },
    '& .Mui-selected': {
      fontSize: '12px',
      paddingRight: '12px',
      paddingLeft: '12px'
    },
    '& .MuiButtonBase-root': {
      fontSize: '12px',
      paddingRight: '12px',
      paddingLeft: '12px'
    }
  }
}))

export default useStyles
