import { useState } from 'react'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import useFormStyles from '../globalFormStying.module'
import useStyles from './profileImage.module'

interface ImageProps {
  imageData: string | undefined
  width: number
  height: number
  alt: string
  style?: any
}
function StyledProfilePicture({ imageData, width, height, alt, style }: ImageProps) {
  const formClasses = useFormStyles()
  const classes = useStyles()
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)

  const handleImageClick = () => {
    setIsBackdropOpen(true)
  }

  const handleCloseBackdrop = () => {
    setIsBackdropOpen(false)
  }

  let fileType
  const firstChar = imageData?.charAt(0)
  if (firstChar === '/') {
    fileType = 'jpg'
  } else if (firstChar === 'i') {
    fileType = 'png'
  }

  return (
    <>
      <img
        className={classes.profileImage}
        src={`data:image/${fileType};base64,${imageData}`}
        alt={alt}
        width={width}
        height={height}
        onClick={handleImageClick}
        style={style}
      />

      {isBackdropOpen && (
        <Backdrop open={true} onClick={handleCloseBackdrop} className={formClasses.backdropContainer}>
          <div>
            <img
              src={`data:image/${fileType};base64,${imageData}`}
              alt={alt}
              style={{
                borderRadius: '300px',
                width: '300px',
                height: '300px',
                objectFit: 'cover'
              }}
            />
          </div>
        </Backdrop>
      )}
    </>
  )
}

export default StyledProfilePicture
