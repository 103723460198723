import Grid from '@mui/material/Grid/Grid'
import Paper from '@mui/material/Paper'
import useStyles from '../landingPageBody/landingBody.module'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { Controller, useForm } from 'react-hook-form'
import { StyledInput } from '../../styledComponents/styledInput/styledInput'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import Alert from '@mui/material/Alert'
import StyledButton from '../../styledComponents/styledButton.module'
import { useMediaQueries } from '../../../utils/global-utils'
import { CircularProgress } from '@material-ui/core'
import { useState } from 'react'
import RegisterUserApi from '../../../services/registerApi'
import BODY_ITEMS from '../../../assets/bodyItems'

function ContactUsSection() {
  const classes = useStyles()
  const { isMobile } = useMediaQueries()
  const formClasses = useFormStyles()
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const { control, handleSubmit, formState, trigger, reset } = useForm({
    mode: 'onChange'
  })

  const onSubmit = async (formData: any) => {
    try {
      const response: any = await RegisterUserApi.PreRegisterFormEmployer(formData)
      reset({
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      })
      const { status } = response
      if (status === 200) {
        setSuccessModal(true)
      }
    } catch {
      setErrorModal(true)
    }
  }

  const handleButtonClick = async () => {
    try {
      await trigger()
      if (Object.keys(formState.errors).length === 0) {
        setLoading(true)
        await handleSubmit(onSubmit)()
        setLoading(false)
      }
    } catch {
      //
    }
  }

  return (
    <>
      <div className={`${classes.middleBodyContainerContact}`} style={{ backgroundImage: `url(${BODY_ITEMS.BEACH_LANDING_MIDDLE})` }}>
        <Grid className={`${isMobile ? '' : classes.plr36} ${classes.ptb60}`} justifyContent='center'>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
              <div className={`${classes.primaryTextContainer}`}>
                <div className={`${classes.mtb36}`}>
                  <span className={`${classes.benefitsHeadline}`} style={{ color: '#FFFFFF' }}>
                    Kontakt
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
              <div>
                <div className={`${classes.mtb12} ${classes.tac}`}>
                  <span className={`${classes.landingHeadingMobile}`}>Kontaktirajte nas putem formulara za svaki vid saradnje</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
              <Paper className={`${classes.p32}`}>
                <form onSubmit={handleButtonClick}>
                  <div className={formClasses.inputContainer}>
                    <Controller
                      name='companyName'
                      control={control}
                      rules={{
                        required: 'Polje je obavezno'
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <StyledInput
                            required={true}
                            type='text'
                            label='Naziv kompanije / Hotela / Gazdinstva'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            inputRef={field.ref}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={formClasses.inputContainer}>
                    <Controller
                      name='firstName'
                      control={control}
                      rules={{
                        required: 'Polje je obavezno',
                        pattern: {
                          value: /^[A-Za-z]+$/i,
                          message: 'Ime ne može sadržati ništa sem slova'
                        }
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <StyledInput
                            required={true}
                            type='text'
                            label='Ime'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            inputRef={field.ref}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={formClasses.inputContainer}>
                    <Controller
                      name='lastName'
                      control={control}
                      rules={{
                        required: 'Polje je obavezno',
                        pattern: {
                          value: /^[A-Za-z]+$/i,
                          message: 'Prezime ne može sadržati ništa sem slova'
                        }
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <StyledInput
                            required={true}
                            type='text'
                            label='Prezime'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            inputRef={field.ref}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={formClasses.inputContainer}>
                    <Controller
                      name='email'
                      control={control}
                      rules={{
                        required: 'Polje je obavezno',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Unesi validnu e-mail adresu'
                        }
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <StyledInput
                            required={true}
                            type='email'
                            label='Unesi e-mail adresu'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            inputRef={field.ref}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={formClasses.inputContainer}>
                    <Controller
                      name='phoneNumber'
                      control={control}
                      rules={{
                        required: 'Polje je obavezno',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Unesite broj'
                        },
                        minLength: {
                          value: 9,
                          message: 'Broj telefona mora imati najmanje 9 cifara'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Broj telefona ne sme imati više od 10 cifara'
                        }
                      }}
                      render={({ field, fieldState }) => {
                        const isMobileCheck = isMobile ? { width: '100%' } : {}
                        return (
                          <StyledInput
                            required={true}
                            style={isMobileCheck}
                            type='tel'
                            label='Broj telefona'
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            inputRef={field.ref}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={formClasses.inputContainer}>
                    <Controller
                      name='message'
                      control={control}
                      rules={{
                        required: 'Polje je obavezno',
                        maxLength: 200
                      }}
                      render={({ field, fieldState }) => {
                        const CHARACTER_LIMIT = 200
                        return (
                          <StyledInput
                            variant='outlined'
                            multiline={true}
                            rows={4}
                            required={true}
                            type='text'
                            label='Predložite vid saradnje'
                            error={!!fieldState.error}
                            InputProps={{
                              maxLength: CHARACTER_LIMIT
                            }}
                            inputRef={field.ref}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                  {successModal && (
                    <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                      <Alert
                        className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                        onClose={() => setSuccessModal(false)}
                        severity='success'
                      >
                        Poštovani, primili smo Vaš upit. Obratićemo Vam se putem elektronske pošte u najkraćem mogućem roku.
                      </Alert>
                    </Backdrop>
                  )}
                  {errorModal && (
                    <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                      <Alert
                        className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                        onClose={() => setErrorModal(false)}
                        severity='error'
                      >
                        Došlo je do greške
                      </Alert>
                    </Backdrop>
                  )}
                  <div className={formClasses.inputContainer}>
                    <StyledButton
                      style={{ textTransform: 'none' }}
                      className={formClasses.w100pi}
                      variant='contained'
                      onClick={handleButtonClick}
                    >
                      {loading ? <CircularProgress size={24} className='circular-progress' /> : 'Pošalji'}
                    </StyledButton>
                  </div>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ContactUsSection
