import useStyles from './blogPostImage.module'
interface BlogPostProps {
  blogTitle: string
  imageData: string
  altTag: string
}
function BlogPostImage({ blogTitle, imageData, altTag }: BlogPostProps) {
  const classes = useStyles()
  let fileType
  const firstChar = imageData?.charAt(0)
  if (firstChar === '/') {
    fileType = 'jpg'
  } else if (firstChar === 'i') {
    fileType = 'png'
  }

  return (
    <div className={classes.blogCardBody}>
      <div className={classes.BlogCardContent}>
        <div className={classes.textContainer}>
          <span className={classes.blogTitle}>{blogTitle}</span>
        </div>
        <div className={classes.imageContainer}>
          {imageData && <img alt={altTag} className={classes.blogPostImage} src={`data:image/${fileType};base64,${imageData}`} />}
        </div>
        <span className={classes.moreInfo}>Više detalja</span>
      </div>
    </div>
  )
}

export default BlogPostImage
