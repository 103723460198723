import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  '& .MuiInputLabel-root': {
    zIndex: '0 !important',
    fontWeight: 'bold !important',
    display: 'block !important'
  }
}))

export default useStyles
