import { useState } from 'react'
import useFormStyles from '../globalFormStying.module'
import { Button } from '@material-ui/core'
import useStyles from './imagePreviewThumbnail.module'
import Paper from '@mui/material/Paper'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import Alert from '@mui/material/Alert'
import { useMediaQueries } from '../../../utils/global-utils'

interface FileUploaderProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (file: File) => void
}

function ImageUploaderThumbnail({ onChange }: FileUploaderProps) {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [filePreview, setFilePreview] = useState<string | null>(null)
  const [fileName, setFileName] = useState<string | null>(null)
  const [errorModal, setErrorModal] = useState(false)

  const isValidFile = (file: File): boolean => {
    return [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ].includes(file.type)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null

    if (file && isValidFile(file)) {
      setSelectedFile(file)
      setFileName(file.name)

      if (file.type.startsWith('image/')) {
        // Handle image preview
        const reader = new FileReader()
        reader.onload = (e) => {
          setFilePreview(e.target?.result as string)
        }
        reader.readAsDataURL(file)
      } else {
        // For PDFs and Word files, only store the file name
        setFilePreview(null)
      }

      onChange(file)
    } else {
      setErrorModal(true)
    }
  }

  return (
    <>
      <div className={classes.uploaderContainer}>
        <input accept='.png, .jpg, .jpeg, .pdf, .doc, .docx' id='file-input' type='file' hidden onChange={handleFileChange} />
        <div className={classes.uploaderLabel}>
          <label htmlFor='file-input'>
            <Button style={{ textTransform: 'none' }} variant='text' component='span' className={formClasses.uploaderComponentButton}>
              Dodaj datoteku
            </Button>
          </label>
        </div>
        {errorModal && (
          <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
            <Alert
              className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
              onClose={() => setErrorModal(false)}
              severity='error'
            >
              Pogrešan format. Izaberite .jpg, .png, .pdf, .doc ili .docx datoteku.
            </Alert>
          </Backdrop>
        )}
      </div>
      {selectedFile && (
        <div className={classes.uploaderThumbnail}>
          <div className={formClasses.headlineSmall}>Izabrana datoteka:</div>
          <Paper className={classes.uploaderPaper}>
            {filePreview ? (
              <img className={classes.uploaderImage} src={filePreview as string} alt='selected-img' />
            ) : (
              <div className={formClasses.headlineSmall}>{fileName}</div>
            )}
          </Paper>
        </div>
      )}
    </>
  )
}

export default ImageUploaderThumbnail
