import useFormStyles from '../../styledComponents/globalFormStying.module'
import { getInitials, getUserId, useMediaQueries } from '../../../utils/global-utils'
import Grid from '@mui/material/Grid/Grid'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import { CircularProgress } from '@material-ui/core'
import Divider from '@mui/material/Divider'
import { SelectChangeEvent } from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import { Button, Typography } from '@material-ui/core'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Box from '@mui/material/Box/Box'
import Chip from '@mui/material/Chip/Chip'
import { Controller, useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { StyledInput } from '../../styledComponents/styledInput/styledInput'
import StyledButton from '../../styledComponents/styledButton.module'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../context/UserContext'
import { EmployeeDetailsResponse, Industry } from '../../../utils/types.d'
import EmployeeApi from '../../../services/employeeApi'
import BillingAndIndustriesApi from '../../../services/billingAndIndustries'
import { StyledSelect } from '../../styledComponents/styledSelect/styledSelect'
import StyledProfilePicture from '../../styledComponents/profileImage/profileImage'
import ImageUploaderThumbnail from '../../styledComponents/imagePreviewThumbnail/imagePreviewThumbnail'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import logoBlue from '../../../assets/logo_blue.svg'

function EmployeeProfile() {
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()

  const { userData, accessToken } = useAuthContext()
  const userId = getUserId(userData)
  const initials = getInitials(userData)

  const [industry, setIndustry] = useState<string[]>([])
  const [fetchIndustries, setFetchIndustries] = useState<Industry[]>([])
  const [fetchEmployeeDetails, setFetchEmployeeDetails] = useState<EmployeeDetailsResponse | null>(null)
  const [isNotifyMeSwitched, setIsNotifyMeSwitched] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)
  const [imageData, setImageData] = useState<string | null>('')
  const [notifyProfileSetup, setNotifyProfileSetup] = useState(false)

  useEffect(() => {
    const hasNotificationBeenShown = localStorage.getItem('profileSetupNotificationShown')
    if (accessToken && !hasNotificationBeenShown) {
      setNotifyProfileSetup(true)
      localStorage.setItem('profileSetupNotificationShown', 'true')
    }
  }, [accessToken])

  const handleCloseProfileSetupModal = () => {
    setNotifyProfileSetup(false)
  }

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (accessToken) {
          const employeeDetailsResponse = await EmployeeApi.GetEmployeeDetails(accessToken, userId)
          setFetchEmployeeDetails(employeeDetailsResponse.data)
        }
      } catch {
        //
      }
    }

    if (accessToken) {
      fetchDetails()
    }
  }, [accessToken, userId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [industriesResponse] = await Promise.all([BillingAndIndustriesApi.GetIndustries({})])
        setFetchIndustries(industriesResponse.data)
      } catch {
        //
      }
    }

    fetchData()
  }, [])

  const { handleSubmit, formState, control, trigger, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      age: '',
      phoneNumber: '',
      country: '',
      industryIds: [],
      city: '',
      aboutMe: '',
      profileImagePath: '',
      notifyByEmail: true
    }
  })

  useEffect(() => {
    if (fetchEmployeeDetails) {
      const { firstName, lastName, age, phoneNumber, country, city, industries, aboutMe, profileImagePath } = fetchEmployeeDetails

      const selectedIndustryNames = industries.map((industry) => industry.industryName)
      const selectedIndustryIds = industries.map((industry) => industry.id)
      const countryName = country?.countryName
      const cityName = city.city

      setIndustry(selectedIndustryNames)
      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('age', age)
      setValue('phoneNumber', phoneNumber)
      setValue('country', countryName)
      setValue('country', countryName)
      setValue('industryIds', selectedIndustryIds as never[])
      setValue('city', cityName)
      setValue('aboutMe', aboutMe)

      if (fetchEmployeeDetails.profileImagePath) {
        setImageData(profileImagePath)
      }
    }
  }, [fetchEmployeeDetails, setValue])

  const onSubmit = async (formData: any) => {
    const { ...editProfileData } = formData
    editProfileData.employeeId = userId

    try {
      const response: any = await EmployeeApi.EditEmployeeProfile(editProfileData, accessToken)
      const { status } = response

      if (status === 200) {
        const employeeDetailsResponse = await EmployeeApi.GetEmployeeDetails(accessToken, userId)
        setImageData(employeeDetailsResponse?.data.profileImagePath as string)
      }
    } catch {
      //
    }
  }

  const handleButtonClick = async () => {
    try {
      await trigger()
      if (Object.keys(formState.errors).length === 0) {
        setLoading(true)
        await handleSubmit(onSubmit)()
        setLoading(false)
      }
    } catch {
      //
    }
  }

  const handleNotifyMeSwitchChange = (event: { target: { checked: boolean } }) => {
    const { checked } = event.target

    setValue('notifyByEmail', checked)
    setIsNotifyMeSwitched(checked)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={10}>
          {loading ? (
            <Backdrop open={true}>
              <div className={formClasses.circularProgressContainer}>
                <StyledSpinningIcon />
              </div>
            </Backdrop>
          ) : (
            <>
              <Grid
                container
                className={`${isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}
              >
                {notifyProfileSetup && (
                  <Dialog maxWidth={'sm'} open={notifyProfileSetup} aria-describedby='profile-setup-dialog'>
                    <DialogContent>
                      <DialogContentText id='profile-setup-dialog'>
                        Opet mi. <br />
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>
                            Ovo je tvoj profil. <br />
                            Da bi povećao/la šanse za pronalaženje svog sezonskog posla iz snova,
                            <br />
                            predlažemo ti da ažuriraš svoj profil. Evo nekoliko koraka: <br />
                          </span>
                        </div>
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>
                            <strong>Industrije:</strong> Izaberi industrije u kojima se pronalaziš. <br />
                            To će pomoći kompanijama da te angažuju lakše. <br />
                          </span>
                        </div>
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>
                            <strong>Nešto o sebi:</strong> Napiši sve one stvari u kojima si "pro". <br />
                          </span>
                        </div>
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>
                            <strong>Profilna slika:</strong> Dodaj profilnu sliku. Prvi utisak je važan! <br />
                          </span>
                        </div>
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>
                            Iskreno tvoj, <img height={12} alt='Sezonal' src={`${logoBlue}`} />
                          </span>
                        </div>
                      </DialogContentText>
                      <DialogActions>
                        <Button onClick={() => handleCloseProfileSetupModal()} autoFocus>
                          Razumem
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                )}
                <>
                  <Grid item xs={12} sm={12} md={12} order={{ xs: 2, sm: 1, md: 1 }} className={formClasses.toggleButtonsContainer}>
                    <form onSubmit={handleButtonClick}>
                      <div className={formClasses.inputContainer}>
                        <Controller
                          name='firstName'
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <StyledInput
                                {...field}
                                disabled={true}
                                variant='outlined'
                                required={false}
                                type='text'
                                label='Ime'
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                inputRef={field.ref}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Controller
                          name='lastName'
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <StyledInput
                                {...field}
                                disabled={true}
                                variant='outlined'
                                required={false}
                                type='text'
                                label='Prezime'
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                inputRef={field.ref}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Controller
                          name='age'
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <StyledInput
                                {...field}
                                disabled={true}
                                variant='outlined'
                                required={false}
                                type='text'
                                label='Godine'
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                inputRef={field.ref}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Controller
                          name='phoneNumber'
                          control={control}
                          rules={{
                            required: 'Polje je obavezno',
                            pattern: {
                              value: /^[0-9]+$/,
                              message: 'Unesite broj'
                            },
                            minLength: {
                              value: 9,
                              message: 'Broj telefona mora imati najmanje 9 cifara'
                            },
                            maxLength: {
                              value: 10,
                              message: 'Broj telefona ne sme imati više od 10 cifara'
                            }
                          }}
                          render={({ field, fieldState }) => {
                            return (
                              <StyledInput
                                {...field}
                                variant='outlined'
                                required={false}
                                type='tel'
                                label='Broj telefona'
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                inputRef={field.ref}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Divider />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Controller
                          name='country'
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <StyledInput
                                {...field}
                                disabled={true}
                                variant='outlined'
                                required={false}
                                type='text'
                                label='Država'
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                inputRef={field.ref}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Controller
                          name='city'
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <StyledInput
                                {...field}
                                disabled={true}
                                variant='outlined'
                                required={false}
                                type='text'
                                label='Grad'
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                inputRef={field.ref}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Divider />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Controller
                          name='industryIds'
                          control={control}
                          render={({ field, fieldState }) => {
                            const handleChange = (event: SelectChangeEvent<typeof industry>) => {
                              const {
                                target: { value }
                              } = event
                              const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean)

                              field.onChange(selectedValues)
                              setIndustry(selectedValues)

                              const selectedIndustryIds = fetchIndustries
                                .filter((industryObj) => selectedValues.includes(industryObj.industryName))
                                .map((industryObj) => industryObj.id)

                              setValue('industryIds', selectedIndustryIds as unknown as never[])
                            }
                            return (
                              <div className={formClasses.customFormErrorsContainer}>
                                {fieldState.error && (
                                  <Typography className={formClasses.customValidationErrors} color='error' variant='caption'>
                                    {fieldState.error.message}
                                  </Typography>
                                )}
                                <FormControl className={formClasses.selectContainer}>
                                  <InputLabel id='field-of-industry' className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                    Industrija
                                  </InputLabel>
                                  <StyledSelect
                                    {...field}
                                    required={false}
                                    label='Industrije koje me zanimaju'
                                    labelId='field-of-industry'
                                    multiple
                                    value={industry}
                                    onChange={handleChange}
                                    input={
                                      <OutlinedInput label='Industrija' className={fieldState.error ? formClasses.customFormErrors : ' '} />
                                    }
                                    error={!!fieldState.error}
                                    inputRef={field.ref}
                                    renderValue={(selected) => {
                                      return (
                                        <Box className={formClasses.chipContainer}>
                                          {selected.map((value: any) => (
                                            <Chip key={value} label={value} />
                                          ))}
                                        </Box>
                                      )
                                    }}
                                  >
                                    {fetchIndustries.map((industryObj) => (
                                      <MenuItem
                                        key={industryObj.id}
                                        value={industryObj.industryName}
                                        className={formClasses.menuItemContainer}
                                      >
                                        {industryObj.industryName}
                                        {industry.includes(industryObj.industryName) ? (
                                          <CheckCircleIcon className={formClasses.checkIcon} />
                                        ) : undefined}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                </FormControl>
                              </div>
                            )
                          }}
                        />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <div className={formClasses.inputContainer}>
                          <Controller
                            name='aboutMe'
                            control={control}
                            render={({ field, fieldState }) => {
                              return (
                                <StyledInput
                                  {...field}
                                  variant='outlined'
                                  required={false}
                                  multiline={true}
                                  rows={4}
                                  type='text'
                                  label='Kratko o sebi'
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                  inputRef={field.ref}
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className={formClasses.inputContainer}>
                        <Divider />
                      </div>
                      <Grid container className={formClasses.inputContainerTop}>
                        <Grid item xs={12} sm={12} md={4}>
                          <div>
                            {fetchEmployeeDetails?.profileImagePath ? (
                              <div className={`${formClasses.avatarRatingsContainer} ${formClasses.mb12}`}>
                                <div className={`${formClasses.avatarsHolderContainerLarge}`}>
                                  <StyledProfilePicture
                                    width={180}
                                    height={180}
                                    alt={`profile-image-${initials}`}
                                    imageData={imageData as string}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className={`${formClasses.avatarRatingsContainer} ${formClasses.mb12}`}>
                                <div className={`${formClasses.avatarContainerLarge} ${formClasses.avatarsHolderContainerLarge}`}>
                                  <div className={formClasses.profileInitialsLarge}>
                                    <span className={formClasses.initialsHolderLarge}>{initials}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                            <Controller
                              name='profileImagePath'
                              control={control}
                              render={({ field }) => {
                                return <ImageUploaderThumbnail onChange={(file: File) => field.onChange(file)} />
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div className={formClasses.inputContainer}>
                        <Divider />
                      </div>
                      <div className={formClasses.inputContainer}>
                        <div className={formClasses.inputContainer}>
                          <Controller
                            name='notifyByEmail'
                            control={control}
                            render={({ field }) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      {...field}
                                      inputRef={field.ref}
                                      size='medium'
                                      checked={isNotifyMeSwitched}
                                      onChange={handleNotifyMeSwitchChange}
                                    />
                                  }
                                  label='Želim da primam obaveštenja putem email-a'
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                          <div className={formClasses.inputContainer}>
                            <StyledButton
                              style={{ textTransform: 'none' }}
                              className={formClasses.w100pi}
                              variant='contained'
                              onClick={handleButtonClick}
                            >
                              {loading ? <CircularProgress size={24} className='circular-progress' /> : ' Sačuvaj promene'}
                            </StyledButton>
                          </div>
                          <div>
                            <StyledButton
                              style={{ textTransform: 'none' }}
                              className={formClasses.w100pi}
                              variant='text'
                              onClick={() => navigate(-1)}
                            >
                              Nazad
                            </StyledButton>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default EmployeeProfile
