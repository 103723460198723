import { Grid } from '@mui/material'
import SOCIAL_MEDIA_ICONS from '../../../assets/media'
import { navigateWithScroll, useMediaQueries } from '../../../utils/global-utils'
import CopyrightIcon from '@mui/icons-material/Copyright'
import useStyles from './landingBody.module'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useNavigate } from 'react-router-dom'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

function LandingFooter() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  return (
    <div className={`${isMobile ? classes.footerSectionMobile : classes.footerSection}`}>
      <div className={`${classes.p32} ${classes.pb0i}`}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container display='flex' alignItems='center'>
              <Grid item xs={6} sm={6} md={4}>
                <div className={classes.copyrightContainer}>
                  <span className={classes.footerText}>info@sezonal.com</span>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={8}>
                <div className={classes.copyrightContainer}>
                  <EmailOutlinedIcon className={`${formClasses.w18} ${formClasses.pSides12}`} />
                </div>
              </Grid>
            </Grid>
            <Grid container display='flex' alignItems='center'>
              <Grid item xs={6} sm={6} md={4}>
                <div className={classes.copyrightContainer}>
                  <span className={classes.footerText}>+381 69 386 7477</span>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={8}>
                <div className={classes.copyrightContainer}>
                  <WhatsAppIcon className={`${formClasses.w18} ${formClasses.pSides12}`} />
                  <img width='18' height='18' src={`${SOCIAL_MEDIA_ICONS.viberIcon}`} />
                </div>
              </Grid>
            </Grid>
            <Grid container display='flex' alignItems='center'>
              <Grid item xs={6} sm={6} md={4}>
                <div className={classes.copyrightContainer}>
                  <span className={classes.footerText}>+381 65 402 2322</span>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={8}>
                <div className={classes.copyrightContainer}>
                  <WhatsAppIcon className={`${formClasses.w18} ${formClasses.pSides12}`} />
                  <img width='18' height='18' src={`${SOCIAL_MEDIA_ICONS.viberIcon}`} />
                </div>
              </Grid>
            </Grid>
            <Grid container display='flex' alignItems='center'>
              <Grid item xs={6} sm={6} md={4}>
                <div className={classes.copyrightContainer}>
                  <span
                    onClick={() => navigateWithScroll(navigate, `/terms-and-conditions-general`)}
                    className={`${classes.footerText} ${formClasses.cpr}`}
                  >
                    Uslovi korišćenja
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={8}>
                <div className={classes.copyrightContainer}>
                  <DescriptionOutlinedIcon className={`${formClasses.w18} ${formClasses.pSides12}`} />
                </div>
              </Grid>
            </Grid>
            <Grid container display='flex' alignItems='center'>
              <Grid item xs={6} sm={6} md={4}>
                <div className={classes.copyrightContainer}>
                  <span
                    onClick={() => navigateWithScroll(navigate, `/privacy-policy-general`)}
                    className={`${classes.footerText} ${formClasses.cpr}`}
                  >
                    Politika privatnosti
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={8}>
                <div className={classes.copyrightContainer}>
                  <PolicyOutlinedIcon className={`${formClasses.w18} ${formClasses.pSides12}`} />
                </div>
              </Grid>
            </Grid>
            <div className={classes.copyrightContainer}>
              <span className={`${classes.footerText}`}>
                2023 Sezonal <CopyrightIcon sx={{ fontSize: '14px' }} /> RasTech d.o.o. <br /> Sva prava zadržana.
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} display='flex' justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
            <div className={classes.copyrightContainer}>
              <div className={`${formClasses.mr12}`}>
                <a href='https://www.instagram.com/sezonal_com/' target='_blank'>
                  <img width='24' height='24' src={`${SOCIAL_MEDIA_ICONS.instagram}`} />
                </a>
              </div>
              <div className={`${formClasses.mr12} ${formClasses.ml12}`}>
                <a href='https://www.facebook.com/sezonalcom' target='_blank'>
                  <img width='24' height='24' src={`${SOCIAL_MEDIA_ICONS.facebook}`} />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default LandingFooter
