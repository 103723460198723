import Grid from '@mui/material/Grid/Grid'
import FunctionalHeader from '../../functionalHeader/functionalHeader'
import useStyles from './employerDashboard.module'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { handleNavigate, useMediaQueries } from '../../../utils/global-utils'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../context/UserContext'
import AddApi from '../../../services/addApi'
import { useEffect, useState } from 'react'
import logoBlue from '../../../assets/logo_blue.svg'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import Dialog from '@mui/material/Dialog'
import Alert from '@mui/material/Alert'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Button, Link } from '@material-ui/core'
import PricingPackagesDialog from '../pricingPackagesDialog/pricingPackagesDialog'
import Box from '@mui/material/Box/Box'
import EmployerProfile from '../employerProfile/employerProfile'
import NewJob from '../newJob/newJob'
import ActiveAds from '../activeAds/activeAds'
import LandingFooter from '../../wwwLandingPage/landingPageBody/landingFooter'
import FetchUserDetails from '../../fetchUserDetails/fetchUserDetails'
import Stack from '@mui/material/Stack/Stack'

function EmployerDashboard() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  const [, setLoading] = useState(true)

  const { userData, accessToken } = useAuthContext()
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)
  const [displayAdBlockerNotificationModal, setDisplayAdBlockerNotificationModal] = useState(false)
  const [, setSelectedPackageType] = useState<string | undefined>()
  const [errorModal, setErrorModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const TabPanel = (props: { value: number; index: number; children: React.ReactNode }) => {
    const { children, value, index, ...other } = props
    return (
      <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
        {value === index && <Box p={`${isMobile ? 0 : 3}`}>{children}</Box>}
      </div>
    )
  }

  useEffect(() => {
    const hasNotificationBeenShown = localStorage.getItem('adBlockerNotificationShown')
    if (accessToken && !hasNotificationBeenShown) {
      setDisplayAdBlockerNotificationModal(true)
      localStorage.setItem('adBlockerNotificationShown', 'true')
    }
  }, [accessToken])

  const handleCloseAdBlickerModal = () => {
    setDisplayAdBlockerNotificationModal(false)
  }

  const handleOpenSubscriptionModal = () => {
    setOpenSubscriptionModal(true)
  }
  const handleCloseSubscriptionModal = () => {
    setOpenSubscriptionModal(false)
  }

  const handlePackageTypeSubmit = async (type: string | undefined) => {
    try {
      setSelectedPackageType(type)
      setLoading(true)
      const employerId = userData?.nameid
      const packagePurchaseData = {
        employerId: employerId,
        packageTypeId: type
      }
      const response: any = await AddApi.PackagePurchase(packagePurchaseData, accessToken)
      const { status } = response

      if (status === 200) {
        setSuccessModal(true)
      }
      setLoading(false)
    } catch {
      setErrorModal(true)
    }

    setOpenSubscriptionModal(false)
  }

  const handlePricingModalConfirm = (type: string | undefined) => {
    setSelectedPackageType(type)
    handlePackageTypeSubmit(type)
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <FunctionalHeader />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              container
              className={`${formClasses.layoutContainer} ${isMobile ? formClasses.headlineContainerMobile : formClasses.headlineContainer}`}
            >
              {displayAdBlockerNotificationModal && (
                <Dialog maxWidth={'sm'} open={displayAdBlockerNotificationModal} aria-describedby='add-blocker-dialog'>
                  <DialogContent>
                    <DialogContentText id='add-blocker-dialog'>
                      Poštovani, <br />
                      <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                        <span>
                          ukoliko koristite neki od alata za blokiranje sadržaja molimo Vas da ga privremeno isključite kako biste nesmetano
                          koristili našu aplikaciju. <br />
                        </span>
                      </div>
                      <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                        <span>
                          Hvala na razumevanju, <br />
                        </span>
                      </div>
                      <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                        <span>
                          Vaš <img height={12} alt='Sezonal' src={`${logoBlue}`} />
                        </span>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseAdBlickerModal()} autoFocus>
                      Razumem
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              {successModal && (
                <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                  <Dialog maxWidth={'sm'} open={successModal} aria-describedby='successful-payment-dialog'>
                    <DialogContent>
                      <DialogContentText id='successful-payment-dialog'>
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>Uspešna kupovina paketa.</span>
                        </div>
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>Sezonal će vam poslati detalje plaćanja na vašu e-mail adresu.</span>
                        </div>
                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                          <span>
                            Vaš, <img height={12} alt='Sezonal' src={`${logoBlue}`} />
                          </span>
                        </div>
                      </DialogContentText>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setSuccessModal(false)
                            handleNavigate(navigate, `/employer-dashboard/`)
                          }}
                        >
                          Razumem
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </Backdrop>
              )}
              {errorModal && (
                <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                  <Alert
                    className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                    onClose={() => setErrorModal(false)}
                    severity='error'
                  >
                    Došlo je do greške
                  </Alert>
                </Backdrop>
              )}
              <div className={formClasses.bodyItemsHolder}>
                {isMobile && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                        <Tabs
                          orientation='horizontal'
                          variant='scrollable'
                          value={activeTab}
                          onChange={handleTabChange}
                          aria-label='Horiontal tabs'
                          className={classes.horizontalTabsContainer}
                        >
                          <Tab label='Moj Profil' />
                          <Tab label='Postavi oglas' />
                          <Tab label='Moji aktivni oglasi' />
                          <Tab label='Odaberi paket oglasa' />
                        </Tabs>
                      </Grid>
                      <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                        <TabPanel value={activeTab} index={0}>
                          <Stack className={formClasses.pt24}>
                            <FetchUserDetails />
                            <EmployerProfile />
                          </Stack>
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                          <NewJob />
                        </TabPanel>
                        <TabPanel value={activeTab} index={2}>
                          <ActiveAds />
                        </TabPanel>
                        <TabPanel value={activeTab} index={3}>
                          <div className={`${formClasses.canditateName} ${formClasses.mt24}`}>
                            <Link onClick={handleOpenSubscriptionModal} className={`${formClasses.fwbi}`}>
                              Ovde{' '}
                            </Link>
                            možete izabrati paket oglasa koji najviše odgovara Vašim potrebama.
                          </div>
                          {openSubscriptionModal && (
                            <Backdrop open={true}>
                              <PricingPackagesDialog
                                open={openSubscriptionModal}
                                onClose={handleCloseSubscriptionModal}
                                onConfirm={handlePricingModalConfirm}
                              />
                            </Backdrop>
                          )}
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!isMobile && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} sx={{ mb: 1 }}>
                      <Tabs
                        orientation='vertical'
                        variant='scrollable'
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label='Vertical tabs'
                        className={classes.verticalTabsContainer}
                      >
                        <Tab className={classes.verticalTabsHeading} label='Moj Profil' />
                        <Tab label='Postavi oglas' />
                        <Tab label='Moji aktivni oglasi' />
                        <Tab label='Odaberi paket oglasa' />
                      </Tabs>
                    </Grid>
                    <Grid item xs={12} sm={4} md={9} sx={{ mb: 1 }}>
                      <TabPanel value={activeTab} index={0}>
                        <EmployerProfile />
                      </TabPanel>
                      <TabPanel value={activeTab} index={1}>
                        <NewJob />
                      </TabPanel>
                      <TabPanel value={activeTab} index={2}>
                        <ActiveAds />
                      </TabPanel>
                      <TabPanel value={activeTab} index={3}>
                        <div className={`${formClasses.canditateName}`}>
                          <Link onClick={handleOpenSubscriptionModal} className={`${formClasses.fwbi}`}>
                            Ovde{' '}
                          </Link>
                          možete izabrati paket oglasa koji najviše odgovara Vašim potrebama.
                        </div>
                        {openSubscriptionModal && (
                          <Backdrop open={true}>
                            <PricingPackagesDialog
                              open={openSubscriptionModal}
                              onClose={handleCloseSubscriptionModal}
                              onConfirm={handlePricingModalConfirm}
                            />
                          </Backdrop>
                        )}
                      </TabPanel>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </>
  )
}

export default EmployerDashboard
