import Grid from '@mui/material/Grid/Grid'
import useStyles from '../landingPageBody/landingBody.module'
import { useMediaQueries } from '../../../utils/global-utils'
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import BODY_ITEMS from '../../../assets/bodyItems/index'

function FAQSection() {
  const classes = useStyles()
  const { isMobile } = useMediaQueries()
  const [isVisible, setIsVisible] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)

  const { ref: benefitsRef, inView: benefitsInView } = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  useEffect(() => {
    if (benefitsInView) {
      setIsVisible(true)
    }
  }, [benefitsInView])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={`${classes.middleBodyContainer}`} style={{ backgroundImage: `url(${BODY_ITEMS.CITY_LANDING})` }}>
      <Grid container ref={benefitsRef} className={`${isMobile ? '' : classes.plr36} ${classes.ptb60}`} justifyContent='center'>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>
            <div className={`${classes.primaryTextContainer}`}>
              <div className={`${classes.mtb36}`}>
                <span className={`${classes.benefitsHeadline}`} style={{ color: '#FFFFFF' }}>
                  Česta pitanja
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={`${classes.plr12} ${isMobile ? classes.mtb12 : ''} ${isVisible ? classes.show : ''} ${classes.item}`}
        >
          <Accordion className={`${classes.tac}`} expanded={expanded === 'how-long-is'} onChange={handleChange('how-long-is')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='how-long-is-content' id='how-long-is'>
              <span className={isMobile ? classes.benefitsListSmall : classes.benefitsList}>Koliko traje sezonski rad</span>
            </AccordionSummary>
            <AccordionDetails>
              Rad na sezoni obično traje oko 6 meseci. Mnoga mesta nude opciju stalnog zaposlenja nakon sezone. Ako je to nešto što te
              zanima, piši nam da te uputimo na poslodavce koji nude te opcije.
            </AccordionDetails>
          </Accordion>
          <Accordion className={`${classes.tac}`} expanded={expanded === 'how-can-I-apply'} onChange={handleChange('how-can-I-apply')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='how-can-I-apply-content' id='how-can-I-apply'>
              <span className={isMobile ? classes.benefitsListSmall : classes.benefitsList}>Kako da se prijavim za posao</span>
            </AccordionSummary>
            <AccordionDetails>
              Pošalji nam poruku na <strong>info@sezonal.com</strong> sa poslom koji ti najviše odgovara. Napiši nam od kad do kad ti
              odogovara da radiš. Pogledaj naše pakete, i javi šta ti najviše odgovara.
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={`${classes.tac}`}
            expanded={expanded === 'can-I-work-without-contract'}
            onChange={handleChange('can-I-work-without-contract')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='can-I-work-as-foreigner' id='can-I-work-as-foreigner'>
              <span className={isMobile ? classes.benefitsListSmall : classes.benefitsList}>Mogu li raditi kao stranac</span>
            </AccordionSummary>
            <AccordionDetails>
              Da naravno. U Hrvatskoj i Crnoj Gori je velika potražnja za sezonskim radnicima i lako se dobijaju radne dozvole.
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={`${classes.plr12} ${isMobile ? classes.mtb12 : ''} ${isVisible ? classes.show : ''} ${classes.item}`}
        >
          <Accordion className={`${classes.tac}`} expanded={expanded === 'can-I-work'} onChange={handleChange('can-I-work')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='can-I-work-content' id='can-I-work'>
              <span className={isMobile ? classes.benefitsListSmall : classes.benefitsList}>Mogu li raditi bez ugovora</span>
            </AccordionSummary>
            <AccordionDetails>
              Ne, rad bez ugovora nije moguć. Svaki poslodavac je dužan da obezbedi radnu dozvolu zaposleniku i prijavi ga, kako bi i išao
              staž i zdravstveno osiguranje.{' '}
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={`${classes.tac}`}
            expanded={expanded === 'do-I-have-accomodation'}
            onChange={handleChange('do-I-have-accomodation')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='do-I-have-accomodation-content' id='do-I-have-accomodation'>
              <span className={isMobile ? classes.benefitsListSmall : classes.benefitsList}>Da li je smeštaj obezbedjen</span>
            </AccordionSummary>
            <AccordionDetails>Da, svi posodavci sa kojima sarađujemo obezbeđuju smeštaj, kao i 1 do 2 obroka dnevno.</AccordionDetails>
          </Accordion>
          <Accordion className={`${classes.tac}`} expanded={expanded === 'student-jobs'} onChange={handleChange('student-jobs')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='student-jobs-content' id='student-jobs'>
              <span className={isMobile ? classes.benefitsListSmall : classes.benefitsList}>Poslovi za studente</span>
            </AccordionSummary>
            <AccordionDetails>
              Iako si student, možeš da radiš sezonski na moru. Potreban ti je samo papir o nekažnjavanju i možeš da potpišeš ugovor na 3
              meseca.
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  )
}

export default FAQSection
