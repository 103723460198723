import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid/Grid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MobileHeader from '../mobileHeader/mobileHeader'
import useFormStyles from '../styledComponents/globalFormStying.module'
import { useMediaQueries } from '../../utils/global-utils'
import Typography from '@material-ui/core/Typography'
import LandingFooter from './landingPageBody/landingFooter'

function AboutUs() {
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <MobileHeader />
            </Grid>
          </Grid>
          <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
            <div className={formClasses.companyNameHeadline}>
              <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Šta je Sezonal?
            </div>
          </div>
          <Grid container justifyContent='center' className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
            <Grid item xs={12} sm={12} md={10}>
              <div className={formClasses.bodyItemsHolder}>
                <Typography>
                  Zdravo! Mi smo TIM ljudi koji su prošli baš kroz ono što Vas možda sada čeka – sezonski poslovi! Tokom studija radili smo
                  razne sezonske poslove, od konobarisanja i animacije u turizmu, do branja voća i rada u proizvodnji. I znate šta? Znamo
                  kako to izgleda – i dobro i loše. Upravo zato smo došli na ideju da stvorimo platformu koja će sezonski rad podići na viši
                  nivo!
                </Typography>
                <br />
                <Typography>
                  Naša misija je jednostavna – spojiti poslodavce i radnike na najlakši i najefikasniji način. Želimo da Vam olakšamo proces
                  traženja i pronalaženja posla, ali i da ga učinimo zabavnim i motivišućim!
                </Typography>
                <br />
                <Typography>
                  U našem timu se nalaze iskusni „sezonci“ koji su prošli sve – od prvog razgovora do napredovanja na sezonskim poslovima.
                  Sad želimo da svoje iskustvo i znanje podelimo sa Vama! Bilo da ste novi u sezonskom svetu ili već imate iskustva, naša
                  misija je da Vam pomognemo da pronađete posao koji će Vam savršeno leći – i po uslovima i po atmosferi.
                </Typography>
                <br />
                <Typography>
                  Verujemo da sezonski rad može biti prilika za sjajnu zaradu, nova iskustva i čak nova prijateljstva! Zato smo tu – da
                  učinimo ceo proces jednostavnim, brzim i prijatnim, kako za Vas, tako i za poslodavce.
                </Typography>
                <Typography>Pridružite se našoj zajednici i hajde da zajedno stvaramo bolje prilike za sve! 🌟</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </>
  )
}

export default AboutUs
