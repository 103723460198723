import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  adHeadlineContainer: {
    color: '#636366',
    fontSize: '24px',
    lineHeight: '24px',
    padding: '18px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  adHeadlineContainerMobile: {
    color: '#636366',
    fontSize: '18px',
    lineHeight: '18px',
    padding: '24px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  adHeadlineDetails: {
    color: '#636366',
    fontSize: '18px',
    lineHeight: '24px',
    padding: '12px 12px 12px 18px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center'
  }
}))

export default useStyles
