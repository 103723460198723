import axios, { AxiosResponse } from 'axios'
import { APP_CORE } from '../environment'

const GET_ALL_BLOGS = '/Blog/getAllBlogs'
const ADD_NEW_BLOG = '/Blog/addBlog'
const GET_SELECTED_BLOG = '/Blog/getBlog'
const DELETE_BLOG = 'Blog/deleteBlog'

const api = axios.create({
  baseURL: APP_CORE
})

const apiGetNoAuth = (operation: string, data: object): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    api
      .get(operation, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const apiGetBlog = (operation: string, blogId: string): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    const params: Record<string, string> = {}

    if (blogId) {
      params.BlogId = `${blogId}`
    }

    api
      .get(operation, {
        params
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const apiDeleteBlog = (operation: string, accessToken: string | null, blogId: string): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    api
      .delete(operation, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        data: { blogId: blogId }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const apiPost = (operation: string, data: object, accessToken: string | null) => {
  return new Promise((resolve, reject) => {
    api
      .post(operation, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        },
        data: {
          data
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const GetAllBlogs = async (data: object) => {
  return apiGetNoAuth(GET_ALL_BLOGS, data)
}

const GetSelectedBlog = async (blogId: string) => {
  return apiGetBlog(GET_SELECTED_BLOG, blogId)
}

const AddNewBlog = async (data: object, accessToken: string | null) => {
  return apiPost(ADD_NEW_BLOG, data, accessToken)
}

const DeleteBlog = async (accessToken: string | null, blogId: string) => {
  return apiDeleteBlog(DELETE_BLOG, accessToken, blogId)
}

const BlogApi = {
  GetAllBlogs,
  AddNewBlog,
  GetSelectedBlog,
  DeleteBlog
}
export default BlogApi
