import useFormStyles from '../../styledComponents/globalFormStying.module'
import Grid from '@mui/material/Grid/Grid'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import { useMediaQueries } from '../../../utils/global-utils'
import { StyledInput } from '../../styledComponents/styledInput/styledInput'
import ImageUploaderThumbnail from '../../styledComponents/imagePreviewThumbnail/imagePreviewThumbnail'
import BlogApi from '../../../services/blogApi'
import StyledButton from '../../styledComponents/styledButton.module'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import Alert from '@mui/material/Alert/Alert'
import { useAuthContext } from '../../../context/UserContext'
import Divider from '@mui/material/Divider/Divider'
import Typography from '@mui/material/Typography/Typography'

function NewBlog() {
  const { accessToken } = useAuthContext()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)

  const { handleSubmit, control, trigger, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      BlogTitle: '',
      BlogText: '',
      BlogImage: '',
      AltTag: ''
    }
  })

  const onSubmit = async (formData: any) => {
    try {
      const response: any = await BlogApi.AddNewBlog(formData, accessToken)
      const { status } = response
      if (status === 200) {
        setSuccessModal(true)
      }

      reset({
        BlogTitle: '',
        BlogText: '',
        BlogImage: '',
        AltTag: ''
      })
    } catch {
      setErrorModal(true)
    }
  }

  const handleButtonClick = async () => {
    try {
      await trigger()
      if (Object.keys(formState.errors).length === 0) {
        setLoading(true)
        await handleSubmit(onSubmit)()
        setLoading(false)
      }
    } catch {
      //
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10}>
        <Grid
          container
          className={`${isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}
        >
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleButtonClick} className={isMobile ? formClasses.mb24 : ''}>
              <div className={formClasses.inputContainer}>
                <Controller
                  name='BlogTitle'
                  control={control}
                  rules={{
                    required: 'Polje je obavezno'
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <StyledInput
                        variant='outlined'
                        required={true}
                        type='text'
                        label='Naziv bloga'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        inputRef={field.ref}
                        {...field}
                      />
                    )
                  }}
                />
              </div>
              <div className={formClasses.inputContainer}>
                <Controller
                  name='BlogText'
                  control={control}
                  rules={{
                    required: 'Polje je obavezno',
                    maxLength: 2000
                  }}
                  render={({ field, fieldState }) => {
                    const CHARACTER_LIMIT = 2000
                    return (
                      <StyledInput
                        variant='outlined'
                        multiline={true}
                        rows={4}
                        required={true}
                        type='text'
                        label='Opis bloga'
                        error={!!fieldState.error}
                        InputProps={{
                          maxLength: CHARACTER_LIMIT
                        }}
                        helperText={`${field.value.length} / ${CHARACTER_LIMIT}`}
                        inputRef={field.ref}
                        {...field}
                      />
                    )
                  }}
                />
              </div>
              <div className={formClasses.inputContainerTop}>
                <Divider />
              </div>
              <div className={formClasses.formText}>
                <Typography className={formClasses.headline}>Dodaj sliku bloga</Typography>
              </div>
              <div className={formClasses.inputContainer}>
                <Controller
                  name='BlogImage'
                  control={control}
                  rules={{
                    required: 'Polje je obavezno'
                  }}
                  render={({ field }) => {
                    return <ImageUploaderThumbnail onChange={(file: File) => field.onChange(file)} />
                  }}
                />
              </div>
              <div className={formClasses.inputContainerTop}>
                <Divider />
              </div>
              <div className={formClasses.inputContainer}>
                <Controller
                  name='AltTag'
                  control={control}
                  rules={{
                    required: 'Polje je obavezno'
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <StyledInput
                        variant='outlined'
                        required={true}
                        type='text'
                        label='Kratki alt tag sa ključnim rečima bloga'
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        inputRef={field.ref}
                        {...field}
                      />
                    )
                  }}
                />
              </div>
              <div className={formClasses.inputContainer}>
                <StyledButton
                  style={{ textTransform: 'none' }}
                  variant='contained'
                  className={isMobile ? formClasses.w100pi : ' '}
                  onClick={handleButtonClick}
                >
                  {loading ? <CircularProgress size={24} className='circular-progress' /> : 'Dodaj blog'}
                </StyledButton>
              </div>
              {successModal && (
                <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                  <Alert
                    className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                    onClose={() => setSuccessModal(false)}
                    severity='success'
                  >
                    Uspešno dodavanje bloga
                  </Alert>
                </Backdrop>
              )}
              {errorModal && (
                <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                  <Alert
                    className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                    onClose={() => setErrorModal(false)}
                    severity='error'
                  >
                    Došlo je do greške
                  </Alert>
                </Backdrop>
              )}
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NewBlog
