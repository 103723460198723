import { FilteredEmployerResponse } from '../../../utils/types.d'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import useStyles from './detailedEmployerCard.module'
import Grid from '@mui/material/Grid/Grid'
import { useEffect, useState } from 'react'
import EmployerApi from '../../../services/employerApi'
import StyledProfilePicture from '../../styledComponents/profileImage/profileImage'

function DetailedEmployerCard() {
  const formClasses = useFormStyles()
  const classes = useStyles()

  const [fetchEmployers, setFetcEmployers] = useState<FilteredEmployerResponse[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employersResponse = await EmployerApi.GetAllEmployers({})
        const highlightedEmployers = employersResponse.data.filter(
          (highlighted: { isHighlighted: boolean }) => highlighted.isHighlighted === true
        )

        setFetcEmployers(highlightedEmployers)
      } catch {
        //
      }
    }

    fetchData()
  }, [])

  return (
    <>
      <Grid container display='flex' justifyContent='center'>
        {fetchEmployers?.map((details: FilteredEmployerResponse, index: number) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <div className={classes.employerCardContainer}>
              <div>
                {details?.image ? (
                  <div className={`${formClasses.avatarsHolderContainerMedium}`}>
                    <StyledProfilePicture
                      style={{ pointerEvents: 'none' }}
                      width={60}
                      height={60}
                      alt={`employer-profile-image-${index}`}
                      imageData={details?.image as string}
                    />
                  </div>
                ) : (
                  <>
                    {details.companyName && (
                      <div className={formClasses.avatarContainer}>
                        <div key={index} className={formClasses.initialsHolderMedium}>{`${details?.companyName[0]}`}</div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={`${formClasses.pt12} ${formClasses.pb12}`}>
                <div className={formClasses.companyName}>{`${details.companyName}`}</div>
                <div className={`${formClasses.companyDescription} ${formClasses.pt12} ${formClasses.pb12}`}>
                  <span className={classes.moreInfoDetails}>aktivnih oglasa:</span>
                  <span className={classes.moreInfoDetails}>{details.numberOfActiveAds}</span>
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default DetailedEmployerCard
