import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  detailedCardContainerRegular: {
    border: '1px solid #B8B8BD',
    backgroundColor: '#F2F2F7',
    borderRadius: '18px',
    padding: '12px',
    marginBottom: '6px !important',
    cursor: 'pointer',
    height: '322px',
    maxHeight: '322px',
    overflowY: 'auto',
    marginLeft: '12px !important',
    marginRight: '12px !important'
  },
  detailedCardContainerSponsored: {
    border: '1px solid #BFA173',
    backgroundColor: '#FFEFD6',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '6px !important',
    cursor: 'pointer',
    height: '322px',
    maxHeight: '322px',
    overflowY: 'auto',
    marginLeft: '12px !important',
    marginRight: '12px !important'
  },
  detailedCardContainerPremium: {
    border: '1px solid #0033FF',
    backgroundColor: '#F2F2F7',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '6px !important',
    cursor: 'pointer',
    height: '322px',
    maxHeight: '322px',
    overflowY: 'auto',
    marginLeft: '12px !important',
    marginRight: '12px !important'
  },
  fieldGroupChip: {
    backgroundColor: '#EBEFFF !important',
    border: '1px solid #0033FF !important',
    color: '#0033FF !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    margin: '2px'
  },
  standardChip: {
    backgroundColor: '#0033FF33 !important',
    opacity: '80%',
    color: '#001A80 !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    fontSize: '14px !important',
    marginRight: '6px !important'
  },
  sponsoredChip: {
    backgroundColor: '#BFA173 !important',
    color: '#FFEFD6 !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    fontSize: '14px !important',
    border: '1px solid #BFA173 !important',
    marginRight: '6px !important'
  },
  premiumChip: {
    backgroundColor: '#001A80 !important',
    color: '#FFFFFF !important',
    borderRadius: '12px !important',
    height: 'fit-content !important',
    padding: '6px !important',
    fontSize: '14px !important',
    border: '1px solid #0033FF !important',
    marginRight: '6px !important'
  },
  dividerSponsored: {
    border: '1px solid #BFA173 !important',
    opacity: '10%'
  },
  dividerPremium: {
    border: '1px solid #0033FF !important',
    opacity: '10%'
  },
  dividerRegular: {
    border: '1px solid #0033FF !important',
    opacity: '10%'
  },
  headlineContainer: {
    marginTop: '18px',
    marginBottom: '36px',
    width: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  myAddContainers: {
    marginTop: '18px'
  },
  adTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#2C2C2E'
  },
  adTitleMobile: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#2C2C2E'
  },
  myAddDetails: {
    display: 'flex'
  },
  explanationIcons: {
    color: '#001A80',
    width: '16px !important',
    height: '16px !important',
    paddingLeft: '12px',
    paddingRight: '6px',
    '&:first-child': {
      paddingLeft: '0px'
    }
  },
  checkIcon: {
    color: '#1FCC5A !important'
  },
  detailsTextContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  },
  detailsText: {
    color: '#636366',
    fontSize: '16px'
  },
  star: {
    fontSize: '16px !important',
    color: '#FFD740 !important',
    position: 'relative',
    top: '3px',
    paddingLeft: '3px',
    paddingRight: '3px'
  },
  companyName: {
    fontSize: '16px',
    color: '#0033FF',
    width: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  companyNameMobile: {
    fontSize: '16px',
    color: '#0033FF',
    width: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  landingCardsBody: {
    cursor: 'pointer',
    transition: 'transform .6s',
    '&:hover, &:focus, &:active': {
      transform: 'scale(1.01)'
    }
  }
}))

export default useStyles
