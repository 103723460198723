import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  bodyText: {
    padding: '16px 24px',
    color: '#636366',
    fontSize: '14px'
  },
  bodyTextSmall: {
    paddingTop: '12px',
    color: '#636366',
    fontSize: '12px'
  },
  actionButtonsContainer: {
    padding: '36px !important'
  }
}))

export default useStyles
