import Grid from '@mui/material/Grid/Grid'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useMediaQueries } from '../../../utils/global-utils'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import { useEffect, useState } from 'react'
import { EmployerAddResponse } from '../../../utils/types.d'
import { useAuthContext } from '../../../context/UserContext'
import AddApi from '../../../services/addApi'
import Divider from '@material-ui/core/Divider/Divider'
import SingleAd from '../singleAd/singleAd'

function ActiveAds() {
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const [loading, setLoading] = useState(true)
  const [fetchEmployerActiveAdds, setFetchEmployerActiveAdds] = useState<EmployerAddResponse[]>([])

  const { accessToken } = useAuthContext()

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (accessToken) {
          const activeAddsResponse = await AddApi.GetActiveEmpleyerAdds(accessToken)
          setFetchEmployerActiveAdds(activeAddsResponse.data)

          setLoading(false)
        }
      } catch {
        setLoading(false)
      }
    }

    if (accessToken) {
      setLoading(true)
      fetchDetails()
    }
  }, [accessToken])

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={10}>
          <Grid
            container
            className={`${isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}
          >
            <div className={formClasses.bodyItemsHolder}>
              {loading ? (
                <Backdrop open={true}>
                  <div className={formClasses.circularProgressContainer}>
                    <StyledSpinningIcon />
                  </div>
                </Backdrop>
              ) : (
                <>
                  {fetchEmployerActiveAdds.length ? (
                    <>
                      {fetchEmployerActiveAdds?.map((addDetails: any, index: number) => <SingleAd key={index} addDetails={addDetails} />)}
                    </>
                  ) : (
                    <>
                      <div className={formClasses.inputContainerTop}>
                        <div className={`${formClasses.canditateName} ${formClasses.ml28}`}>Nemate aktivnih oglasa</div>
                      </div>
                      <div className={formClasses.inputContainerTop}>
                        <Divider />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ActiveAds
