import Grid from '@mui/material/Grid/Grid'
import FunctionalHeader from '../../functionalHeader/functionalHeader'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import Alert from '@mui/material/Alert'
import { CircularProgress } from '@material-ui/core'
import { useState } from 'react'
import { useMediaQueries } from '../../../utils/global-utils'
import { useAuthContext } from '../../../context/UserContext'
import { Controller, useForm } from 'react-hook-form'
import { StyledInput } from '../../styledComponents/styledInput/styledInput'
import StyledButton from '../../styledComponents/styledButton.module'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AdminApi from '../../../services/adminApi'
import Box from '@mui/material/Box/Box'
import useStyles from './adminPanel.module'
import Stack from '@mui/material/Stack/Stack'
import NewBlog from '../adminBlogs/addNewBlog'
import BlogPostSection from '../../wwwLandingPage/blogPostSection/blogPostSection'
import SearchAdsAdmin from '../searchAdsAdmin/searchAdsAdmin'

function AdminPanel() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const { accessToken } = useAuthContext()
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const { handleSubmit, formState, control, trigger, reset } = useForm({
    defaultValues: {
      paymentReference: ''
    }
  })

  const onSubmit = async (formData: any) => {
    try {
      const response: any = await AdminApi.ApproveTheAd(accessToken, formData)
      reset({
        paymentReference: ''
      })
      const { status } = response
      if (status === 200) {
        setSuccessModal(true)
      }
    } catch {
      setErrorModal(true)
    }
  }

  const handleButtonClick = async () => {
    try {
      await trigger()
      if (Object.keys(formState.errors).length === 0) {
        setLoading(true)
        await handleSubmit(onSubmit)()
        setLoading(false)
      }
    } catch {
      //
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const TabPanel = (props: { value: number; index: number; children: React.ReactNode }) => {
    const { children, value, index, ...other } = props
    return (
      <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
        {value === index && <Box p={`${isMobile ? 0 : 3}`}>{children}</Box>}
      </div>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <FunctionalHeader />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              container
              className={`${formClasses.layoutContainer} ${isMobile ? formClasses.headlineContainerMobile : formClasses.headlineContainer}`}
            >
              <div className={formClasses.bodyItemsHolder}>
                {isMobile && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                        <Tabs
                          orientation='horizontal'
                          variant='scrollable'
                          value={activeTab}
                          onChange={handleTabChange}
                          aria-label='Horiontal tabs'
                          className={classes.horizontalTabsContainer}
                        >
                          <Tab label='Odobri oglase' />
                          <Tab label='Dodaj nov blog' />
                          <Tab label='Svi blogovi' />
                          <Tab label='Svi oglasi' />
                        </Tabs>
                      </Grid>
                      <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                        <Stack className={formClasses.pt24}>
                          <TabPanel value={activeTab} index={0}>
                            <div className={formClasses.bodyItemsHolder}>
                              <form onSubmit={handleButtonClick} className={isMobile ? formClasses.mb24 : ''}>
                                <div className={formClasses.inputContainer}>
                                  <Controller
                                    name='paymentReference'
                                    control={control}
                                    rules={{
                                      required: 'Polje je obavezno'
                                    }}
                                    render={({ field, fieldState }) => {
                                      return (
                                        <StyledInput
                                          variant='outlined'
                                          required={true}
                                          type='text'
                                          label='Unesi poziv na broj'
                                          error={!!fieldState.error}
                                          helperText={fieldState.error?.message}
                                          inputRef={field.ref}
                                          {...field}
                                        />
                                      )
                                    }}
                                  />
                                </div>
                                <div className={formClasses.inputContainer}>
                                  <StyledButton
                                    style={{ textTransform: 'none' }}
                                    variant='contained'
                                    className={isMobile ? formClasses.w100pi : ' '}
                                    onClick={handleButtonClick}
                                  >
                                    {loading ? <CircularProgress size={24} className='circular-progress' /> : 'Odobri oglas'}
                                  </StyledButton>
                                </div>
                                {successModal && (
                                  <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                                    <Alert
                                      className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                      onClose={() => setSuccessModal(false)}
                                      severity='success'
                                    >
                                      Uspešno odobravanje oglasa
                                    </Alert>
                                  </Backdrop>
                                )}
                                {errorModal && (
                                  <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                    <Alert
                                      className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                      onClose={() => setErrorModal(false)}
                                      severity='error'
                                    >
                                      Nema porudzbine sa ovim pozivom na broj
                                    </Alert>
                                  </Backdrop>
                                )}
                              </form>
                            </div>
                          </TabPanel>
                          <TabPanel value={activeTab} index={1}>
                            <NewBlog />
                          </TabPanel>
                          <TabPanel value={activeTab} index={2}>
                            <BlogPostSection />
                          </TabPanel>
                          <TabPanel value={activeTab} index={3}>
                            <SearchAdsAdmin />
                          </TabPanel>
                        </Stack>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!isMobile && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} sx={{ mb: 1 }}>
                      <Tabs
                        orientation='vertical'
                        variant='scrollable'
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label='Vertical tabs'
                        className={classes.verticalTabsContainer}
                      >
                        <Tab className={classes.verticalTabsHeading} label='Odobri oglase' />
                        <Tab label='Dodaj nov blog' />
                        <Tab label='Svi blogovi' />
                        <Tab label='Svi oglasi' />
                      </Tabs>
                    </Grid>
                    <Grid item xs={12} sm={4} md={9} sx={{ mb: 1 }}>
                      <TabPanel value={activeTab} index={0}>
                        <div className={formClasses.bodyItemsHolder}>
                          <form onSubmit={handleButtonClick} className={isMobile ? formClasses.mb24 : ''}>
                            <div className={formClasses.inputContainer}>
                              <Controller
                                name='paymentReference'
                                control={control}
                                rules={{
                                  required: 'Polje je obavezno'
                                }}
                                render={({ field, fieldState }) => {
                                  return (
                                    <StyledInput
                                      variant='outlined'
                                      required={true}
                                      type='text'
                                      label='Unesi poziv na broj'
                                      error={!!fieldState.error}
                                      helperText={fieldState.error?.message}
                                      inputRef={field.ref}
                                      {...field}
                                    />
                                  )
                                }}
                              />
                            </div>
                            <div className={formClasses.inputContainer}>
                              <StyledButton
                                style={{ textTransform: 'none' }}
                                variant='contained'
                                className={isMobile ? formClasses.w100pi : ' '}
                                onClick={handleButtonClick}
                              >
                                {loading ? <CircularProgress size={24} className='circular-progress' /> : 'Odobri oglas'}
                              </StyledButton>
                            </div>
                            {successModal && (
                              <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                                <Alert
                                  className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                  onClose={() => setSuccessModal(false)}
                                  severity='success'
                                >
                                  Uspešno odobravanje oglasa
                                </Alert>
                              </Backdrop>
                            )}
                            {errorModal && (
                              <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                <Alert
                                  className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                  onClose={() => setErrorModal(false)}
                                  severity='error'
                                >
                                  Nema porudzbine sa ovim pozivom na broj
                                </Alert>
                              </Backdrop>
                            )}
                          </form>
                        </div>
                      </TabPanel>
                      <TabPanel value={activeTab} index={1}>
                        <NewBlog />
                      </TabPanel>
                      <TabPanel value={activeTab} index={2}>
                        <BlogPostSection />
                      </TabPanel>
                      <TabPanel value={activeTab} index={3}>
                        <SearchAdsAdmin />
                      </TabPanel>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default AdminPanel
