import { useMediaQuery } from '@material-ui/core'
import { AdDetails, BillingType, City, Country, Currency } from './types.d'

export const useMediaQueries = () => {
  const isMobile = useMediaQuery('(min-width:320px) and (max-width:710px)')
  const isTablet = useMediaQuery('(min-width:711px) and (max-width:1024px)')

  return { isMobile, isTablet }
}

export const getUserId = (userData: any) => userData?.nameid
export const getInitials = (userData: any) =>
  userData?.given_name && userData?.family_name ? `${userData.given_name[0]}${userData.family_name[0]}` : ''
export const getFullName = (userData: any) =>
  userData?.given_name && userData?.family_name ? `${userData.given_name} ${userData.family_name}` : ''
export const countryId: string = '64a46be326b2366cdc68f7eb'
export const defaultSelectedAdType: string = '64b3defd2c7bf8da76ab1b62'
export const defaultSelectedSubscriptionType = '6602f94593cef73e2c2b04c7'
export const searchParamsZlatibor: string = `CountryId=64a46be326b2366cdc68f7eb&PlaceId=65118158d02a1e8e6b418a3d`
export const searchParamsKopaonik: string = `CountryId=64a46be326b2366cdc68f7eb&PlaceId=65118111d02a1e8e6b418a3c`
export const searchParamsCroatia: string = `CountryId=64a46c4b26b2366cdc68f7ed`
export const searchParamsMontenegro: string = `CountryId=64a46c2726b2366cdc68f7ec`
export const searchParamsSerbia: string = `CountryId=64a46be326b2366cdc68f7eb`
export const standardAdNameConst = `Standardni oglas`
export const sponsoredAdNameConst = `Sponzorisani oglas`
export const premiumAdNameConst = `Premium oglas`
export const monthlySubscription = `Mesečna pretplata`
export const sesonalSubscription = `Godišnja pretplata`

export const formatDate = (dateString: string): string => {
  const dateObject = new Date(dateString)
  const day = dateObject.getDate()
  const month = dateObject.toLocaleString('default', { month: 'short' })
  const year = dateObject.getFullYear()

  return `${day} ${month} ${year}`
}

export const formatDateShort = (dateString: string): string => {
  const dateObject = new Date(dateString)
  const day = dateObject.getDate()
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0')
  const year = dateObject.getFullYear()

  return `${month}/${day}/${year}`
}

export const formatApiDate = (dateString: string): string => {
  const dateObject = new Date(dateString)

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const day = days[dateObject.getUTCDay()]
  const month = months[dateObject.getUTCMonth()]
  const year = dateObject.getUTCFullYear()
  const hours = dateObject.getUTCHours().toString().padStart(2, '0')
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0')
  const seconds = dateObject.getUTCSeconds().toString().padStart(2, '0')
  const timeZoneOffset = dateObject.toString().match(/GMT([+-]\d{4})/)?.[1] || ''

  return `${day} ${month} ${dateObject.getUTCDate()} ${year} ${hours}:${minutes}:${seconds} GMT${timeZoneOffset}`
}

export const generateOfferedItemsText = (addDetails: AdDetails): string => {
  const offeredItems: string[] = []

  if (addDetails?.offeredFood) {
    offeredItems.push('Hrana')
  }

  if (addDetails?.offeredAccomodation) {
    offeredItems.push('Smeštaj')
  }

  if (addDetails?.offeredTransport) {
    offeredItems.push('Prevoz')
  }

  return offeredItems.join(', ')
}

export const findCityIdByName = (cityName: any, places: any[]): string | undefined => {
  const selectedCity = places.find((place) => place.city === cityName)
  return selectedCity ? selectedCity.id : undefined
}

export function formatCity(city: string | City): string {
  const cityName = typeof city === 'string' ? city : city.city

  return cityName ? `${cityName}` : ' '
}

export function formatCountry(country: string | Country): string {
  const countryName = typeof country === 'string' ? country : country.countryName

  return countryName ? `${countryName}` : ' '
}

export function formatCurrency(currency: string | Currency): string {
  const currencyName = typeof currency === 'string' ? currency : currency.currencyName

  return currencyName ? `${currencyName}` : ' '
}

export function formatBillingType(billingType: string | BillingType): string {
  const typeOfBilling = typeof billingType === 'string' ? billingType : billingType.typeOfBilling

  return typeOfBilling ? `${typeOfBilling}` : ' '
}

export const handleNavigate = (navigate: any, path: string, id?: string) => {
  const fullPath = id ? `${path}/${id}` : path

  navigate(fullPath)
}

export const navigateWithScroll = (navigate: any, path: string, id?: string) => {
  handleNavigate(navigate, path, id)
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const navigateToSection = (navigate: any, path: string) => {
  const id = path.split('#').pop()
  handleNavigate(navigate, path)
  setTimeout(() => {
    const element = document.getElementById(id as string)
    element?.scrollIntoView({ behavior: 'smooth' })
  }, 0)
}

export const getGreeting = () => {
  const currentHour = new Date().getHours()
  return currentHour >= 5 && currentHour < 12 ? 'Dobro jutro, ' : currentHour >= 12 && currentHour < 18 ? 'Dobar dan, ' : 'Dobro veče, '
}

export const validateValue = (value: string | number) => {
  const minValue = 1
  const maxValue = 12
  const numericValue = parseInt(value as string, 10)
  if (isNaN(numericValue) || numericValue < minValue || numericValue > maxValue) {
    return 'Unesite broj između ' + minValue + ' i ' + maxValue
  }
  return true
}

export default validateValue
