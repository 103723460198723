import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid/Grid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MobileHeader from '../mobileHeader/mobileHeader'
import useFormStyles from '../styledComponents/globalFormStying.module'
import { useMediaQueries } from '../../utils/global-utils'
import { useContext, useEffect, useState } from 'react'
import EmployeeApi from '../../services/employeeApi'
import { LookingForAJobResponse } from '../../utils/types.d'
import useStyles from '../wwwApp/pricingDialog/pricingDialog.module'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../styledComponents/spinningIcon/spinningIcon'
import UserContext from '../../context/UserContext'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LandingFooter from './landingPageBody/landingFooter'

function EmployeeManual() {
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const classes = useStyles()
  const navigate = useNavigate()
  const { setAvailableJobsId } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [fetchJobOptions, setFetchJobOptions] = useState<LookingForAJobResponse>()

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response: any = await EmployeeApi.GetJobTypeOptions({})
        setFetchJobOptions(response.data)
        const { status } = response
        if (status === 200) {
          setLoading(false)
        }
      } catch {
        setLoading(false)
      }
    }

    fetchDetails()
  }, [])

  const jobOptions = fetchJobOptions?.lookingForAJobTypes

  const handleNavigateWithSelectedId = (id: string) => {
    setAvailableJobsId(id)
    navigate(`/register/employee`)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const getCardClass = (price: number) =>
    ({
      0: classes.standardCard,
      30: classes.sponsoredCard,
      50: classes.premiumCard
    })[price] || ''

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <MobileHeader />
            </Grid>
          </Grid>
        </Grid>
        <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
          <div className={formClasses.companyNameHeadline}>
            <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Kako do posla?
          </div>
        </div>
      </Grid>
      <Grid container>
        <div className={formClasses.bodyItemsHolder}>
          {loading ? (
            <Backdrop open={true}>
              <div className={formClasses.circularProgressContainer}>
                <StyledSpinningIcon />
              </div>
            </Backdrop>
          ) : (
            <>
              {jobOptions?.map((details) => (
                <Grid container justifyContent='center' className={formClasses.mtb12}>
                  <Grid item xs={10} sm={10} md={4}>
                    <Card
                      className={`${classes.regularCard} ${getCardClass(details.price)}`}
                      onClick={() => handleNavigateWithSelectedId(details.id)}
                      key={details.id}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.headlineHolder}>
                          <span className={`${classes.regularAddHeadline}`}>{details.name}</span>
                        </div>
                        <span className={classes.explanationTextSmall}>{details.description}</span>
                        <div className={`${classes.pricingContainer} ${formClasses.mt24}`}>
                          {details.price === 0 ? (
                            <span className={formClasses.fwbi}>Besplatna opcija</span>
                          ) : (
                            <span className={formClasses.fwbi}>Cena: {details.price}€</span>
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </>
  )
}

export default EmployeeManual
