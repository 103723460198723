import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  actionCards: {
    backgroundColor: '#EBEFFF !important',
    borderRadius: '12px !important',
    marginLeft: '12px',
    marginRight: '12px',
    cursor: 'pointer !important'
  },
  actionCardsSelected: {
    backgroundColor: '#0033FF !important',
    borderRadius: '12px !important',
    marginLeft: '12px',
    marginRight: '12px',
    cursor: 'pointer'
  },
  actionCardsUpper: {
    fontWeight: 'bold',
    fontSize: '30px',
    color: '#0033FF'
  },
  verticalTabsContainer: {
    borderRight: '1px solid #636366',
    alignItems: 'flex-end',
    fontSize: '16px',
    '& .MuiTabs-scroller': {
      paddingRight: '24px'
    },
    '& .MuiTabs-flexContainer': {
      alignItems: 'baseline'
    }
  },
  horizontalTabsContainer: {
    fontSize: '12px',
    '& .MuiTabs-scroller': {
      paddingRight: '12px',
      paddingLeft: '12px'
    },
    '& .Mui-selected': {
      fontSize: '12px',
      paddingRight: '12px',
      paddingLeft: '12px'
    },
    '& .MuiButtonBase-root': {
      fontSize: '12px',
      paddingRight: '12px',
      paddingLeft: '12px'
    }
  }
}))

export default useStyles
