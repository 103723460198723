import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  styledInput: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '-10px',
      right: '0',
      transform: 'translateY(-100%)',
      fontSize: '0.65rem !important'
    },
    //width: '320px',
    borderRadius: '6px',
    '& .MuiFormLabel-root': {
      fontWeight: 'bold',
      zIndex: '0 !important'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -14px) scale(0.75) !important'
    },
    '& .MuiInputBase-root': {
      color: '#1C1C1E',
      fontWeight: 'bold'
    },
    '& .MuiInputBase-input': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 'bold'
    }
  }
}))

export default useStyles
