import facebookIcon from './facebook.png'
import instagramIcon from './instagram.png'
import linkedinIcon from './linkedin.png'
import serbiaFlagIcon from './serbia_flag.svg'
import viberIcon from './viberWhite.png'

const SOCIAL_MEDIA_ICONS = {
  facebook: facebookIcon,
  instagram: instagramIcon,
  linkedIn: linkedinIcon,
  serbiaFlagIcon: serbiaFlagIcon,
  viberIcon: viberIcon
}

export default SOCIAL_MEDIA_ICONS
