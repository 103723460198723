import Grid from '@mui/material/Grid'
import BODY_ITEMS from '../../../assets/bodyItems/index'
import useStyles from './rolePicker.moduje'
import { Link, Typography } from '@material-ui/core'
import StyledButton from '../../styledComponents/styledButton.module'
import { StyledCard } from '../../styledComponents/styledCard/styledCard'
import { handleNavigate, useMediaQueries } from '../../../utils/global-utils'
import MobileHeader from '../../mobileHeader/mobileHeader'
import StyledLayoutItem from '../../layoutItem/layoutItem'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useState } from 'react'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import employerBenefits from '../../../assets/media/poslodavac.png'
import candidateBenefits from '../../../assets/media/radnik.png'
import LandingFooter from '../../wwwLandingPage/landingPageBody/landingFooter'

function RolePicker() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile, isTablet } = useMediaQueries()
  const { control, handleSubmit } = useForm()
  const navigate = useNavigate()
  const [selectedCard, setSelectedCard] = useState(null)

  const handleSelectedCard = (fieldName: any) => {
    setSelectedCard(fieldName)
  }

  const onSubmit = async () => {
    if (selectedCard === 'employee') {
      handleNavigate(navigate, `/employee-manual`)
    } else {
      handleNavigate(navigate, `/register/${selectedCard}`)
    }
  }

  return (
    <>
      <section>
        <Grid container className={formClasses.container}>
          {isMobile || isTablet ? <MobileHeader /> : <StyledLayoutItem alt='lifeguard' imageSrc={`${BODY_ITEMS.FARM}`} children />}
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.rolePickerContainer}>
              <div className={classes.rolePickerText}>
                <Typography className={classes.headline}>Napravi besplatan nalog!</Typography>
                <Typography className={classes.headlineSmall}>Odaberi tip profila shodno tvojim potrebama</Typography>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name='employee'
                  control={control}
                  render={({ field }) => {
                    const currentCard = selectedCard === field.name ? true : false
                    return (
                      <div className={classes.styledCardContainerUpper}>
                        <StyledCard
                          {...field}
                          className={currentCard ? classes.styledCardBodySelected : classes.styledCardBody}
                          innerRef={field.ref}
                          onClick={() => handleSelectedCard(field.name)}
                          cardTextHeadline='Tražim sezonski posao'
                          cardImage={candidateBenefits}
                          cardTextDescription='Ova opcija dozvoliće Vam da kreirate svoj profil, 
                                                    imate pregled oglasa i mogućnost da se prijavite na iste'
                          isSelected={selectedCard === field.name}
                        ></StyledCard>
                      </div>
                    )
                  }}
                />
                <Controller
                  name='employer'
                  control={control}
                  render={({ field }) => {
                    const currentCard = selectedCard === field.name ? true : false
                    return (
                      <div className={classes.styledCardContainer}>
                        <StyledCard
                          {...field}
                          className={currentCard ? classes.styledCardBodySelected : classes.styledCardBody}
                          innerRef={field.ref}
                          onClick={() => handleSelectedCard(field.name)}
                          cardTextHeadline='Nudim sezonski posao'
                          cardImage={employerBenefits}
                          cardTextDescription='Ova opcija dozvoliće Vam da kreirate oglase za posao, 
                                                        imate pregled potencijalnih kandidata i kreirate profil Vaše organizacije'
                          isSelected={selectedCard === field.name}
                        ></StyledCard>
                      </div>
                    )
                  }}
                />
                <div>
                  <StyledButton
                    className={!selectedCard ? formClasses.buttonDisabled : ''}
                    style={{ textTransform: 'none' }}
                    type='submit'
                    variant='contained'
                    disabled={!selectedCard}
                  >
                    {!selectedCard ? 'Izaberi tip profila' : 'Nastavi'}
                  </StyledButton>
                  <div className={formClasses.inputContainerTop}>
                    <StyledButton style={{ textTransform: 'none' }} variant='text' onClick={() => navigate(-1)}>
                      Vrati se nazad
                    </StyledButton>
                  </div>
                  <div className={formClasses.manageConsentContainerCenter}>
                    <Typography className={formClasses.manageConsent}>
                      Registrovan/na si?{' '}
                      <Link onClick={() => handleNavigate(navigate, `/login`)} className={formClasses.manageConsentLink}>
                        Idi na prijavu
                      </Link>
                    </Typography>
                  </div>
                </div>
              </form>
            </div>
          </Grid>
          {isMobile && <LandingFooter />}
        </Grid>
      </section>
    </>
  )
}

export default RolePicker
