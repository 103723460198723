import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  logoLanding: {
    width: '120px',
    height: '18px',
    paddingTop: '21px',
    paddingBottom: '21px'
  },
  menuItemsHolder: {
    marginLeft: '24px'
  },
  drawerItemsContainer: {
    marginLeft: '16px',
    marginTop: '12px'
  },
  drawerNavigations: {
    fontWeight: 'bold !important',
    color: '#0033FF'
  }
}))

export default useStyles
