import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  layoutItemContainer: {
    width: '100%',
    height: '100%'
  },
  layoutItem: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    backgroundSize: 'contain',
    objectFit: 'cover'
  },
  textContainer: {
    position: 'absolute',
    top: '84px',
    left: '95px'
  },
  headlineLarge: {
    color: '#FFFFFF !important',
    fontSize: '48px !important',
    fontWeight: 'bold !important',
    lineHeight: '54px !important'
  },
  logoContainer: {
    position: 'absolute',
    bottom: '96px',
    left: '95px'
  },
  logo: {
    width: '160px',
    height: '24px'
  }
}))

export default useStyles
