import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  appBarMobile: {
    backgroundColor: '#0033FF',
    height: '60px',
    paddingLeft: '24px',
    zIndex: '2',
    position: 'fixed'
  },
  toolBarMobile: {
    paddingLeft: '0px'
  },
  logoContainer: {
    cursor: 'pointer'
  },
  logoMobile: {
    width: '120px',
    height: '18px',
    paddingTop: '21px',
    paddingBottom: '21px'
  }
}))

export default useStyles
