import Grid from '@mui/material/Grid/Grid'
import FunctionalHeader from '../../functionalHeader/functionalHeader'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useMediaQueries } from '../../../utils/global-utils'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import { useContext, useEffect, useState } from 'react'
import AddApi from '../../../services/addApi'
import AdsCard from '../adsCard/adsCard'
import UserContext from '../../../context/UserContext'

function ApliedAds() {
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const [loading, setLoading] = useState(true)
  const { accessToken } = useContext(UserContext)
  const [fetchEmployeeAppliedAds, setfetchEmployeeAppliedAds] = useState<string[]>([])

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (accessToken) {
          const employerAppliedAds = await AddApi.getMyAppliedAds(accessToken)
          setfetchEmployeeAppliedAds(employerAppliedAds.data)

          setLoading(false)
        }
      } catch {
        setLoading(false)
      }
    }

    if (accessToken) {
      setLoading(true)
      fetchDetails()
    }
  }, [accessToken])

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <FunctionalHeader />
          </Grid>
        </Grid>
        <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
          <div className={formClasses.bodyItemsHolder}>
            {loading ? (
              <Backdrop open={true}>
                <div className={formClasses.circularProgressContainer}>
                  <StyledSpinningIcon />
                </div>
              </Backdrop>
            ) : (
              <>{fetchEmployeeAppliedAds?.map((addDetails: any, index: number) => <AdsCard key={index} addDetails={addDetails} />)}</>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ApliedAds
