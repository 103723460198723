import CR from './CRO.png'
import ME from './MNE.png'
import NMK from './nmk.png'
import RS from './RS.png'
import SLO from './slo.png'
import BIH from './bih.png'

const COUNTRY_FLAG_ICONS = {
  CR,
  ME,
  NMK,
  RS,
  SLO,
  BIH
}

export default COUNTRY_FLAG_ICONS
