import { useEffect, useState } from 'react'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useAuthContext } from '../../../context/UserContext'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid/Grid'
import FunctionalHeader from '../../functionalHeader/functionalHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { EmployerAddResponse } from '../../../utils/types.d'
import { getUserId, useMediaQueries } from '../../../utils/global-utils'
import AddApi from '../../../services/addApi'
import AdsCard from '../adsCard/adsCard'
import { useForm } from 'react-hook-form'
import LandingFooter from '../../wwwLandingPage/landingPageBody/landingFooter'

function EmployerReadOnlyProfile() {
  const formClasses = useFormStyles()
  const { userData, accessToken } = useAuthContext()
  const { userId } = useParams()
  const employeeId = getUserId(userData)
  const employerId = userId
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  const [fetchEmployerAdds, setFetchEmployerAdds] = useState<EmployerAddResponse[]>([])
  const [adsLoading, setAdsLoading] = useState(false)

  useEffect(() => {
    const fetchAds = async () => {
      try {
        if (accessToken) {
          setAdsLoading(true)
          const employerAdsResponse = await AddApi.GetAllEmployerAds(accessToken, employerId)
          setFetchEmployerAdds(employerAdsResponse.data)
          setAdsLoading(false)
        }
      } catch {
        //
      }
    }

    if (accessToken) {
      fetchAds()
    }
  }, [accessToken, employerId])

  const { setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      employeeId: employeeId,
      employerId: employerId,
      rating: 3,
      comment: ''
    }
  })

  setValue('employeeId', employeeId)
  setValue('employerId', employerId)

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <FunctionalHeader />
            </Grid>
          </Grid>
          <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
            <div className={formClasses.companyNameHeadline}>
              <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Oglasi poslodavca
            </div>
          </div>
          <Grid
            container
            className={`${isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}
          >
            {fetchEmployerAdds.length === 0 ? (
              <>
                <div className={formClasses.companyNameHeadline}>Bez oglasa</div>
              </>
            ) : (
              <>
                {adsLoading ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} className={formClasses.toggleButtonsContainer}>
                      <Stack spacing={1}>
                        <Skeleton variant='rectangular' width={'100%'} height={120} />
                        <Skeleton variant='rectangular' width={'100%'} height={120} />
                        <Skeleton variant='rectangular' width={'100%'} height={120} />
                        <Skeleton variant='rectangular' width={'100%'} height={120} />
                        <Skeleton variant='rectangular' width={'100%'} height={120} />
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} sm={12} md={12} className={formClasses.toggleButtonsContainer}>
                    {fetchEmployerAdds?.map((addDetails: any, index: number) => <AdsCard key={index} addDetails={addDetails} />)}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </>
  )
}

export default EmployerReadOnlyProfile
