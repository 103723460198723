import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  addDescriptionText: {
    fontSize: '14px',
    color: '#636366'
  },
  moreInfoDetails: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  landingCardsBody: {
    cursor: 'pointer',
    transition: 'transform .6s',
    '&:hover, &:focus, &:active': {
      transform: 'scale(1.01)'
    }
  },
  employerCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    margin: '12px',
    padding: '24px'
  }
}))

export default useStyles
