import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  blogCardBody: {
    height: '332px'
  },
  BlogCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  imageContainer: {
    marginTop: '20px',
    marginBottom: '12px'
  },
  blogPostImage: {
    width: '186px',
    height: '186px',
    objectFit: 'cover',
    '&:focus': {
      outline: 'none !important'
    }
  },
  blogTitle: {
    color: '#636366',
    fontSize: '18px',
    lineHeight: '16px',
    fontWeight: 'bold',
    paddingBottom: '12px'
  },
  moreInfo: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: '12px',
    paddingBottom: '12px',
    color: '#636366'
  }
}))

export default useStyles
