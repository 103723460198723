import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  uploaderContainer: {
    //display: 'flex'
  },
  uploaderThumbnail: {
    paddingTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline'
  },
  uploaderPaper: {
    maxWidth: '200px',
    maxHeight: '200px',
    padding: '16px',
    marginTop: '12px',
    overflow: 'hidden'
  },
  uploaderImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}))

export default useStyles
