import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  cookiesConsentDialog: {
    '& .MuiPaper-root': {
      width: '90% !important',
      maxWidth: '90% !important',
      margin: '12px !important',
      position: 'absolute !important',
      bottom: '0 !important'
    }
  },
  contentContainer: {
    width: '80%'
  },
  contentContainerMobile: {
    paddingLeft: '24px',
    paddingRight: '24px',
    marginBottom: '48px'
  },
  contentContainerTablet: {
    paddingBottom: '24px',
    paddingLeft: '0',
    paddingRight: '0'
  },
  mobileScreensContainer: {
    width: '598px',
    height: '624px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  mobileScreensContainerSmall: {
    height: '336px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  teamMembersContainerSmall: {
    width: '100%'
  },
  mobileScreens: {
    width: '100%',
    height: '100%'
  },
  teamMates: {
    width: '100%'
  },
  newsletterForm: {
    paddingRight: '124px'
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '990px'
  },
  containerMobile: {
    height: '708px'
  },
  carouselContainer: {
    width: '100%',
    height: '708px'
  },
  middleBodyContainer: {
    width: '100%',
    height: '808px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  middleBodyContainerContact: {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  carouselContainerMobile: {
    width: '100%',
    height: '708px'
  },
  carouselImages: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  carouselImagesMobile: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '1',
    width: '100%',
    height: '100%',
    display: 'block',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
    paddingTop: '60px'
  },
  overlayTablet: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '1',
    width: '100%',
    height: '100%',
    display: 'block',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
    paddingLeft: '60px',
    paddingTop: '180px'
  },
  overlayMobile: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '1',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start'
  },
  gridContainer: {
    marginTop: '96px',
    marginBottom: '48px'
  },
  gridContainerMobile: {
    marginTop: '84px',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  textContainer: {
    marginTop: '36px',
    marginBottom: '60px'
  },
  textContainerMobilePrimary: {
    marginTop: '72px',
    marginBottom: '24px',
    width: '80%'
  },
  buttonsContainer: {
    marginLeft: '12px !important'
  },
  textContainerMobileSecondary: {
    marginTop: '24px',
    marginBottom: '176px',
    width: '80%'
  },
  primaryTextContainer: {
    marginBottom: '24px',
    marginLeft: '12px'
  },
  landingCardsBody: {
    padding: '38px',
    cursor: 'pointer',
    height: '112px',
    color: '#1976d2',
    '& span': {
      color: '#636366'
    },
    '&:hover, &:focus': {
      transform: 'scale(1.2)',
      transition: 'all .6s'
    }
  },
  iconTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0'
  },
  FAQTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    color: '#1976d2',
    width: '80%',
    margin: '12px',
    padding: '24px',
    cursor: 'pointer'
  },
  contactUsSectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#1976d2',
    margin: '12px',
    padding: '24px'
  },
  landingCadrsHolder: {
    fontSize: '24px',
    fontWeight: 'bold'
  },
  primaryText: {
    color: '#FFFFFF',
    fontSize: '60px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '72px'
  },
  primaryTextMobile: {
    color: '#FFFFFF',
    fontSize: '30px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '42px'
  },
  secondaryText: {
    color: '#FFFFFF',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '24px'
  },
  secondaryTextMobile: {
    color: '#FFFFFF',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '24px'
  },

  newsletterButton: {
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    width: '266px',
    color: '#0033FF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0033FF',
      color: 'white'
    }
  },
  mobileButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '24px',
    paddingRight: '24px'
  },
  mobileNewsletterButton: {
    width: '100%',
    height: '60px',
    borderRadius: '30px',
    backgroundColor: 'white',
    color: '#0033FF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0033FF',
      color: 'white'
    }
  },
  newsletterButonContainer: {
    marginTop: '67px'
  },
  applyContainer: {
    float: 'right'
  },
  newsletterButtonApply: {
    float: 'right',
    borderRadius: '30px',
    width: '266px',
    backgroundColor: '#0033FF',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#0033FF'
    }
  },
  newsletterButtonApplyMobile: {
    width: '100%',
    height: '60px',
    borderRadius: '30px',
    backgroundColor: '#0033FF',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0033FF',
      color: '#FFFFFF'
    }
  },
  indicator: {
    padding: 0,
    color: '#FFFFFF',
    opacity: '0.5 !important',
    '&:active': {
      opacity: '1 !important'
    }
  },
  middleSection: {
    paddingTop: '180px',
    paddingLeft: '120px',
    paddingBottom: '256px'
  },
  middleSectionTablet: {
    paddingLeft: '60px'
  },
  middleSectionMobile: {
    paddingTop: '96px'
  },
  middleSectionMobileTeam: {
    paddingTop: '96px'
  },
  headlineBottomLarge: {
    marginBottom: '100px'
  },
  headlineBottomSmall: {
    paddingBottom: '40px'
  },
  middleHeadline: {
    color: '#1C1C1E',
    fontSize: '48px',
    fontWeight: 'bold'
  },
  middleHeadlineMobile: {
    color: '#1C1C1E',
    fontSize: '24px',
    fontWeight: 'bold'
  },
  middleText: {
    fontSize: '16px',
    color: '#636366',
    lineHeight: '24px',
    fontWeight: '200'
  },
  item: {
    opacity: '0',
    transform: 'scale(0.8)',
    transition: 'transform 1.2s ease-out, opacity 1.2s ease-out'
  },
  show: {
    opacity: '1',
    transform: 'scale(1)'
  },
  delay1: {
    transitionDelay: '0.8s'
  },
  delay2: {
    transitionDelay: '1.2s'
  },
  delay3: {
    transitionDelay: '1.4s'
  },
  delay4: {
    transitionDelay: '1.6s'
  },
  delay5: {
    transitionDelay: '1.8s'
  },
  benefitsContainer: {
    marginTop: '96px',
    marginBottom: '96px'
  },
  benefitsPlaceholders: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '48px',
    marginBottom: '72px'
  },
  benefitsHeadline: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '38px',
    lineHeight: '38px',
    fontWeight: '900'
  },
  landingHeading: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#fff',
    fontSize: '42px',
    lineHeight: '38px',
    fontWeight: '900'
  },
  landingHeadingMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#fff',
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '900'
  },
  benefitsBanners: {},
  benefitsList: {
    fontSize: '24px',
    fontWeight: 'bold',
    paddingTop: '12px',
    paddingBottom: '12px',
    color: '#636366'
  },
  benefitsListSmall: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '8px',
    paddingBottom: '8px',
    color: '#636366'
  },
  benefitsListMiddle: {
    fontSize: '18px',
    fontWeight: 'bold',
    paddingTop: '12px',
    paddingBottom: '12px',
    textTransform: 'uppercase',
    color: '#636366'
  },
  benefitsListMiddleSmall: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '12px',
    paddingBottom: '18px',
    paddingLeft: '12px',
    paddingRight: '12px',
    color: '#636366'
  },
  newsletterInputs: {
    width: '100%',
    height: '60px',
    borderRadius: '6px',
    marginBottom: '5px'
  },
  newsletterInputsMobile: {
    width: '100%',
    height: '60px',
    borderRadius: '6px',
    marginBottom: '15px'
  },
  inputsContainer: {
    paddingRight: '124px'
  },
  textField: {
    marginBottom: '56px'
  },
  inputsDivider: {
    marginBottom: '56px'
  },
  consentContainer: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'flex-start'
  },
  consentCheckbox: {
    padding: '0px'
  },
  manageConsent: {
    color: '#707070',
    fontSize: '14px',
    lineHeight: '17px',
    marginLeft: '12px'
  },
  manageConsentError: {
    color: '#FF221A',
    fontSize: '14px',
    lineHeight: '17px',
    marginLeft: '12px'
  },
  footerSection: {
    backgroundColor: '#1976d2',
    height: '260px',
    color: '#FFFFFF'
  },
  footerSectionMobile: {
    width: '100%',
    backgroundColor: '#1976d2',
    color: '#FFFFFF'
  },
  footerItemsContainer: {
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingTop: '60px'
  },
  footerItemsContainerMobile: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '60px'
  },
  footerText: {
    fontSize: '16px',
    color: '#FFFFFF'
  },
  copyrightContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  },
  manageConsentLink: {
    cursor: 'pointer'
  },
  mobileDialog: {
    bottom: '30%'
  },
  tabletDialog: {
    bottom: '20%'
  },
  mobileDialogBody: {
    maxHeight: '400px'
  },
  mediaIcons: {
    width: '48px',
    height: '48px',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  carouselCardsContainer: {
    '& .react-multiple-carousel__arrow': {
      zIndex: 'auto !important',
      '&:before': {
        zIndex: 'auto !important'
      }
    }
  },
  // UTILS
  aiBasei: {
    alignItems: 'baseline !important'
  },
  fcbi: {
    color: '#000 !important'
  },
  tac: {
    textAlign: 'center'
  },
  p32: {
    padding: '32px'
  },
  pb0i: {
    paddingBottom: '0px !important'
  },
  pl0i: {
    paddingLeft: '0px !important'
  },
  pl6: {
    paddingLeft: '6px'
  },
  pr6: {
    paddingRight: '6px'
  },
  plr12: {
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  mr6: {
    marginRight: '6px'
  },
  ml6: {
    marginLeft: '6px'
  },
  mtb12: {
    marginTop: '12px',
    marginBottom: '12px'
  },
  mtb12i: {
    marginTop: '12px !important',
    marginBottom: '12px !important'
  },
  mtb24: {
    marginTop: '24px',
    marginBottom: '24px'
  },
  mtb36: {
    marginTop: '36px',
    marginBottom: '36px'
  },
  m24: {
    margin: '24px'
  },
  mtb60: {
    marginTop: '60px',
    marginBottom: '60px'
  },
  ptb60: {
    paddingTop: '60px',
    paddingBottom: '60px'
  },
  ptb36: {
    paddingTop: '36px',
    paddingBottom: '36px'
  },
  plr36: {
    paddingLeft: '36px',
    paddingRight: '36px'
  },
  mtb120: {
    marginTop: '120px',
    marginBottom: '120px'
  },
  ptb120: {
    paddingTop: '120px',
    paddingBottom: '120px'
  },
  mtb240: {
    marginTop: '240px',
    marginBottom: '240px'
  },
  mlr36: {
    marginLeft: '36px',
    marginRight: '36px'
  },
  ptb12: {
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  wh36: {
    width: '36px',
    height: '36px'
  },
  wh42: {
    width: '42px',
    height: '42px'
  },
  colorRed: {
    color: '#ff5757'
  },
  colorGreen: {
    color: '#00bf63'
  },
  colorPurple: {
    color: '#8c52ff'
  },
  bodyBackground: {
    backgroundColor: '#f1f1f0'
  },
  h808: {
    height: '808px'
  }
}))

export default useStyles
