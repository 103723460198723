import { useNavigate, useParams } from 'react-router-dom'
import {
  formatBillingType,
  formatCity,
  formatCountry,
  formatCurrency,
  formatDate,
  generateOfferedItemsText,
  getUserId,
  handleNavigate,
  useMediaQueries
} from '../../../utils/global-utils'
import TextField from '@mui/material/TextField'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../../context/UserContext'
import FunctionalHeader from '../../functionalHeader/functionalHeader'
import useStyles from '../adProfilePreview/adProfilePreview.module'
import Grid from '@mui/material/Grid/Grid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined'
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined'
import Divider from '@mui/material/Divider'
import StyledButton from '../../styledComponents/styledButton.module'
import { AdDetails, SelectedAddResponse } from '../../../utils/types.d'
import FavoritesApi from '../../../services/favoritesApi'
import AddApi from '../../../services/addApi'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import StyledApplyingModal from '../../styledComponents/styledApplyingModal/styledApplyingModal'
import { Controller, useForm } from 'react-hook-form'
import Alert from '@mui/material/Alert'
import LandingFooter from '../../wwwLandingPage/landingPageBody/landingFooter'

function AdProfile() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  const { addId } = useParams()
  const { userData, accessToken } = useAuthContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fetchSelectedAdd, setfFetchSelectedAdd] = useState<SelectedAddResponse[]>([])
  const [errorModal, setErrorModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorApplyForAd, setErrorAplyForAd] = useState(false)
  const [successfulApply, setSuccessfulApply] = useState(false)
  const [errorWithdrawApplication, setErrorWithDrawApplication] = useState(false)
  const [successfulWithdrawal, setSuccessfulWithdrawal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (accessToken) {
          const selectedAddResponse: any = await AddApi.GetSelectedAd(accessToken, addId)
          setfFetchSelectedAdd([selectedAddResponse.data])
          setLoading(false)
        }
      } catch {
        setfFetchSelectedAdd([])
        setLoading(false)
      }
    }

    if (accessToken) {
      fetchDetails()
    }
  }, [accessToken, addId])

  const userId = getUserId(userData)
  const { control, handleSubmit, trigger, formState } = useForm()

  const handleAddFavoriteAd = async () => {
    if (!accessToken) {
      return
    }
    try {
      const dataObject = {
        employeeId: userId,
        adId: addId
      }
      const response: any = await FavoritesApi.AddFavoriteAd(dataObject, accessToken)
      const { status } = response
      if (status === 200) {
        setSuccessModal(true)
      }
    } catch {
      setErrorModal(true)
    }
  }

  const handleWithdrawApplication = async () => {
    try {
      const response: any = await AddApi.WithdrawApplication(accessToken, addId, userId)
      const { status } = response
      if (status === 200) {
        setSuccessfulWithdrawal(true)
      }
    } catch {
      setErrorWithDrawApplication(true)
    }
  }

  const handleApply = async () => {
    setIsModalOpen(true)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  const onSubmit = async (formData: any) => {
    try {
      const dataObject = {
        employeeId: userId,
        adId: addId,
        message: formData?.message
      }

      const response: any = await AddApi.AddAplication(dataObject, accessToken)
      const { status } = response
      if (status === 200) {
        setSuccessfulApply(true)
      }
    } catch {
      setErrorAplyForAd(true)
    }
  }

  const handleButtonClick = async () => {
    try {
      setIsModalOpen(false)
      await trigger()
      if (Object.keys(formState.errors).length === 0) {
        await handleSubmit(onSubmit)()
      }
    } catch {
      //
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <FunctionalHeader />
          </Grid>
        </Grid>
        <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
          <div className={formClasses.companyNameHeadline}>
            <ArrowBackIcon className={formClasses.navigateBack} onClick={() => navigate(-1)} /> Profil oglasa
          </div>
        </div>
        <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
          <div className={formClasses.bodyItemsHolder}>
            {loading ? (
              <Backdrop open={true}>
                <div className={formClasses.circularProgressContainer}>
                  <StyledSpinningIcon />
                </div>
              </Backdrop>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  {fetchSelectedAdd.map((selectedAdDetails, index) => (
                    <>
                      <Grid container key={index}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={`${isMobile ? classes.adHeadlineContainerMobile : classes.adHeadlineContainer}`}>
                            <span>{selectedAdDetails?.adTitle}</span>
                          </div>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={classes.adHeadlineDetails}>
                            <DescriptionOutlinedIcon />
                            <span className={classes.adDetailsText}>OPIS OGLASA</span>
                          </div>
                          <div className={classes.adHeadlineDetailsDesc}>
                            <span>{selectedAdDetails?.adDescription}</span>
                          </div>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.adHeadlineDetails}>
                              <InsertChartOutlinedOutlinedIcon />
                              <span className={classes.adDetailsText}>DETALJI OGLASA</span>
                            </div>
                            <div
                              className={classes.adHeadlineDetails}
                              onClick={() => handleNavigate(navigate, `/employer-detailed/${fetchSelectedAdd[0]?.employerId}`)}
                            >
                              <FactoryOutlinedIcon />
                              <span className={`${classes.adDetailsText} ${formClasses.fcolorLInki}`}>
                                {selectedAdDetails?.companyName}
                              </span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <FactoryOutlinedIcon />
                              <span className={classes.adDetailsText}>
                                {selectedAdDetails?.industries.map((industry) => industry?.industryName).join(', ')}
                              </span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <PublicOutlinedIcon />
                              <span className={classes.adDetailsText}>
                                {formatCountry(selectedAdDetails?.country?.countryName as string)}
                              </span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <LocationCityOutlinedIcon />
                              <span className={classes.adDetailsText}>{formatCity(selectedAdDetails?.city?.city as string)}</span>
                            </div>
                            <div className={classes.adHeadlineDetails}>
                              <CalendarMonthOutlinedIcon />
                              <span className={classes.adDetailsText}>
                                {formatDate(selectedAdDetails?.workingStartDate as string)} /{' '}
                                {selectedAdDetails?.workingEndDate === null ? '_' : formatDate(selectedAdDetails?.workingEndDate as string)}
                              </span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            {selectedAdDetails?.billingType?.typeOfBilling && (
                              <div className={classes.adHeadlineDetails}>
                                <WorkOutlineOutlinedIcon />
                                <span className={classes.adDetailsText}>
                                  {formatBillingType(selectedAdDetails?.billingType?.typeOfBilling as string)}
                                </span>
                              </div>
                            )}
                            {selectedAdDetails?.salaryValue && selectedAdDetails?.currency?.currencyName ? (
                              <>
                                <div className={classes.adHeadlineDetails}>
                                  <EuroSymbolOutlinedIcon />
                                  <span className={classes.adDetailsText}>
                                    {selectedAdDetails?.salaryValue} {formatCurrency(selectedAdDetails?.currency?.currencyName as string)}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={classes.adHeadlineDetails}>
                                  <EuroSymbolOutlinedIcon />
                                  <span className={classes.adDetailsText}>Isplata po dogovoru</span>
                                </div>
                              </>
                            )}
                            <div className={classes.adHeadlineDetails}>
                              <WatchLaterOutlinedIcon />
                              <span className={classes.adDetailsText}>
                                {generateOfferedItemsText(selectedAdDetails as unknown as AdDetails) || 'Bez benefita'}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Grid>
                <div className={formClasses.inputContainerTop}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4}>
                      <form onSubmit={handleButtonClick}>
                        <div className={formClasses.mb12}>
                          <StyledButton
                            style={{ textTransform: 'none' }}
                            className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                            variant='contained'
                            onClick={handleApply}
                            disabled={fetchSelectedAdd[0]?.userAppliedToTheAd || !fetchSelectedAdd[0]?.isOpenForApplications}
                          >
                            {fetchSelectedAdd[0]?.userAppliedToTheAd ? 'Prijava poslata' : 'Prijavi se'}
                          </StyledButton>
                          <StyledApplyingModal
                            open={isModalOpen}
                            onClose={handleModalCancel}
                            onConfirm={handleButtonClick}
                            titleText='Prijava na oglas'
                            bodyText={`Ovim putem prijavićete se na oglas "${fetchSelectedAdd[0]?.adTitle}". 
                                                    Preporučujemo Vam da ostavite kratku poruku poslodavcu što će potencijalno povećati vaše šanse da vas poslodavac izabere.`}
                            inputText={`Poruka poslodavcu (opciono)`}
                            confirmButtonText='Prijavi se'
                          >
                            <Controller
                              name='message'
                              control={control}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    className={formClasses.applyTextField}
                                    multiline={true}
                                    maxRows={4}
                                    inputRef={field.ref}
                                  />
                                )
                              }}
                            />
                          </StyledApplyingModal>
                          {successfulApply && (
                            <Backdrop className={formClasses.zIndexHighest} open={successfulApply}>
                              <Alert
                                className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                onClose={() => setSuccessfulApply(false)}
                                severity='success'
                              >
                                Uspešna prijava
                              </Alert>
                            </Backdrop>
                          )}
                          {errorApplyForAd && (
                            <Backdrop className={formClasses.zIndexHighest} open={errorApplyForAd}>
                              <Alert
                                className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                onClose={() => setErrorAplyForAd(false)}
                                severity='error'
                              >
                                Prijava je već poslata
                              </Alert>
                            </Backdrop>
                          )}
                        </div>
                      </form>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <div className={formClasses.mb12}>
                        <StyledButton
                          style={{ textTransform: 'none' }}
                          className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                          disabled={fetchSelectedAdd[0]?.isFavorite}
                          variant='text'
                          onClick={() => handleAddFavoriteAd()}
                        >
                          {fetchSelectedAdd[0]?.isFavorite === true ? 'Omiljen oglas' : 'Dodaj u omiljene'}
                        </StyledButton>
                        {successModal && (
                          <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                            <Alert
                              className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                              onClose={() => setSuccessModal(false)}
                              severity='success'
                            >
                              Uspešno dodavanje oglasa u omiljene
                            </Alert>
                          </Backdrop>
                        )}
                        {errorModal && (
                          <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                            <Alert
                              className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                              onClose={() => setErrorModal(false)}
                              severity='error'
                            >
                              Oglas je već u omiljenima
                            </Alert>
                          </Backdrop>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {fetchSelectedAdd[0]?.userAppliedToTheAd && (
                        <>
                          <div>
                            <StyledButton
                              style={{ textTransform: 'none' }}
                              className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                              variant='outlined'
                              onClick={() => handleWithdrawApplication()}
                            >
                              Povuci prijavu
                            </StyledButton>
                          </div>
                          {successfulWithdrawal && (
                            <Backdrop className={formClasses.zIndexHighest} open={successfulWithdrawal}>
                              <Alert
                                className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                onClose={() => setSuccessfulWithdrawal(false)}
                                severity='success'
                              >
                                Prijava je povučena
                              </Alert>
                            </Backdrop>
                          )}
                          {errorWithdrawApplication && (
                            <Backdrop className={formClasses.zIndexHighest} open={errorWithdrawApplication}>
                              <Alert
                                className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''}
                                onClose={() => setErrorWithDrawApplication(false)}
                                severity='error'
                              >
                                Prijava na ovom oglasu je već povučena
                              </Alert>
                            </Backdrop>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LandingFooter />
      </Grid>
    </Grid>
  )
}

export default AdProfile
