import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import {
  formatBillingType,
  formatCity,
  formatCountry,
  formatCurrency,
  formatDate,
  generateOfferedItemsText,
  navigateWithScroll,
  useMediaQueries
} from '../../../utils/global-utils'
import Grid from '@mui/material/Grid/Grid'
import Chip from '@mui/material/Chip/Chip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PlaceIcon from '@mui/icons-material/Place'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import Divider from '@mui/material/Divider/Divider'
import classNames from 'classnames'
import useStyles from './adsCardLanding.module'
import AddApi from '../../../services/addApi'
import { AdDetails } from '../../../utils/types.d'
import { Card, CardContent } from '@mui/material'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

function AdsCardLanding() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const navigate = useNavigate()
  const [, setLoading] = useState(true)
  const [fetchAds, setFetchAds] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const addResponse = await AddApi.GetAllAds({})
        const highlighteds = addResponse.data.filter((ad: { isHighlighted: boolean }) => ad.isHighlighted === true)
        setFetchAds(highlighteds)
        setLoading(false)
      } catch {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const getClassNames = (adType: string) => ({
    divider: classNames({
      [classes.dividerRegular]: adType === 'Standardni oglas',
      [classes.dividerSponsored]: adType === 'Sponzorisani oglas',
      [classes.dividerPremium]: adType === 'Premium oglas'
    })
  })

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <Carousel
      responsive={responsive}
      autoPlay={isMobile ? false : true}
      keyBoardControl={true}
      autoPlaySpeed={3000}
      transitionDuration={500}
    >
      {fetchAds?.map((adDetails: AdDetails, index) => {
        const { divider } = getClassNames(adDetails?.adType)
        return (
          <Card
            onClick={() => navigateWithScroll(navigate, `/ad-profile-preview`, adDetails.adId)}
            key={index}
            className={`${formClasses.mtb12} ${classes.landingCardsBody} ${formClasses.mlr12}`}
          >
            <CardContent>
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid container>
                  <Grid item xs={12} className={formClasses.pb12}>
                    {adDetails.adType === 'Standardni oglas' && <Chip label='Standardni oglas' className={classes.standardChip} />}
                    {adDetails.adType === 'Sponzorisani oglas' && <Chip label='Sponzorisan oglas' className={classes.sponsoredChip} />}
                    {adDetails.adType === 'Premium oglas' && (
                      <Chip icon={<StarRoundedIcon className={formClasses.star} />} label='Premium oglas' className={classes.premiumChip} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {adDetails.industries.map((industry, key) => (
                      <Chip key={key} label={industry} className={classes.fieldGroupChip} />
                    ))}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={`${isMobile ? classes.adTitleMobile : classes.adTitle} ${classes.headlineContainer}`}>
                      {adDetails.adTitle}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={divider} />
              <div className={`${classes.myAddDetails} ${classes.myAddContainers}`}>
                <Grid container>
                  <Grid container>
                    <Grid item xs={12} md={6} display='flex'>
                      <div className={classes.detailsTextContainer}>
                        <PlaceIcon className={classes.explanationIcons} />
                        <span className={classes.detailsText}>
                          {formatCity(adDetails.city)}, {formatCountry(adDetails.country)}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} display='flex'>
                      <div className={classes.detailsTextContainer}>
                        <CalendarMonthIcon className={classes.explanationIcons} />
                        <span className={classes.detailsText}>
                          {formatDate(adDetails.workingStartDate)} / {adDetails.workingEndDate ? formatDate(adDetails.workingEndDate) : '_'}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} display='flex'>
                      <div className={classes.detailsTextContainer}>
                        <MonetizationOnIcon className={classes.explanationIcons} />
                        <span className={classes.detailsText}>
                          {adDetails.salaryValue && adDetails.currency && adDetails.billingType
                            ? `${adDetails.salaryValue} ${formatCurrency(adDetails.currency)}, ${formatBillingType(adDetails.billingType)}`
                            : 'Isplata po dogovoru'}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Grid container>
                <Grid item xs={12} md={6} display='flex'>
                  <div className={classes.detailsTextContainer}>
                    {adDetails.offeredFood || adDetails.offeredAccomodation || adDetails.offeredTransport ? (
                      <>
                        <CheckCircleIcon className={`${classes.checkIcon} ${classes.explanationIcons}`} />
                        <span className={classes.detailsText}>{generateOfferedItemsText(adDetails)}</span>
                      </>
                    ) : (
                      <>
                        <RadioButtonUncheckedIcon className={`${classes.checkIcon} ${classes.explanationIcons}`} />
                        <span className={classes.detailsText}>Bez benefita</span>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={6} display='flex'>
                  <div className={isMobile ? classes.companyNameMobile : classes.companyName}>{adDetails.companyName}</div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      })}
    </Carousel>
  )
}

export default AdsCardLanding
