import { useMediaQueries } from '../../../utils/global-utils'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import useStyles from './employeeDashboard.module'
import Grid from '@mui/material/Grid/Grid'
import FunctionalHeader from '../../functionalHeader/functionalHeader'
import { useAuthContext } from '../../../context/UserContext'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Dialog from '@mui/material/Dialog'
import logoBlue from '../../../assets/logo_blue.svg'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Button } from '@material-ui/core'
import { useEffect, useState } from 'react'
import AddApi from '../../../services/addApi'
import { AdResponse } from '../../../utils/types.d'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import StyledSpinningIcon from '../../styledComponents/spinningIcon/spinningIcon'
import AdsCard from '../adsCard/adsCard'
import Box from '@mui/material/Box/Box'
import Stack from '@mui/material/Stack/Stack'
import FetchUserDetails from '../../fetchUserDetails/fetchUserDetails'
import EmployeeProfile from '../employeeProfile/employeeProfile'
import FavoriteAds from '../favoriteAds/favoriteAds'
import ApliedAds from '../appliedAds/apliedAds'
import LandingFooter from '../../wwwLandingPage/landingPageBody/landingFooter'

function EmployeeDashboard() {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const { isMobile } = useMediaQueries()
  const { accessToken } = useAuthContext()
  const [loading, setLoading] = useState(true)
  const [fetchAds, setFetchAds] = useState<AdResponse[]>([])
  const [displayAdBlockerNotificationModal, setDisplayAdBlockerNotificationModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    const hasNotificationBeenShown = localStorage.getItem('adBlockerNotificationShown')
    if (accessToken && !hasNotificationBeenShown) {
      setDisplayAdBlockerNotificationModal(true)
      localStorage.setItem('adBlockerNotificationShown', 'true')
    }
  }, [accessToken])

  const handleCloseAdBlickerModal = () => {
    setDisplayAdBlockerNotificationModal(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const addResponse = await AddApi.GetAllAds({})
        setFetchAds(addResponse.data)
        setLoading(false)
      } catch {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const TabPanel = (props: { value: number; index: number; children: React.ReactNode }) => {
    const { children, value, index, ...other } = props
    return (
      <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
        {value === index && <Box p={`${isMobile ? 0 : 3}`}>{children}</Box>}
      </div>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <FunctionalHeader />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              container
              className={`${formClasses.layoutContainer} ${isMobile ? formClasses.headlineContainerMobile : formClasses.headlineContainer}`}
            >
              {displayAdBlockerNotificationModal && (
                <Dialog maxWidth={'sm'} open={displayAdBlockerNotificationModal} aria-describedby='add-blocker-dialog'>
                  <DialogContent>
                    <DialogContentText id='add-blocker-dialog'>
                      Dobri/a naš/a, <br />
                      <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                        <span>
                          ukoliko koristiš neki od alata za blokiranje sadržaja molimo te da ga privremeno isključiš kako bi nesmetano
                          koristio/la našu aplikaciju. <br />
                        </span>
                      </div>
                      <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                        <span>
                          Iskreno tvoj, <img height={12} alt='Sezonal' src={`${logoBlue}`} />
                        </span>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseAdBlickerModal()} autoFocus>
                      Razumem
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              <div className={formClasses.bodyItemsHolder}>
                {isMobile && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                        <Tabs
                          orientation='horizontal'
                          variant='scrollable'
                          value={activeTab}
                          onChange={handleTabChange}
                          aria-label='Horiontal tabs'
                          className={classes.horizontalTabsContainer}
                        >
                          <Tab label='Moj Profil' />
                          <Tab label='Najnoviji oglasi' />
                          <Tab label='Omiljeni oglasi' />
                          <Tab label='Konkurisani oglasi' />
                        </Tabs>
                      </Grid>
                      <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                        <TabPanel value={activeTab} index={0}>
                          <Stack className={formClasses.pt24}>
                            <FetchUserDetails />
                            <EmployeeProfile />
                          </Stack>
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                          <div className={formClasses.bodyItemsHolder}>
                            {loading ? (
                              <Backdrop open={true}>
                                <div className={formClasses.circularProgressContainer}>
                                  <StyledSpinningIcon />
                                </div>
                              </Backdrop>
                            ) : (
                              <>
                                {fetchAds &&
                                  fetchAds?.map((addDetails: any, index: number) => <AdsCard key={index} addDetails={addDetails} />)}
                              </>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel value={activeTab} index={2}>
                          <FavoriteAds />
                        </TabPanel>
                        <TabPanel value={activeTab} index={3}>
                          <ApliedAds />
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!isMobile && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} sx={{ mb: 1 }}>
                      <Tabs
                        orientation='vertical'
                        variant='scrollable'
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label='Vertical tabs'
                        className={classes.verticalTabsContainer}
                      >
                        <Tab className={classes.verticalTabsHeading} label='Moj Profil' />
                        <Tab label='Najnoviji oglasi' />
                        <Tab label='Omiljeni oglasi' />
                        <Tab label='Konkurisani oglasi' />
                      </Tabs>
                    </Grid>
                    <Grid item xs={12} sm={4} md={9} sx={{ mb: 1 }}>
                      <TabPanel value={activeTab} index={0}>
                        <Stack className={formClasses.pt24}>
                          <EmployeeProfile />
                        </Stack>
                      </TabPanel>
                      <TabPanel value={activeTab} index={1}>
                        <div className={formClasses.bodyItemsHolder}>
                          {loading ? (
                            <Backdrop open={true}>
                              <div className={formClasses.circularProgressContainer}>
                                <StyledSpinningIcon />
                              </div>
                            </Backdrop>
                          ) : (
                            <>
                              {fetchAds &&
                                fetchAds?.map((addDetails: any, index: number) => <AdsCard key={index} addDetails={addDetails} />)}
                            </>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value={activeTab} index={2}>
                        <FavoriteAds />
                      </TabPanel>
                      <TabPanel value={activeTab} index={3}>
                        <ApliedAds />
                      </TabPanel>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </>
  )
}

export default EmployeeDashboard
