import { makeStyles } from '@mui/styles'

const useStyles: any = makeStyles(() => ({
  blogPostSectionBody: {
    cursor: 'pointer'
    // transition: 'transform .6s',
    // '&:hover, &:focus, &:active': {
    //   transform: 'scale(1.03)'
    // }
  },
  // UTILS
  p12: {
    padding: '12px'
  },
  p18: {
    padding: '18px'
  },
  m12: {
    margin: '12px'
  }
}))

export default useStyles
