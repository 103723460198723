import farm from '../bodyItems/farma.jpg'
import construct from '../bodyItems/gradjevina.jpg'
import bartender from '../bodyItems/konobar.jpg'
import beach from '../bodyItems/plaza.jpg'
import beachLanding from '../bodyItems/beach_landing.jpg'
import beachLandingMiddle from '../bodyItems/beach_landing_middle.jpg'
import portLanding from '../bodyItems/port_landing.jpg'
import cityLanding from '../bodyItems/city_landing.jpg'
import background from '../bodyItems/landing_background.jpg'
import megaphone from '../bodyItems/graphic_megaphone.png'
import no_results from '../bodyItems/no_results.png'

const BODY_ITEMS = {
  FARM: farm,
  CONSTRUCT: construct,
  BARTENDER: bartender,
  BEACH: beach,
  BEACH_LANDING: beachLanding,
  BEACH_LANDING_MIDDLE: beachLandingMiddle,
  PORT_LANDING: portLanding,
  CITY_LANDING: cityLanding,
  LANDING_BACKGROUND: background,
  MEGAPHONE: megaphone,
  NO_RESULTS: no_results
}

export default BODY_ITEMS
