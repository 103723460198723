import axios from 'axios'
import { APP_CORE } from '../environment'

const APPROVE_AD = '/Ad/approveTheAd'

const api = axios.create({
  baseURL: APP_CORE
})

const apiPut = (operation: string, data: object, accessToken: string | null) => {
  return new Promise((resolve, reject) => {
    api
      .put(operation, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const ApproveTheAd = async (accessToken: string | null, data: object) => {
  return apiPut(APPROVE_AD, data, accessToken)
}

const AdminApi = {
  ApproveTheAd
}

export default AdminApi
