const privacyPolicyText = [
  {
    content: `U skladu sa Opštom Uredbom o zaštiti podataka o ličnosti (General Data Protection Regulation – Regulation ((EU) 2016/679 – GDPR) 
        i Zakonom o zaštiti podataka o ličnosti Republike Srbije, obaveštavamo korisnike о sledećim informacijama, 
        a u vezi sa obradom podataka o ličnosti od strane RasTech doo (u daljem tekstu: Rukovalac).`
  },
  {
    content: `1. KO JE RUKOVALAC PODATAKA?
        Rukovalac podataka je RasTech doo, ul. Zetska 5, Kragujevac,
        matični broj: 21917494, društvo koje je organizovano i posluje prema zakonima Republike Srbije.`
  },
  {
    content: `2. KOJU VRSTU PODATAKA O LIČNOSTI KORISTIMO?
        Lične podatke korisnika prikupljamo prilikom registracije korisnika i prilikom prijave korisnika na oglas za posao na Sezonal platformi koja se nalazi na internet stranici www.sezonal.com (u daljem tekstu: Platforma). 
        Lični podaci sadržani u korisničkom profilu i opisu profila, uz prethodnu saglasnost korisnika, biće obrađivni u automatizovanoj i neautomatizovanoj podršci od strane Rukovalaca.`
  },
  {
    content: `Rukovaoci obrađuju sledeće kategorije podataka o ličnosti koje su prikupljeni u meri u kojoj to dozvoljava zakonodavstvo Republike Srbije:`
  },
  {
    content: `Email adresa korisnika.
        Ime i prezime korisnika, datum rođenja korisnika, adresa, mesto prebivališta, telefon, link ka drugim stranicama korisnika, podatke iz opsia profila.
        Bilo koje druge podatke navedene u poruci za poslodavca.
        Ime i prezime kontakt osobe, radna pozicija, email adresa i telefon kompanijskog korisnika.
        Ako nas posetioci i korisnici kontaktiraju u nekim slučajevima sačuvaćemo zapis o toj korespondenciji.
        Možemo prikupljati podatke o posetama korisnika našim internet stranicama uključujući (bez ograničenja) 
        IP adresu, podatke o pretraživaču, vremenske oznake, lokacijske podatke, mrežne dnevnike i ostale komunikacijske podatke, te izvore kojima korisnici pristupaju. 
        Pomoću ovih podataka olakšavamo korisnicima korišćenje naših sistema.`
  },
  {
    content: `3. U KOJE SVRHE KORISTIMO LIČNE PODATKE?
        Rukovaoci podatke o ličnosti obrađuju u svrhu:
        Pristupa korisničkom nalogu.
        Prijave na oglas za posao u kompaniji koja radnu poziciju oglašava na Sezonal platformi.
        Za pružanje odgovora na zahteve ili pitanja korisnika.
        Obaveštavanja i pružanja dodatnih usluga za korisnike.
        Slanje promotivnih i marketinških materijala.
        Kontaktiranja kompanijskih korisnika radi ponude za saradnjom.
        Upravljanje, procenjivanje i unapređenje našeg poslovanja.
        Poštovanja zakona budući da se može ukazati potreba da obrađujemo lične podatke korisnika da bismo postupili u skladu sa zakonom ili sudskim nalogom.
        Rešavanja sporova i istraga neispravnog postupanja budući da se može dogoditi da vršimo obradu ličnih podataka za potrebe rešavanja sporova, 
        žalbi ili zakonskih postupaka ili ako sumnjamo da je došlo do neispravnog postupanja koje zahteva dodatno ispitivanje.
        Takođe možemo koristiti podatke na druge načine za koje pružamo konkretno obaveštenje na dan ili pre vremena prikupljanja.
        Pravni osnov za obradu podataka o ličnosti je ostvarivanje legitimnih interesa rukovaoca ili treće strane (dozvoljavajući korisniku da pristupi Platformi za pružanje ponuđenih usluga).`
  },
  {
    content: `4. KO IMA PRISTUP LIČNIM PODACIMA?
        Podatke o ličnosti korisnika ne prodajemo, ne poklanjamo i ne iznajmljujemo.
        Vaše lične podatke možemo dostavljati sledećim licima:
        Kompanijama za čiji ste oglas za posao poslali prijavu, uz prethodno datu saglasnost za obradu ličnih podataka i to isključivo onih podataka navedenih u prijavi za posao.
        Državnim organima i licima koji zbog prirode posla koji obavljaju moraju imati pristup takvim podacima.
        Dobavljačima koji obavljaju usluge u naše ime na osnovu naših uputstava.
        Pružaocima usluga pravnog savetovanja Rukovaoca.`
  },
  {
    content: `5. KADA SE MOŽE VRŠITI PRENOS PODATAKA?
        Prilikom prijave za posao u kompaniji koja slobodno radno mesto oglašava na Sezonal platformi, 
        dostavljeni korisnički podaci se uz prethodnu saglasnost korisnika šalju u vidu automatizovane korespodencije kompaniji oglašivaču. 
        Rukovalac ne prenosi i ne obrađuje podatke korisnika u slučaju prijave za posao van Sezonal platforme.
        Mi možemo preneti podatke o ličnosti u jurisdikcije koje ne obuhvataju mesto prebivališta korisnika, ali mi to radimo isključivo u svrhe opisane u ovoj Politici privatnosti. 
        Kada prenosimo podatke o ličnosti u druge jurisdikcije, mi to radimo u skladu sa zahtevima važećeg zakona.`
  },
  {
    content: `6. KOLIKO DUGO ČUVAMO LIČNE PODATKE?
        Lične podatke korisnika zadržavamo ne duže nego što je potrebno u pogledu svrhe za koje su prikupljeni podaci ili za koje se ti podaci dalje obrađuju.
        Kriterijumi za određivanje vremena tokom koga čuvamo ove podatke su:
        Neophodnost zadržavanja uskladištenih ličnih podataka s ciljem pružanja usluga uspostavljenih sa korisnikom.
        Da bi se zaštitio legitimni interes Rukovalaca.
        Postojanje specifičnih zakonskih obaveza koje obradu i srodno skladištenje zahtevaju u određenom vremenskom period.`
  },
  {
    content: `7. KOJE MERE PREDUZIMAMO DA ZAŠTITIMO LIČNE PODATKE?
        Preduzeli smo odgovarajuće tehničke i organizacione mere da zaštitimo lične podatke korisnika od nenamerne ili nezakonite obrade, tako što smo obezbedili:
        Da lični podaci korisnika budu zaštićeni od neovlašćenog pristupa ili unošenja izmena.
        Da se sačuva poverljivost ličnih podataka korisnika.
        Čuvanje raspoloživosti ličnih podataka korisnika.
        Dobavljači i klijenti dobijaju instrukcije o tome kako da čuvaju lične podatke korisnika.
        Stvarno kršenje ili sumnja da je došlo do kršenja bezbednosti podataka se prijavljuje u skladu sa važećim zakonom.
        Svi lični podaci skladište se u bazi podataka koja se nalazi na posebnom serveru za čiji pristup i rukovanje je potrebno posebno ovlašćenje.
        Korisničke lozinke se u enkriptovanoj formi čuvaju u bazi podataka čime je zagarantovana nemogućnost zloupotrebe ličnih podataka korisnika pa čak i od strane zaposlenih kod Rukovaoca.
        Kompanije koje dobijaju uvid u lične podatke korisnika su samo one na čiji oglas za posao se korisnik prijavio uz prethodno datu saglasnost za obradu podataka.`
  },
  {
    content: `8. BRISANJE LIČNIH PODATAKA
        Svaki korisnik platforme ima mogućnost da u bilo kom trenutku zatraži brisanje svojih ličnih podataka u skladu sa Opštom Uredbom o zaštiti podataka o ličnosti (General Data Protection Regulation – Regulation ((EU) 2016/679 – GDPR) 
        i Zakonom o zaštiti podataka o ličnosti Republike Srbije, što podrazumeva brisanje email adrese, lozinke i svih ostavljenih ličnih podataka korisnika na nivou baze podataka.
        U svakom trenutku iz opravdanih razloga, Rukovalac može obrisati lične podatke korisnika uz slanje obaveštenja korisniku u elektronskom formatu 
        da su njegovi lični podaci izbrisani iz RasTech doo baze podataka. Dodatno, ukoliko je korisnik kompanijskog naloga svojevoljno ostavio i kontakt telefon i ime i prezime kontakt osobe 
        i ti podaci na zahtev korisnika kompanijskog naloga će biti obrisani iz baze podataka.`
  },
  {
    content: `9. PRAVA LICA NA KOJE SE PODACI ODNOSE U VEZI SA OBRADOM PODATAKA O LIČNOSTI
        Korisnik može tražiti pristup, ispravku, brisanje ili ograničavanje obrade ličnih podataka koje nam je prethodno dostavio. 
        Takođe može uložiti prigovor na naše korišćenje ličnih podataka, ili kada vršimo obradu ličnih podataka na osnovu saglasnosti, da povuče svoju saglasnost. 
        Pored toga, može zahtevati da primi elektronski primerak svojih ličnih podataka za potrebe prenosa drugom društvu (prenosivost podataka).
        U svakom od ovih slučajeva korisnici mogu da pošalju email na info@sezonal.com ako žele da iskoriste neko od svojih prava ili ukoliko imaju dodatna pitanja u vezi sa privatnošću, a koja se mogu odnositi na:
        Pristup - korisnici imaju pravo da zahtevaju pristup svojim ličnim podacima koje čuvamo i da saznaju detalje o tome koje podatke prikupljamo i za koju svrhu koristimo, kao i druge informacije predviđene Zakonom.
        Ispravka - mi preduzimamo primerene mere da obezbedimo da informacije koje o korisnicima čuvamo budu tačne i kompletne, ali korisnici mogu zatražiti da se one dopune ili isprave.
        Brisanje - u nekim slučajevima, korisnici imaju pravo da traže da se lični podaci izbrišu, kao na primer, kada ne postoji više prvobitna potreba da se čuvaju lični podaci koje smo prikupili ili kada povlačite svoju saglasnost. 
            Međutim, to se mora uskladiti sa drugim činiocima. Na primer, možda nećemo moći da ispunimo vaš zahtev zbog određenih zakonskih ili regulatornih obaveza.
        Ograničavanje vršenja obrade - u nekim slučajevima, korisnici imaju pravo da traže da se ograniči obrada njihovih ličnih podataka, 
            na primer, kada smatraju da su lični podaci koje čuvamo netačni ili kada misle da više nije potrebno da ih čuvamo.
        Prenosivost podataka - u nekim slučajevima, korisnici imaju pravo da traže da izvršimo prenos ličnih podataka koji su nam dostavljeni. Podaci se mogu dostaviti samim korisnicima ili trećim licu po zahtevu korisnika.
        Prigovor – korisnici imaju pravo da ulože prigovor na obradu koju vršimo po osnovu našeg zakonskog interesa. 
            Osim ako za obradu podataka postoji izričit zakonski osnov, mi ćemo prestati sa obradom ličnih podataka na osnovu prigovora koji je korisnik uložio. 
            Međutim, molimo korisnike da imaju u vidu da možda nećemo biti u mogućnosti da pružimo određene usluge ili pogodnosti ako ne budemo mogli da za te svrhe vršimo obradu neophodnih ličnih podataka.
        Povlačenje saglasnosti - može se dogoditi da u određenim slučajevima od korisnika tražimo saglasnost za obradu ličnih podataka. U tom slučaju, korisnici imaju pravo da u bilo kom trenutku povuku svoju saglasnost.
        Na zahtev korisnika ćemo odgovoriti u skladu sa važećim zakonom.`
  },
  {
    content: `10. KOLAČIĆI
        Kolačić („cookie“) je mala datoteka koju Platforma šalje pregledaču korisnika, koji ga potom sačuva u sistemu korisnika. 
        Kolačići mogu prikupljati podatke o ličnosti. Kolačići nam omogućavaju da vidimo ko je video koje stranice, 
        da utvrdimo koliko često su posećene određene stranice i da utvrdimo koji su najpopularniji delovi Platforme. Kolačići nam takođe omogućavaju da poboljšamo internet stranicu i pružimo bolje i personalizovane usluge.
        Kolačić će biti instaliran na uređaj korisnika kada poseti Platformu, osim ukoliko korisnik ne naznači da ne želi da se to dogodi ili ukoliko je korisnik isključio tu mogućnost u svom internet pregledaču ili u podešavanjima uređaja.
        Korišćenjem Platforme korisnik pristaje na upotrebu kolačića. Blokiranjem kolačića korisnik i dalje može da pristupa Platformi, ali neke od opcije neće biti dostupne.`
  },
  {
    content: `11. ANALITIKA
        Naša posvećenost kontinuiranom poboljšanju korisničkog iskustva i pružanju relevantnog sadržaja temelji se na pažljivoj analizi podataka. 
        Prikupljamo određene informacije o vašem interagovanju sa našim veb-sajtom kako bismo stvorili bolje uslove za sve naše korisnike. 
        Ove informacije uključuju IP adrese, tip uređaja, vrstu pregledača, vremenske oznake za posete sajtu i stranice koje posećujete.
        Svrha ovog prikupljanja podataka je analitika, a naš cilj je da:
        Personalizujemo Vaše Iskustvo:
        Analiziramo podatke kako bismo bolje razumeli vaše potrebe i interese, što nam omogućava da prilagodimo sadržaj našeg sajta i preporučimo relevantne informacije i usluge.
        Ocena Efikasnosti: Pomažemo u merenju efikasnosti naših marketinških kampanja i inicijativa kako bismo bolje razumeli kako se naše usluge koriste i kako ih možemo unaprediti.
        Otkrivanje Tehničkih Problema: Prikupljeni podaci takođe nam pomažu da identifikujemo tehničke probleme i unapredimo performanse sajta, čime obezbeđujemo stabilno i bezbedno okruženje za sve korisnike.
        Vaša privatnost je naš prioritet, i vaši podaci će biti tretirani sa pažnjom i u skladu sa važećim zakonima o zaštiti podataka. 
        Mi nećemo deliti Vaše lične podatke sa trećim stranama u svrhe analitike bez vašeg izričitog pristanka.
        Korišćenjem našeg sajta, pristajete na prikupljanje i upotrebu vaših podataka u svrhe analitike, kako je opisano u ovom dokumentu. 
        Vaš doprinos pomaže nam da stvorimo bolji veb-sajt i iskustvo za sve naše korisnike. 
        Ukoliko imate bilo kakvih pitanja ili želite više informacija o našoj politici upotrebe podataka u svrhe analitike, slobodno nas kontaktirajte.`
  },
  {
    content: `12. DODATNA PITANJA I NEDOUMICE
        Korisnici mogu kontaktirati Rukovaoca podataka u vezi sa obradom ličnih podataka slanjem poruke na email info@sezonal.com
        Druga mogućnost koju korisnici imaju je da ulože žalbu nadležnom organu (Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti) na obradu podataka o ličnosti 
        koji se odnose na lične podatke.`
  }
]

export default privacyPolicyText
