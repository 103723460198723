import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid/Grid'
import StyledButton from '../../styledComponents/styledButton.module'
import useFormStyles from '../../styledComponents/globalFormStying.module'
import useStyles from '../pricingDialog/pricingDialog.module'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import UserContext from '../../../context/UserContext'
import { PricingDialogProps } from '../pricingDialog/pricingDialog'
import { defaultSelectedSubscriptionType, monthlySubscription, sesonalSubscription, useMediaQueries } from '../../../utils/global-utils'
import { SubscriptionTypesResponse } from '../../../utils/types.d'
import AddApi from '../../../services/addApi'

function PricingPackagesDialog({ open, onClose, onConfirm }: PricingDialogProps) {
  const { isMobile } = useMediaQueries()
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { control } = useForm()
  const { accessToken } = useContext(UserContext)
  const [openDialog] = useState(false)
  const [selectedCard, setSelectedCard] = useState<string | undefined>('Godišnja pretplata')
  const [subscriptionTypeSelected, setSubscriptionTypeSelected] = useState<string | undefined>(defaultSelectedSubscriptionType)
  const [fetchSubscriptionTypes, setFetchSubscriptionTypes] = useState<SubscriptionTypesResponse[] | null>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAdSubscriptionTypes = async () => {
      if (!accessToken) return
      setLoading(true)
      try {
        const getAdSubscriptionTypes = await AddApi.GetAdPackageTypes(accessToken)
        setFetchSubscriptionTypes(getAdSubscriptionTypes.data)
      } catch (error) {
        console.error('Error fetching details:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchAdSubscriptionTypes()
  }, [accessToken])

  const handleSelectedCard = (cardName: string) => {
    if (fetchSubscriptionTypes) {
      const adSubscriptionType = fetchSubscriptionTypes.find(
        (adType: { adPackageTypeName: string }) => adType.adPackageTypeName === cardName
      )?.id

      setSubscriptionTypeSelected(adSubscriptionType)
      setSelectedCard(cardName)
    }
  }

  const handleModalConfirm = () => {
    if (subscriptionTypeSelected) {
      onConfirm(subscriptionTypeSelected)
      onClose()
    }
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction='down' ref={ref} {...props} />
  })
  return (
    <Dialog
      maxWidth={'md'}
      open={open && !loading}
      onClose={onClose}
      TransitionComponent={openDialog ? Transition : undefined}
      keepMounted
      aria-describedby='pricing-modal'
    >
      <DialogTitle className={`${formClasses.canditateName} ${formClasses.fwbi}`}>Odaberi tip paketa</DialogTitle>
      <DialogContent className={classes.pricingModalContainer}>
        <DialogContentText id='pricing-modal'>
          <Grid container spacing={1}>
            <>
              {fetchSubscriptionTypes?.map((subsDetails: SubscriptionTypesResponse) => (
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name={subsDetails.adPackageTypeName}
                    control={control}
                    render={({ field }) => {
                      const currentCard = selectedCard === field.name ? true : false
                      return (
                        <Card
                          {...field}
                          className={`${classes.regularCard} ${classes.cardHeight} ${currentCard ? classes.sponsoredCard : ' '}`}
                          onClick={() => handleSelectedCard(field.name)}
                        >
                          <CardContent className={classes.cardContent}>
                            <div className={classes.pricingContainer}>
                              <span className={classes.price}>{subsDetails.price} €</span>/
                              <span className={classes.duration}>{subsDetails.duration} dana</span>
                            </div>
                            <div className={classes.headlineHolder}>
                              <span className={`${classes.regularAddHeadline} ${currentCard ? classes.sponsoreddAddHeadline : ' '}`}>
                                {subsDetails.adPackageTypeName}
                              </span>
                            </div>
                            {subsDetails.adPackageTypeName === monthlySubscription && (
                              <span className={classes.explanationTextSmall}>
                                Vaša pretplata uključuje 5 standardnih oglasa, 10 sponzorisanih oglasa i 1 premium oglas. <br />
                                Uključujemo minimum od 3 objave na društvenim mrežama svakog dana, kao i 4 custom reels objave. <br />
                                Ove prilagođene objave biće prilagođene Vašoj ponudi kako bi privukle pažnju kandidata.
                              </span>
                            )}
                            {subsDetails.adPackageTypeName === sesonalSubscription && (
                              <span className={classes.explanationTextSmall}>
                                Vaša pretplata uključuje 10 standardnih oglasa, 20 sponzorisanih oglasa, kao i 10 premium oglasa. <br />
                                Uključujemo minimum od 3 objave na društvenim mrežama svakog dana, kao i 4 custom reels objave mesečno.{' '}
                                <br />
                                Ove prilagođene objave biće prilagođene Vašoj ponudi kako bi privukle pažnju kandidata.
                              </span>
                            )}
                          </CardContent>
                        </Card>
                      )
                    }}
                  />
                </Grid>
              ))}
            </>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
            <div className={`${formClasses.pr6} ${formClasses.pl6}`}>
              <StyledButton style={{ textTransform: 'none' }} className={`${formClasses.w100pi}`} variant='outlined' onClick={onClose}>
                Odustani
              </StyledButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={isMobile ? `${formClasses.mt12} ${formClasses.mb12}` : `${formClasses.pr6} ${formClasses.pl6}`}>
              <StyledButton
                style={{ textTransform: 'none' }}
                className={`${formClasses.w100pi} ${formClasses.pr6} ${formClasses.pl6}`}
                variant='contained'
                onClick={handleModalConfirm}
              >
                Odaberi paket
              </StyledButton>
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default PricingPackagesDialog
